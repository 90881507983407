import { MapBlockInfo } from '@/models/johaisetsuCommon';

function getMainlineElementsMapByBlockCode(): Record<string, string[]> {
  // block_codeとsvgのidが1対1で対応していない場合はここに追加する.
  return {
    // t-west v2
    'ueno-up-m-002': ['ueno-up-m-001', 'ueno-up-m-002'],
    'ueno-up-m-003': ['ueno-up-m-003'],
    'ueno-down-m-002': ['ueno-down-m-001'],
    'ueno-down-m-003': ['ueno-down-m-002', 'ueno-down-m-003'],
    'haneda-up-m-004': ['haneda-up-m-001'],
    'haneda-up-m-005': ['haneda-up-m-002', 'haneda-up-m-003'],
    'haneda-up-m-006': ['haneda-up-m-004', 'haneda-up-m-005', 'haneda-up-m-006'],
    'haneda-up-m-007': ['haneda-up-m-007'],
    'haneda-down-m-004': ['haneda-down-m-001', 'haneda-down-m-002'],
    'haneda-down-m-005': ['haneda-down-m-003', 'haneda-down-m-004'],
    'haneda-down-m-006': ['haneda-down-m-005', 'haneda-down-m-006'],
    'haneda-down-m-007': ['haneda-down-m-007'],
    'meguro-up-m-003': ['meguro-up-m-001'],
    'meguro-up-m-004': ['meguro-up-m-002'],
    'meguro-down-m-003': ['meguro-down-m-001'],
    'meguro-down-m-004': ['meguro-down-m-002'],
    'shibuya-up-m-004': ['shibuya-up-m-001', 'shibuya-up-m-002'],
    'shibuya-up-m-005': ['shibuya-up-m-003', 'shibuya-up-m-004'],
    'shibuya-up-m-006': ['shibuya-up-m-005'],
    'shibuya-down-m-004': ['shibuya-down-m-001'],
    'shibuya-down-m-005': ['shibuya-down-m-002', 'shibuya-down-m-003'],
    'shibuya-down-m-006': ['shibuya-down-m-004', 'shibuya-down-m-005'],
    'shinjuku-up-m-005': ['shinjuku-up-m-001', 'shinjuku-up-m-002'],
    'shinjuku-up-m-006': ['shinjuku-up-m-003', 'shinjuku-up-m-004'],
    'shinjuku-up-m-007': ['shinjuku-up-m-005'],
    'shinjuku-up-m-008': ['shinjuku-up-m-006', 'shinjuku-up-m-007'],
    'shinjuku-up-m-009': ['shinjuku-up-m-008'],
    'shinjuku-down-m-005': ['shinjuku-down-m-001'],
    'shinjuku-down-m-006': ['shinjuku-down-m-002', 'shinjuku-down-m-003'],
    'shinjuku-down-m-007': ['shinjuku-down-m-004', 'shinjuku-down-m-005', 'shinjuku-down-m-006'],
    'shinjuku-down-m-008': ['shinjuku-down-m-007'],
    'shinjuku-down-m-009': ['shinjuku-down-m-008'],
    'ikebukuro-west-up-m-004': ['ikebukuro-west-up-m-001', 'ikebukuro-west-up-m-002'],
    'ikebukuro-west-up-m-005': ['ikebukuro-west-up-m-003', 'ikebukuro-west-up-m-004'],
    'ikebukuro-west-up-m-006': ['ikebukuro-west-up-m-005'],
    'ikebukuro-west-down-m-004': ['ikebukuro-west-down-m-001'],
    'ikebukuro-west-down-m-005': ['ikebukuro-west-down-m-002', 'ikebukuro-west-down-m-003'],
    'ikebukuro-west-down-m-006': ['ikebukuro-west-down-m-004', 'ikebukuro-west-down-m-005'],
    'yaesu-north-m-002': ['yaesu-north-m-001'],
    'yaesu-north-m-003': ['yaesu-north-m-002'],
    'yaesu-north-m-004': ['yaesu-north-m-003'],
    'yaesu-south-m-002': ['yaesu-south-m-001'],
    'yaesu-south-m-003': ['yaesu-south-m-002'],
    'yaesu-south-m-004': ['yaesu-south-m-003'],
    'tokan-nai-m-007': ['tokan-nai-m-001', 'tokan-nai-m-002', 'tokan-nai-m-003'],
    'tokan-nai-m-008': ['tokan-nai-m-004', 'tokan-nai-m-005'],
    'tokan-nai-m-009': ['tokan-nai-m-006'],
    'tokan-nai-m-010': ['tokan-nai-m-007'],
    'tokan-nai-m-011': ['tokan-nai-m-008', 'tokan-nai-m-009', 'tokan-nai-m-010'],
    'tokan-gai-m-007': ['tokan-gai-m-001', 'tokan-gai-m-002', 'tokan-gai-m-003'],
    'tokan-gai-m-008': ['tokan-gai-m-004', 'tokan-gai-m-005'],
    'tokan-gai-m-009': ['tokan-gai-m-006'],
    'tokan-gai-m-010': ['tokan-gai-m-007'],
    'tokan-gai-m-011': ['tokan-gai-m-008', 'tokan-gai-m-009'],
    'nakakan-west-nai-m-011': ['nakakan-west-nai-m-001'],
    'nakakan-west-nai-m-012': ['nakakan-west-nai-m-002', 'nakakan-west-nai-m-003', 'nakakan-west-nai-m-004'],
    'nakakan-west-nai-m-013': ['nakakan-west-nai-m-005'],
    'nakakan-west-nai-m-014': ['nakakan-west-nai-m-006'],
    'nakakan-west-nai-m-015': ['nakakan-west-nai-m-007', 'nakakan-west-nai-m-008', 'nakakan-west-nai-m-009'],
    'nakakan-west-nai-m-016': ['nakakan-west-nai-m-010'],
    'nakakan-west-nai-m-017': ['nakakan-west-nai-m-011'],
    'nakakan-west-nai-m-018': ['nakakan-west-nai-m-012', 'nakakan-west-nai-m-013', 'nakakan-west-nai-m-014'],
    'nakakan-west-nai-m-019': ['nakakan-west-nai-m-015'],
    'nakakan-west-gai-m-011': ['nakakan-west-gai-m-001'],
    'nakakan-west-gai-m-012': ['nakakan-west-gai-m-002', 'nakakan-west-gai-m-003', 'nakakan-west-gai-m-004'],
    'nakakan-west-gai-m-013': ['nakakan-west-gai-m-005'],
    'nakakan-west-gai-m-014': ['nakakan-west-gai-m-006'],
    'nakakan-west-gai-m-015': ['nakakan-west-gai-m-007', 'nakakan-west-gai-m-008', 'nakakan-west-gai-m-009'],
    'nakakan-west-gai-m-016': ['nakakan-west-gai-m-010'],
    'nakakan-west-gai-m-017': ['nakakan-west-gai-m-011'],
    'nakakan-west-gai-m-018': ['nakakan-west-gai-m-012', 'nakakan-west-gai-m-013', 'nakakan-west-gai-m-014'],
    'nakakan-west-gai-m-019': ['nakakan-west-gai-m-015'],
    'nakakan-ooi-gai-m-002': ['nakakan-ooi-gai-m-001'],
    // kanagawa v1
    'yokohane-up-m-001': ['yokohane-up-m-001', 'yokohane-up-m-002'],
    'yokohane-up-m-002': ['yokohane-up-m-003', 'yokohane-up-m-004'],
    'yokohane-up-m-003': ['yokohane-up-m-005', 'yokohane-up-m-006'],
    'yokohane-up-m-004': ['yokohane-up-m-007', 'yokohane-up-m-008'],
    'yokohane-up-m-005': ['yokohane-up-m-009', 'yokohane-up-m-010', 'yokohane-up-m-011'],
    'yokohane-up-m-006': ['yokohane-up-m-012'],
    'yokohane-up-m-007': ['yokohane-up-m-013'],
    'yokohane-up-m-008': ['yokohane-up-m-014', 'yokohane-up-m-015', 'yokohane-up-m-016', 'yokohane-up-m-017'],
    'yokohane-up-m-009': ['yokohane-up-m-018', 'yokohane-up-m-019', 'yokohane-up-m-020'],
    'yokohane-up-m-010': ['yokohane-up-m-021', 'yokohane-up-m-022', 'yokohane-up-m-023', 'yokohane-up-m-024'],
    'yokohane-up-m-011': ['yokohane-up-m-025'],
    'yokohane-up-m-012': ['yokohane-up-m-026'],
    'yokohane-up-m-013': ['yokohane-up-m-027'],
    'yokohane-up-m-014': ['yokohane-up-m-028'],
    'yokohane-up-m-015': ['yokohane-up-m-029'],
    'yokohane-up-m-016': ['yokohane-up-m-030'],
    'yokohane-up-m-017': ['yokohane-up-m-031'],
    'yokohane-up-m-018': ['yokohane-up-m-032'],
    'yokohane-up-m-019': ['yokohane-up-m-032'],
    'yokohane-down-m-001': ['yokohane-down-m-001', 'yokohane-down-m-002'],
    'yokohane-down-m-002': ['yokohane-down-m-003'],
    'yokohane-down-m-003': ['yokohane-down-m-004', 'yokohane-down-m-005', 'yokohane-down-m-006'],
    'yokohane-down-m-004': ['yokohane-down-m-007', 'yokohane-down-m-008'],
    'yokohane-down-m-005': ['yokohane-down-m-009', 'yokohane-down-m-010', 'yokohane-down-m-011'],
    'yokohane-down-m-006': ['yokohane-down-m-012'],
    'yokohane-down-m-007': ['yokohane-down-m-013', 'yokohane-down-m-014'],
    'yokohane-down-m-008': ['yokohane-down-m-015', 'yokohane-down-m-016', 'yokohane-down-m-017', 'yokohane-down-m-018', 'yokohane-down-m-019'],
    'yokohane-down-m-009': ['yokohane-down-m-020', 'yokohane-down-m-021', 'yokohane-down-m-022'],
    'yokohane-down-m-010': ['yokohane-down-m-023', 'yokohane-down-m-024', 'yokohane-down-m-025', 'yokohane-down-m-026'],
    'yokohane-down-m-011': ['yokohane-down-m-027'],
    'yokohane-down-m-012': ['yokohane-down-m-028'],
    'yokohane-down-m-013': ['yokohane-down-m-029'],
    'yokohane-down-m-014': ['yokohane-down-m-030'],
    'yokohane-down-m-015': ['yokohane-down-m-030'],
    'yokohane-down-m-016': ['yokohane-down-m-030'],
    'yokohane-down-m-017': ['yokohane-down-m-031'],
    'yokohane-down-m-018': ['yokohane-down-m-031'],
    'yokohane-down-m-019': ['yokohane-down-m-031'],
    'yokokita-up-m-001': ['yokokita-up-m-001'],
    'yokokita-up-m-002': ['yokokita-up-m-001'],
    'yokokita-up-m-003': ['yokokita-up-m-001'],
    'yokokita-up-m-004': ['yokokita-up-m-002'],
    'yokokita-up-m-005': ['yokokita-up-m-003', 'yokokita-up-m-004', 'yokokita-up-m-005', 'yokokita-up-m-006', 'yokokita-up-m-007'],
    'yokokita-up-m-006': ['yokokita-up-m-008', 'yokokita-up-m-009'],
    'yokokita-up-m-007': ['yokokita-up-m-010'],
    'yokokita-up-m-008': ['yokokita-up-m-010'],
    'yokokita-up-m-009': ['yokokita-up-m-010'],
    'yokokita-down-m-001': ['yokokita-down-m-001'],
    'yokokita-down-m-002': ['yokokita-down-m-001'],
    'yokokita-down-m-003': ['yokokita-down-m-001'],
    'yokokita-down-m-004': ['yokokita-down-m-002'],
    'yokokita-down-m-005': ['yokokita-down-m-003', 'yokokita-down-m-004', 'yokokita-down-m-005', 'yokokita-down-m-006', 'yokokita-down-m-007'],
    'yokokita-down-m-006': ['yokokita-down-m-008', 'yokokita-down-m-009'],
    'yokokita-down-m-007': ['yokokita-down-m-010'],
    'yokokita-down-m-008': ['yokokita-down-m-010'],
    'yokokita-down-m-009': ['yokokita-down-m-010'],
    'hokusei-up-m-001': ['hokusei-up-m-001'],
    'hokusei-up-m-002': ['hokusei-up-m-002'],
    'hokusei-up-m-003': ['hokusei-up-m-003'],
    'hokusei-up-m-004': ['hokusei-up-m-004'],
    'hokusei-up-m-005': ['hokusei-up-m-005'],
    'hokusei-up-m-006': ['hokusei-up-m-006'],
    'hokusei-up-m-007': ['hokusei-up-m-006'],
    'hokusei-up-m-008': ['hokusei-up-m-006'],
    'hokusei-down-m-001': ['hokusei-down-m-001'],
    'hokusei-down-m-002': ['hokusei-down-m-002'],
    'hokusei-down-m-003': ['hokusei-down-m-003'],
    'hokusei-down-m-004': ['hokusei-down-m-004'],
    'hokusei-down-m-005': ['hokusei-down-m-005'],
    'hokusei-down-m-006': ['hokusei-down-m-006'],
    'hokusei-down-m-007': ['hokusei-down-m-006'],
    'hokusei-down-m-008': ['hokusei-down-m-006'],
    'kariba-up-m-001': ['kariba-up-m-001'],
    'kariba-up-m-002': ['kariba-up-m-002'],
    'kariba-up-m-003': ['kariba-up-m-003', 'kariba-up-m-004', 'kariba-up-m-005'],
    'kariba-up-m-004': ['kariba-up-m-006', 'kariba-up-m-007', 'kariba-up-m-008'],
    'kariba-up-m-005': ['kariba-up-m-009', 'kariba-up-m-010', 'kariba-up-m-011'],
    'kariba-up-m-006': ['kariba-up-m-012', 'kariba-up-m-013'],
    'kariba-up-m-007': ['kariba-up-m-014'],
    'kariba-up-m-008': ['kariba-up-m-015', 'kariba-up-m-016'],
    'kariba-down-m-001': ['kariba-down-m-001'],
    'kariba-down-m-002': ['kariba-down-m-002'],
    'kariba-down-m-003': ['kariba-down-m-003', 'kariba-down-m-004', 'kariba-down-m-005'],
    'kariba-down-m-004': ['kariba-down-m-006'],
    'kariba-down-m-005': ['kariba-down-m-007', 'kariba-down-m-008'],
    'kariba-down-m-006': ['kariba-down-m-009', 'kariba-down-m-010'],
    'kariba-down-m-007': ['kariba-down-m-011'],
    'kariba-down-m-008': ['kariba-down-m-012'],
    'mitsuzawa-up-m-001': ['mitsuzawa-up-m-001'],
    'mitsuzawa-up-m-002': ['mitsuzawa-up-m-002'],
    'mitsuzawa-up-m-003': ['mitsuzawa-up-m-002'],
    'mitsuzawa-up-m-004': ['mitsuzawa-up-m-003'],
    'mitsuzawa-up-m-005': ['mitsuzawa-up-m-004'],
    'mitsuzawa-up-m-006': ['mitsuzawa-up-m-004'],
    'mitsuzawa-up-m-007': ['mitsuzawa-up-m-005'],
    'mitsuzawa-up-m-008': ['mitsuzawa-up-m-006'],
    'mitsuzawa-down-m-001': ['mitsuzawa-down-m-001'],
    'mitsuzawa-down-m-002': ['mitsuzawa-down-m-002'],
    'mitsuzawa-down-m-003': ['mitsuzawa-down-m-002'],
    'mitsuzawa-down-m-004': ['mitsuzawa-down-m-003'],
    'mitsuzawa-down-m-005': ['mitsuzawa-down-m-004'],
    'mitsuzawa-down-m-006': ['mitsuzawa-down-m-004'],
    'mitsuzawa-down-m-007': ['mitsuzawa-down-m-005'],
    'mitsuzawa-down-m-008': ['mitsuzawa-down-m-006'],
    'daikoku-up-m-001': ['daikoku-up-m-001', 'daikoku-up-m-002', 'daikoku-up-m-003'],
    'daikoku-up-m-002': ['daikoku-up-m-004'],
    'daikoku-down-m-001': ['daikoku-down-m-001', 'daikoku-down-m-002', 'daikoku-down-m-003'],
    'daikoku-down-m-002': ['daikoku-down-m-004'],
    'kawasaki-up-m-001': ['kawasaki-up-m-001', 'kawasaki-up-m-002', 'kawasaki-up-m-003'],
    'kawasaki-up-m-002': ['kawasaki-up-m-004', 'kawasaki-up-m-005', 'kawasaki-up-m-006'],
    'kawasaki-down-m-001': ['kawasaki-down-m-001', 'kawasaki-down-m-002', 'kawasaki-down-m-003'],
    'kawasaki-down-m-002': ['kawasaki-down-m-004', 'kawasaki-down-m-005', 'kawasaki-down-m-006'],
    'wangan-k-east-m-001': ['wangan-k-east-m-001'],
    'wangan-k-east-m-002': ['wangan-k-east-m-002'],
    'wangan-k-east-m-003': ['wangan-k-east-m-003'],
    'wangan-k-east-m-004': ['wangan-k-east-m-004'],
    'wangan-k-east-m-005': ['wangan-k-east-m-005'],
    'wangan-k-east-m-006': ['wangan-k-east-m-006'],
    'wangan-k-east-m-007': ['wangan-k-east-m-007'],
    'wangan-k-east-m-008': ['wangan-k-east-m-008'],
    'wangan-k-east-m-009': ['wangan-k-east-m-009'],
    'wangan-k-east-m-010': ['wangan-k-east-m-010'],
    'wangan-k-east-m-011': ['wangan-k-east-m-011'],
    'wangan-k-east-m-012': ['wangan-k-east-m-012'],
    'wangan-k-east-m-013': ['wangan-k-east-m-013'],
    'wangan-k-east-m-014': ['wangan-k-east-m-014'],
    'wangan-k-east-m-015': ['wangan-k-east-m-015'],
    'wangan-k-east-m-016': ['wangan-k-east-m-016'],
    'wangan-k-east-m-017': ['wangan-k-east-m-017'],
    'wangan-k-east-m-018': ['wangan-k-east-m-018'],
    'wangan-k-east-m-019': ['wangan-k-east-m-019'],
    'wangan-k-east-m-020': ['wangan-k-east-m-019'],
    'wangan-k-east-m-021': ['wangan-k-east-m-019'],
    'wangan-k-east-m-022': ['wangan-k-east-m-020'],
    'wangan-k-east-m-023': ['wangan-k-east-m-020'],
    'wangan-k-east-m-024': ['wangan-k-east-m-020'],
    'wangan-k-east-m-025': ['wangan-k-east-m-021'],
    'wangan-k-east-m-026': ['wangan-k-east-m-021'],
    'wangan-k-east-m-027': ['wangan-k-east-m-021'],
    'wangan-k-east-m-028': ['wangan-k-east-m-021'],
    'wangan-k-east-m-029': ['wangan-k-east-m-021'],
    'wangan-k-east-m-030': ['wangan-k-east-m-021'],
    'wangan-k-east-m-031': ['wangan-k-east-m-022'],
    'wangan-k-east-m-032': ['wangan-k-east-m-022'],
    'wangan-k-east-m-033': ['wangan-k-east-m-023'],
    'wangan-k-east-m-034': ['wangan-k-east-m-023'],
    'wangan-k-east-m-035': ['wangan-k-east-m-024'],
    'wangan-k-east-m-036': ['wangan-k-east-m-025'],
    'wangan-k-east-m-037': ['wangan-k-east-m-026'],
    'wangan-k-east-m-038': ['wangan-k-east-m-026'],
    'wangan-k-east-m-039': ['wangan-k-east-m-027'],
    'wangan-k-east-m-040': ['wangan-k-east-m-027'],
    'wangan-k-east-m-041': ['wangan-k-east-m-028'],
    'wangan-k-west-m-001': ['wangan-k-west-m-001'],
    'wangan-k-west-m-002': ['wangan-k-west-m-002'],
    'wangan-k-west-m-003': ['wangan-k-west-m-003'],
    'wangan-k-west-m-004': ['wangan-k-west-m-004'],
    'wangan-k-west-m-005': ['wangan-k-west-m-005'],
    'wangan-k-west-m-006': ['wangan-k-west-m-006'],
    'wangan-k-west-m-007': ['wangan-k-west-m-007'],
    'wangan-k-west-m-008': ['wangan-k-west-m-008'],
    'wangan-k-west-m-009': ['wangan-k-west-m-009'],
    'wangan-k-west-m-010': ['wangan-k-west-m-010'],
    'wangan-k-west-m-011': ['wangan-k-west-m-011'],
    'wangan-k-west-m-012': ['wangan-k-west-m-012'],
    'wangan-k-west-m-013': ['wangan-k-west-m-013'],
    'wangan-k-west-m-014': ['wangan-k-west-m-014'],
    'wangan-k-west-m-015': ['wangan-k-west-m-015'],
    'wangan-k-west-m-016': ['wangan-k-west-m-016'],
    'wangan-k-west-m-017': ['wangan-k-west-m-017'],
    'wangan-k-west-m-018': ['wangan-k-west-m-018'],
    'wangan-k-west-m-019': ['wangan-k-west-m-019'],
    'wangan-k-west-m-020': ['wangan-k-west-m-019'],
    'wangan-k-west-m-021': ['wangan-k-west-m-019'],
    'wangan-k-west-m-022': ['wangan-k-west-m-020'],
    'wangan-k-west-m-023': ['wangan-k-west-m-020'],
    'wangan-k-west-m-024': ['wangan-k-west-m-020'],
    'wangan-k-west-m-025': ['wangan-k-west-m-021'],
    'wangan-k-west-m-026': ['wangan-k-west-m-021'],
    'wangan-k-west-m-027': ['wangan-k-west-m-021'],
    'wangan-k-west-m-028': ['wangan-k-west-m-021'],
    'wangan-k-west-m-029': ['wangan-k-west-m-021'],
    'wangan-k-west-m-030': ['wangan-k-west-m-021'],
    'wangan-k-west-m-031': ['wangan-k-west-m-022'],
    'wangan-k-west-m-032': ['wangan-k-west-m-022'],
    'wangan-k-west-m-033': ['wangan-k-west-m-023'],
    'wangan-k-west-m-034': ['wangan-k-west-m-023'],
    'wangan-k-west-m-035': ['wangan-k-west-m-024'],
    'wangan-k-west-m-036': ['wangan-k-west-m-025'],
    'wangan-k-west-m-037': ['wangan-k-west-m-026'],
    'wangan-k-west-m-038': ['wangan-k-west-m-026'],
    'wangan-k-west-m-039': ['wangan-k-west-m-027'],
    'wangan-k-west-m-040': ['wangan-k-west-m-027'],
    'wangan-k-west-m-041': ['wangan-k-west-m-028'],
    // kanagawa v2
    'wangan-k-east-m-042': ['wangan-k-east-m-001'],
    'wangan-k-east-m-043': ['wangan-k-east-m-002'],
    'wangan-k-east-m-044': ['wangan-k-east-m-003'],
    'wangan-k-east-m-045': ['wangan-k-east-m-004'],
    'wangan-k-east-m-046': ['wangan-k-east-m-005'],
    'wangan-k-east-m-047': ['wangan-k-east-m-006'],
    'wangan-k-east-m-048': ['wangan-k-east-m-007'],
    'wangan-k-east-m-049': ['wangan-k-east-m-008'],
    'wangan-k-east-m-050': ['wangan-k-east-m-009'],
    'wangan-k-east-m-051': ['wangan-k-east-m-010'],
    'wangan-k-east-m-052': ['wangan-k-east-m-011'],
    'wangan-k-east-m-053': ['wangan-k-east-m-012'],
    'wangan-k-east-m-054': ['wangan-k-east-m-013'],
    'wangan-k-east-m-055': ['wangan-k-east-m-014'],
    'wangan-k-east-m-056': ['wangan-k-east-m-015'],
    'wangan-k-east-m-057': ['wangan-k-east-m-016'],
    'wangan-k-east-m-058': ['wangan-k-east-m-017'],
    'wangan-k-east-m-059': ['wangan-k-east-m-018'],
    'wangan-k-east-m-060': ['wangan-k-east-m-019'],
    'wangan-k-east-m-061': ['wangan-k-east-m-019'],
    'wangan-k-east-m-062': ['wangan-k-east-m-019'],
    'wangan-k-east-m-063': ['wangan-k-east-m-020'],
    'wangan-k-east-m-064': ['wangan-k-east-m-020'],
    'wangan-k-east-m-065': ['wangan-k-east-m-020'],
    'wangan-k-east-m-066': ['wangan-k-east-m-021'],
    'wangan-k-east-m-067': ['wangan-k-east-m-021'],
    'wangan-k-east-m-068': ['wangan-k-east-m-021'],
    'wangan-k-east-m-069': ['wangan-k-east-m-021'],
    'wangan-k-east-m-070': ['wangan-k-east-m-021'],
    'wangan-k-east-m-071': ['wangan-k-east-m-021'],
    'wangan-k-east-m-072': ['wangan-k-east-m-022'],
    'wangan-k-east-m-073': ['wangan-k-east-m-022'],
    'wangan-k-east-m-074': ['wangan-k-east-m-023'],
    'wangan-k-east-m-075': ['wangan-k-east-m-023'],
    'wangan-k-east-m-076': ['wangan-k-east-m-024'],
    'wangan-k-east-m-077': ['wangan-k-east-m-025'],
    'wangan-k-east-m-078': ['wangan-k-east-m-026'],
    'wangan-k-east-m-079': ['wangan-k-east-m-026'],
    'wangan-k-east-m-080': ['wangan-k-east-m-027'],
    'wangan-k-east-m-081': ['wangan-k-east-m-027'],
    'wangan-k-east-m-082': ['wangan-k-east-m-028'],
    'wangan-k-west-m-042': ['wangan-k-west-m-001'],
    'wangan-k-west-m-043': ['wangan-k-west-m-002'],
    'wangan-k-west-m-044': ['wangan-k-west-m-003'],
    'wangan-k-west-m-045': ['wangan-k-west-m-004'],
    'wangan-k-west-m-046': ['wangan-k-west-m-005'],
    'wangan-k-west-m-047': ['wangan-k-west-m-006'],
    'wangan-k-west-m-048': ['wangan-k-west-m-007'],
    'wangan-k-west-m-049': ['wangan-k-west-m-008'],
    'wangan-k-west-m-050': ['wangan-k-west-m-009'],
    'wangan-k-west-m-051': ['wangan-k-west-m-010'],
    'wangan-k-west-m-052': ['wangan-k-west-m-011'],
    'wangan-k-west-m-053': ['wangan-k-west-m-012'],
    'wangan-k-west-m-054': ['wangan-k-west-m-013'],
    'wangan-k-west-m-055': ['wangan-k-west-m-014'],
    'wangan-k-west-m-056': ['wangan-k-west-m-015'],
    'wangan-k-west-m-057': ['wangan-k-west-m-016'],
    'wangan-k-west-m-058': ['wangan-k-west-m-017'],
    'wangan-k-west-m-059': ['wangan-k-west-m-018'],
    'wangan-k-west-m-060': ['wangan-k-west-m-019'],
    'wangan-k-west-m-061': ['wangan-k-west-m-019'],
    'wangan-k-west-m-062': ['wangan-k-west-m-019'],
    'wangan-k-west-m-063': ['wangan-k-west-m-020'],
    'wangan-k-west-m-064': ['wangan-k-west-m-020'],
    'wangan-k-west-m-065': ['wangan-k-west-m-020'],
    'wangan-k-west-m-066': ['wangan-k-west-m-021'],
    'wangan-k-west-m-067': ['wangan-k-west-m-021'],
    'wangan-k-west-m-068': ['wangan-k-west-m-021'],
    'wangan-k-west-m-069': ['wangan-k-west-m-021'],
    'wangan-k-west-m-070': ['wangan-k-west-m-021'],
    'wangan-k-west-m-071': ['wangan-k-west-m-021'],
    'wangan-k-west-m-072': ['wangan-k-west-m-022'],
    'wangan-k-west-m-073': ['wangan-k-west-m-022'],
    'wangan-k-west-m-074': ['wangan-k-west-m-023'],
    'wangan-k-west-m-075': ['wangan-k-west-m-023'],
    'wangan-k-west-m-076': ['wangan-k-west-m-024'],
    'wangan-k-west-m-077': ['wangan-k-west-m-025'],
    'wangan-k-west-m-078': ['wangan-k-west-m-026'],
    'wangan-k-west-m-079': ['wangan-k-west-m-026'],
    'wangan-k-west-m-080': ['wangan-k-west-m-027'],
    'wangan-k-west-m-081': ['wangan-k-west-m-027'],
    'wangan-k-west-m-082': ['wangan-k-west-m-028'],
  };
}
export function getMainlineElementIds(info: MapBlockInfo) : string[] {
  let arr: string[] = [];
  const mainlineElementsMapByBlockCode = getMainlineElementsMapByBlockCode();
  if (info.blockCode) {
    if (mainlineElementsMapByBlockCode.hasOwnProperty(info.blockCode)) {
      arr = mainlineElementsMapByBlockCode[info.blockCode];
    } else {
      arr = [info.blockCode];
    }
  }
  return arr;
}
