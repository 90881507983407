import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { CommonHeader, JoukyouInfo } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { Johaisetsu_joukyouIndexParams, Johaisetsu_joukyouShowMainParams } from '@/models/apis/johaisetsu/johaisetsuJoukyouRequest';
import { UpdateSnowHeightParams, UpdateHaisetsuKeisanParams, UpdateProgressTableParams } from '@/models/apis/johaisetsu/sekisetsuInputRequest';

export default {
  index(params: Johaisetsu_joukyouIndexParams): Promise<AxiosResponse<CommonHeader[]>> {
    return axios.get(utils.getApiUrl(
      `/johaisetsu_planning/${params.taskForceId}`,
    ));
  },
  show(params: Johaisetsu_joukyouShowMainParams): Promise<AxiosResponse<JoukyouInfo>> {
    return axios.get(utils.getApiUrl(
      `/johaisetsu_planning/${params.taskForceId}/${params.headerId}`,
    ));
  },
  // 以下、操作対象はtaskForceId内のtype=currentなheaderレコード配下のみ
  updateSnowHeight(taskForceId: number, params: UpdateSnowHeightParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(
      `/johaisetsu_planning/${taskForceId}/snow_height`,
    ), params);
  },
  updateHaisetsuKeisan(taskForceId: number, params: UpdateHaisetsuKeisanParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(
      `/johaisetsu_planning/${taskForceId}/haisetsu_keisan`,
    ), params);
  },
  updateProgressTable(taskForceId: number, params: UpdateProgressTableParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(
      `/johaisetsu_planning/${taskForceId}/progress_table`,
    ), params);
  },
  proceedFlowStep(taskForceId: number): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl(
      `/johaisetsu_planning/${taskForceId}/proceed_flow_step`,
    ));
  },
};
