
import { JohaisetsuSagyouJoukyouRoadName } from '@/models/apis/master/masterResponse';
import { KanriBlock,
  StatusMap,
  JoukyouInfoRaw,
  SagyouJoukyouDetailRaw,
  SagyouJoukyouDetail,
  KanriBlockMapRaw,
  KanriBlockData,
  KanriBlockMap} from '@/models/apis/johaisetsu/johaisetsuCommon';
import { DateTimeError, SagyouJoukyouInputError } from '@/models/johaisetsuCommon';
import { dateStrToDateWithoutTime, dateStrToDate, dtFormat, timeToString } from '@/lib/dateHelper';
import { convertStrToTime } from '@/lib/utils';
import {
  JOHAISETSU_STATUS_JOSETSU_CHU,
  JOHAISETSU_STATUS_HAISETSU_CHU,
  JOHAISETSU_STATUS_HAISETSU_KANRYOU,
} from '@/consts/johaisetsu';

export function getJohaisetsuSagyouJoukyouRoadNameDirectionsShutokou(): JohaisetsuSagyouJoukyouRoadName[] {
  const srcArr = [
    ['t-west', '都心環状線', '外,内'],
    ['t-west', '八重洲線', '南,北'],
    ['t-west', '1号上野線', '下,上'],
    ['t-west', '1号羽田線', '下,上'],
    ['t-west', '2号目黒線', '下,上'],
    ['t-west', '3号渋谷線', '下,上'],
    ['t-west', '4号新宿線', '下,上'],
    ['t-west', '中央環状線(西)', '外,内'],
    ['t-west', '中央環状線(大井)', '外'],
    ['t-west', '5号池袋線(西)', '下,上'],
    ['t-east', '5号池袋線(東)', '下,上'],
    ['t-east', '6号向島線', '下,上'],
    ['t-east', '6号三郷線', '下,上'],
    ['t-east', '7号小松川線', '下,上'],
    ['t-east', '9号深川線', '下,上'],
    ['t-east', '10号晴海線', '上,下'],
    ['t-east', '11号台場線', '下,上'],
    ['t-east', '中央環状線(東)', '外,内'],
    ['t-east', '川口線', '下,上'],
    ['t-east', '湾岸線(東京)', '東,西'],
    ['t-east', '埼玉大宮線', '下,上'],
    ['t-east', '埼玉新都心線', '下,上'],
    ['kanagawa', '1号横羽線', '下,上'],
    ['kanagawa', '2号三ツ沢線', '下,上'],
    ['kanagawa', '3号狩場線', '下,上'],
    ['kanagawa', '5号大黒線', '上,下'],
    ['kanagawa', '6号川崎線', '下,上'],
    ['kanagawa', '7号横浜北線', '下,上'],
    ['kanagawa', '7号横浜北西線', '下,上'],
    ['kanagawa', '湾岸線(神奈川)', '東,西'],
  ];
  const ret: JohaisetsuSagyouJoukyouRoadName[] = [];
  const areaToAreaDisp = (area: string): string => {
    if (area === 't-west') { return '東京西局'; }
    if (area === 't-east') { return '東京東局'; }
    if (area === 'kanagawa') { return '神奈川管理局'; }
    return '';
  };
  srcArr.forEach((e, i) => {
    const key = i + 1;
    const area = e[0];
    const areaDisp = areaToAreaDisp(area);
    const roadNameDisp = e[1];
    const directions = e[2].split(',');
    ret.push({ key, area, areaDisp, roadNameDisp, directions });
  });
  return ret;
}

export function filterJohaisetsuSagyouJoukyouRoadNameInfoByRole(roadNameInfos: JohaisetsuSagyouJoukyouRoadName[], role: string): JohaisetsuSagyouJoukyouRoadName[] {
  // ユーザーが本社の場合は全部、局の場合は見れる路線を絞り込む
  if (role === 'honsha') { return roadNameInfos; }
  if (!['t-west', 't-east', 'kanagawa'].includes(role)) { return []; }
  return roadNameInfos.filter(e => e.area === role);
}

export function filterJohaisetsuSagyouJoukyouDetailsByRole(sagyouJoukyouDetails: SagyouJoukyouDetailRaw[], role: string): SagyouJoukyouDetailRaw[] {
  // ユーザーが本社の場合は全部、局の場合は見れるデータを絞り込む
  if (role === 'honsha') { return sagyouJoukyouDetails; }
  if (!['t-west', 't-east', 'kanagawa'].includes(role)) { return []; }
  return sagyouJoukyouDetails.filter(e => e.kanri_block?.area === role);
}

// // 路線#方向が一致する管理ブロックを全量取得する
function getJohaisetsuKanriBlockInfo(k: string): KanriBlockMap | null {
  const srcObj = window.johaisetsuMaster.kanriBlockMap[k];
  if (!srcObj) { return null; }
  const dstObj = {} as KanriBlockMap;
  const props1 = ['start', 'end', 'main_line', 'gateway'];
  props1.forEach(prop1 => {
    const propKey = prop1 as keyof KanriBlockMapRaw;
    const srcArr = srcObj[propKey];
    if (!srcArr) { return; }
    const dstArr = srcArr.map(srcElem => {
      return {
        ...srcElem,
        use_start_date: dateStrToDate(srcElem.use_start_date),
        use_end_date: dateStrToDate(srcElem.use_end_date),
      } as KanriBlock;
    });
    const retPropKey = prop1 as keyof KanriBlockMap;
    (dstObj[retPropKey] as KanriBlock[]) = dstArr;
  });
  dstObj.key = k;
  return dstObj;
}

// // 与えられたsagyouJoukyouInfoに対して有効な管理ブロックのみを抽出する
function filterKanriBlockInfoElements(kanriBlockInfo: KanriBlockMap, selectedSagyouJoukyouInfo: JoukyouInfoRaw<SagyouJoukyouDetailRaw> | null): KanriBlockMap | null {
  let ts: Date | null = new Date();
  if (selectedSagyouJoukyouInfo?.header && selectedSagyouJoukyouInfo?.header.timestamp !== undefined) {
    ts = dateStrToDate(selectedSagyouJoukyouInfo?.header.timestamp);
  }

  const props1 = ['start', 'end', 'main_line', 'gateway'];
  props1.forEach(prop => {
    const propKey = prop as keyof KanriBlockMap;
    const arr = (kanriBlockInfo[propKey] as KanriBlock[]).filter(e => {
      if (!ts || !e.use_end_date || !e.use_start_date) { return false; }
      const endTs = new Date(e.use_end_date.valueOf() + 86400 * 1000);
      return e.use_start_date <= ts && ts < endTs;
    });

    (kanriBlockInfo[propKey] as KanriBlock[]) = arr;
  });

  // 1個もなくなってしまった場合はもう色々と諦める
  const props2 = ['start', 'end', 'main_line'];
  for (const prop of props2) {
    const propKey = prop as keyof KanriBlockMap;
    const arr = kanriBlockInfo[propKey] as KanriBlock[];
    if (arr.length === 0) {
      console.error(`prop:${prop} of kanriBlockInfo:${kanriBlockInfo.key} is missing`);
      return null;
    }
  }
  return kanriBlockInfo;
}

function getEmptySagyouJoukyouDetail(kanriBlockId?: string): SagyouJoukyouDetailRaw {
  const defaultStatusId = 0;
  return {
    kanri_block_id: kanriBlockId,
    status: defaultStatusId,
    genba_sekininsha: null,
    genba_kantoku_han: null,
    sagyou_gaisha: null,
    hansuu: null,
    josetsu_start_date: null,
    josetsu_start_time: null,
    josetsu_estimated_date: null,
    josetsu_estimated_time: null,
    josetsu_end_date: null,
    josetsu_end_time: null,
    haisetsu_start_date: null,
    haisetsu_start_time: null,
    haisetsu_estimated_date: null,
    haisetsu_estimated_time: null,
    haisetsu_end_date: null,
    haisetsu_end_time: null,
    bikou1: null,
  };
}

export function updateStatusDependentParams(inputMap: SagyouJoukyouDetail, statusMasterMap: { [key: string]: StatusMap }): void {
  const st = statusMasterMap[inputMap.status?.toString() || ''];
  inputMap.statusName = st.name;
  inputMap.statusColor = st.color;
  inputMap.statusBgColor = st.bg_color;
  updateDateTimes(inputMap);
}

function updateDateTimes(inputMap: SagyouJoukyouDetail): void {
  const today = new Date();
  const hour = ('0' + today.getHours()).slice(-2);
  const minute = ('0' + today.getMinutes()).slice(-2);
  today.setHours(0);
  today.setMinutes(0);
  today.setMilliseconds(0);

  if (inputMap.status && inputMap.status >= JOHAISETSU_STATUS_JOSETSU_CHU) {
    if (!inputMap.josetsu_start_date) {
      inputMap.josetsu_start_date = today;
      inputMap.josetsu_start_time_h = hour;
      inputMap.josetsu_start_time_m = minute;
    }
  }
  if (inputMap.status && inputMap.status >= JOHAISETSU_STATUS_HAISETSU_CHU) {
    if (!inputMap.josetsu_end_date) {
      inputMap.josetsu_end_date = today;
      inputMap.josetsu_end_time_h = hour;
      inputMap.josetsu_end_time_m = minute;
    }
    if (!inputMap.haisetsu_start_date) {
      inputMap.haisetsu_start_date = today;
      inputMap.haisetsu_start_time_h = hour;
      inputMap.haisetsu_start_time_m = minute;
    }
  }
  if (inputMap.status && inputMap.status >= JOHAISETSU_STATUS_HAISETSU_KANRYOU) {
    if (!inputMap.haisetsu_end_date) {
      inputMap.haisetsu_end_date = today;
      inputMap.haisetsu_end_time_h = hour;
      inputMap.haisetsu_end_time_m = minute;
    }
  }
}

// // 画面の構成部品を準備する
export function getStructurizedKanriBlockData(
  roadNameInfo: JohaisetsuSagyouJoukyouRoadName,
  selectedSagyouJoukyouInfo: JoukyouInfoRaw<SagyouJoukyouDetailRaw> | null,
  statusMasterMap: { [key: string]: StatusMap },
): KanriBlockData {
  const detailMap = selectedSagyouJoukyouInfo?.details.reduce((acc: Record<string, SagyouJoukyouDetailRaw>, e) => {
    acc[e.kanri_block_id || ''] = e; return acc;
  }, {});

  let diagramUpper = null;
  let diagramLower = null;
  const tableMainLineBlocks: KanriBlock[] = [];
  const tableGateways: KanriBlock[] = [];

  roadNameInfo.directions.forEach((direction, i) => {
    if (i >= 2) { return; } // 方向は最大2個のはず
    const kanriBlockMapKey = `${roadNameInfo.roadNameDisp}#${direction}`;
    let kanriBlockInfo = getJohaisetsuKanriBlockInfo(kanriBlockMapKey);
    if (!kanriBlockInfo) { return; }
    kanriBlockInfo = filterKanriBlockInfoElements(
      kanriBlockInfo,
      selectedSagyouJoukyouInfo,
    );
    if (!kanriBlockInfo) { return; }
    kanriBlockInfo.start = (kanriBlockInfo.start as KanriBlock[])[0];
    kanriBlockInfo.end = (kanriBlockInfo.end as KanriBlock[])[0];
    // 今のところ意味はないが一応block_codeでソートしておく.
    // 将来的には表示順カラムを追加した方がいいかもしれない.
    // もしくは、マスタ更新時にはblock_codeを丸ごと洗い替えて
    // 番号順に並ぶよう管理すれば表示順はいらないかもしれない.
    kanriBlockInfo.main_line.sort((a, b) => {
      const blockCode1 = a.block_code || '0';
      const blockCode2 = b.block_code || '0';
      return blockCode1 < blockCode2 ? -1 : 1;
    });
    kanriBlockInfo.gateway.sort((a, b) => {
      const blockCode1 = a.block_code || '0';
      const blockCode2 = b.block_code || '0';
      return blockCode1 < blockCode2 ? -1 : 1;
    });

    const tmpKanriBlocks = [
      kanriBlockInfo.start,
      kanriBlockInfo.end,
      ...kanriBlockInfo.main_line,
      ...kanriBlockInfo.gateway,
    ];
    // kanri_blockについて一つずつ、対応するsagyou_joukyou_detailと紐付ける.
    tmpKanriBlocks.forEach(kanriBlock => {
      if (!kanriBlock.id) { return; }
      if (detailMap && !detailMap[kanriBlock.id]) {
        detailMap[kanriBlock.id] = getEmptySagyouJoukyouDetail(kanriBlock.id);
      }
      if (!detailMap) { return; }
      kanriBlock.origInputMap = convProps(detailMap[kanriBlock.id]);
      kanriBlock.inputMap = convProps(detailMap[kanriBlock.id]);
      updateStatusDependentParams(kanriBlock.inputMap, statusMasterMap);
      if (!kanriBlock.is_editable) {
        // 編集不可の場合はあんまり目立たないよう背景色と同じ色にする.
        // (工区の区切り線と文字が重なってしまう場合があるので、色は消さない)
        kanriBlock.inputMap.statusBgColor = '#f3f3ff';
      }
    });

    if (i === 0) {
      diagramUpper = kanriBlockInfo;
    } else if (i === 1) {
      diagramLower = kanriBlockInfo;
    }

    const mainLineBlocks = kanriBlockInfo.main_line.slice();
    mainLineBlocks.forEach((e, i) => {
      e.dispLength = (e.disp_pos_end || 0) - (e.disp_pos_start || 0);
      e.isFirst = i === 0;
      e.isLast = i === mainLineBlocks.length - 1;
    });
    // is_editableじゃないものは下の表には入れない
    tableMainLineBlocks.push(...mainLineBlocks.filter(e => e.is_editable));

    const gateways = kanriBlockInfo.gateway.slice();
    if (kanriBlockInfo.start.is_editable) {
      gateways.unshift(kanriBlockInfo.start);
    }
    if (kanriBlockInfo.end.is_editable) {
      gateways.push(kanriBlockInfo.end);
    }
    gateways.forEach(e => {
      // 出入り口の幅は一律2単位で格納されている.
      let adjustedDispPosStart = (e.disp_pos_start || 0) + 1;
      if (e.alignment === 'center') {
        // css側でtranslate(-50%)するので、position:absoluteの開始位置を
        // 1単位右にずらす.
        adjustedDispPosStart = (e.disp_pos_start || 0) + 1;
      } else if (e.alignment === 'left') {
        // そのまま.
        adjustedDispPosStart = (e.disp_pos_start || 0);
      } else if (e.alignment === 'right') {
        // 右側に合わせる.
        adjustedDispPosStart = (e.disp_pos_start || 0) + 2;
      }
      e.adjustedDispPosStart = adjustedDispPosStart;
    });
    // is_editableじゃないものは下の表には入れない
    tableGateways.push(...gateways.filter(e => e.is_editable));
  });

  return { diagramUpper, diagramLower, tableMainLineBlocks, tableGateways };
}

export function convProps(sagyouJoukyouDetail: SagyouJoukyouDetailRaw): SagyouJoukyouDetail {
  const josetsuStartTime = timeToString(sagyouJoukyouDetail.josetsu_start_time);
  const josetsuEstimatedTime = timeToString(sagyouJoukyouDetail.josetsu_estimated_time);
  const josetsuEndTime = timeToString(sagyouJoukyouDetail.josetsu_end_time);
  const haisetsuStartTime = timeToString(sagyouJoukyouDetail.haisetsu_start_time);
  const haisetsuEstimatedTime = timeToString(sagyouJoukyouDetail.haisetsu_estimated_time);
  const haisetsuEndTime = timeToString(sagyouJoukyouDetail.haisetsu_end_time);

  return {
    ...sagyouJoukyouDetail,
    kanri_block: {
      ...sagyouJoukyouDetail.kanri_block,
      use_start_date: dateStrToDate(sagyouJoukyouDetail.kanri_block?.use_start_date),
      use_end_date: dateStrToDate(sagyouJoukyouDetail.kanri_block?.use_end_date),
    },
    josetsu_start_date: dateStrToDateWithoutTime(sagyouJoukyouDetail.josetsu_start_date),
    josetsu_estimated_date: dateStrToDateWithoutTime(sagyouJoukyouDetail.josetsu_estimated_date),
    josetsu_end_date: dateStrToDateWithoutTime(sagyouJoukyouDetail.josetsu_end_date),
    haisetsu_start_date: dateStrToDateWithoutTime(sagyouJoukyouDetail.haisetsu_start_date),
    haisetsu_estimated_date: dateStrToDateWithoutTime(sagyouJoukyouDetail.haisetsu_estimated_date),
    haisetsu_end_date: dateStrToDateWithoutTime(sagyouJoukyouDetail.haisetsu_end_date),
    josetsu_start_time_h: josetsuStartTime[0],
    josetsu_estimated_time_h: josetsuEstimatedTime[0],
    josetsu_end_time_h: josetsuEndTime[0],
    haisetsu_start_time_h: haisetsuStartTime[0],
    haisetsu_estimated_time_h: haisetsuEstimatedTime[0],
    haisetsu_end_time_h: haisetsuEndTime[0],
    josetsu_start_time_m: josetsuStartTime[1],
    josetsu_estimated_time_m: josetsuEstimatedTime[1],
    josetsu_end_time_m: josetsuEndTime[1],
    haisetsu_start_time_m: haisetsuStartTime[1],
    haisetsu_estimated_time_m: haisetsuEstimatedTime[1],
    haisetsu_end_time_m: haisetsuEndTime[1],
  };
}

export function getClearedErrorMap(items: KanriBlock[]): Record<string, SagyouJoukyouInputError> {
  const errorMap: Record<string, SagyouJoukyouInputError> = {};
  items.forEach(item => {
    if (item.id) {
      errorMap[item.id] = {
        josetsu_start: {} as DateTimeError,
        josetsu_estimated: {} as DateTimeError,
        josetsu_end: {} as DateTimeError,
        haisetsu_start: {} as DateTimeError,
        haisetsu_estimated: {} as DateTimeError,
        haisetsu_end: {} as DateTimeError,
      };
    }
  });
  return errorMap;
}

export function checkItems(items: KanriBlock[], errorMap: Record<string, SagyouJoukyouInputError>): boolean {
  const paramPfxs = [
    'josetsu_start',
    'josetsu_estimated',
    'josetsu_end',
    'haisetsu_start',
    'haisetsu_estimated',
    'haisetsu_end',
  ];

  let isOk = true;
  items.forEach(item => {
    const inputMap = item.inputMap;
    const errorObj = errorMap[item.id || ''];
    paramPfxs.forEach(paramPfx => {
      const dateParam = paramPfx + '_date';
      const hourParam = paramPfx + '_time_h';
      const minuteParam = paramPfx + '_time_m';
      const paramErrorObj = {} as DateTimeError;
      // date-pickerはreadonlyにしてるのでこれ単体でエラーにはならない
      paramErrorObj.date = false;
      const hourParamKey = hourParam as keyof SagyouJoukyouDetail;
      paramErrorObj.hour = !!(inputMap && inputMap[hourParamKey] &&
        !(inputMap[hourParamKey] as string).match(/^\d*$/));
      const minuteParamKey = minuteParam as keyof SagyouJoukyouDetail;
      paramErrorObj.minute = !!(inputMap && inputMap[minuteParamKey] &&
        !(inputMap[minuteParamKey] as string).match(/^\d*$/));

      const dateParamParamKey = dateParam as keyof SagyouJoukyouDetail;
      // 全て空か、全て入っているか、でなければエラーとしたい
      if (inputMap) {
        const tmpVals = [inputMap[dateParamParamKey], inputMap[hourParamKey], inputMap[minuteParamKey]];
        const tmpCount = tmpVals.reduce((acc: number, e) => {
          const num = (e !== null && e !== '') ? 1 : 0;
          return acc + num;
        }, 0);
        paramErrorObj.all = !(tmpCount === 0 || tmpCount === tmpVals.length);
      }
      const paramPfxKey = paramPfx as keyof SagyouJoukyouInputError;
      (errorObj[paramPfxKey] as DateTimeError) = paramErrorObj;

      isOk = isOk &&
        !paramErrorObj.date &&
        !paramErrorObj.hour &&
        !paramErrorObj.minute &&
        !paramErrorObj.all;
    });
    if (inputMap && inputMap.status) {
      if (inputMap.status >= JOHAISETSU_STATUS_JOSETSU_CHU) {
        if (!inputMap.josetsu_start_date && errorObj.josetsu_start) {
          isOk = false;
          errorObj.josetsu_start.all = true;
        }
      }
      if (inputMap.status >= JOHAISETSU_STATUS_HAISETSU_CHU) {
        if (!inputMap.josetsu_end_date && errorObj.josetsu_end) {
          isOk = false;
          errorObj.josetsu_end.all = true;
        }
        if (!inputMap.haisetsu_start_date && errorObj.haisetsu_start) {
          isOk = false;
          errorObj.haisetsu_start.all = true;
        }
      }
      if (inputMap.status >= JOHAISETSU_STATUS_HAISETSU_KANRYOU) {
        if (!inputMap.haisetsu_end_date && errorObj.haisetsu_end) {
          isOk = false;
          errorObj.haisetsu_end.all = true;
        }
      }
    }
  });
  return isOk;
}

export function organizeDateTimeProps(sagyouJoukyouDetail: SagyouJoukyouDetail): void {
  if (sagyouJoukyouDetail.status && sagyouJoukyouDetail.status < JOHAISETSU_STATUS_HAISETSU_KANRYOU) {
    sagyouJoukyouDetail.haisetsu_end_date = null;
    sagyouJoukyouDetail.haisetsu_end_time_h = null;
    sagyouJoukyouDetail.haisetsu_end_time_m = null;
  }
  if (sagyouJoukyouDetail.status && sagyouJoukyouDetail.status < JOHAISETSU_STATUS_HAISETSU_CHU) {
    sagyouJoukyouDetail.haisetsu_estimated_date = null;
    sagyouJoukyouDetail.haisetsu_estimated_time_h = null;
    sagyouJoukyouDetail.haisetsu_estimated_time_m = null;
    sagyouJoukyouDetail.haisetsu_start_date = null;
    sagyouJoukyouDetail.haisetsu_start_time_h = null;
    sagyouJoukyouDetail.haisetsu_start_time_m = null;
  }
  if (sagyouJoukyouDetail.status && sagyouJoukyouDetail.status < JOHAISETSU_STATUS_JOSETSU_CHU) {
    sagyouJoukyouDetail.josetsu_end_date = null;
    sagyouJoukyouDetail.josetsu_end_time_h = null;
    sagyouJoukyouDetail.josetsu_end_time_m = null;
    sagyouJoukyouDetail.josetsu_estimated_date = null;
    sagyouJoukyouDetail.josetsu_estimated_time_h = null;
    sagyouJoukyouDetail.josetsu_estimated_time_m = null;
    sagyouJoukyouDetail.josetsu_start_date = null;
    sagyouJoukyouDetail.josetsu_start_time_h = null;
    sagyouJoukyouDetail.josetsu_start_time_m = null;
  }
}

export function deconvProps(sagyouJoukyouDetail: SagyouJoukyouDetail): SagyouJoukyouDetailRaw {
  organizeDateTimeProps(sagyouJoukyouDetail);

  return {
    ...sagyouJoukyouDetail,
    kanri_block: {
      ...sagyouJoukyouDetail.kanri_block,
      use_start_date: dtFormat(sagyouJoukyouDetail.kanri_block?.use_start_date),
      use_end_date: dtFormat(sagyouJoukyouDetail.kanri_block?.use_end_date),
    },
    josetsu_start_date: dtFormat(sagyouJoukyouDetail.josetsu_start_date, 'yyyy-mm-dd'),
    josetsu_start_time: convertStrToTime(sagyouJoukyouDetail.josetsu_start_time_h, sagyouJoukyouDetail.josetsu_start_time_m),
    josetsu_estimated_date: dtFormat(sagyouJoukyouDetail.josetsu_estimated_date, 'yyyy-mm-dd'),
    josetsu_estimated_time: convertStrToTime(sagyouJoukyouDetail.josetsu_estimated_time_h, sagyouJoukyouDetail.josetsu_estimated_time_m),
    josetsu_end_date: dtFormat(sagyouJoukyouDetail.josetsu_end_date, 'yyyy-mm-dd'),
    josetsu_end_time: convertStrToTime(sagyouJoukyouDetail.josetsu_end_time_h, sagyouJoukyouDetail.josetsu_end_time_m),
    haisetsu_start_date: dtFormat(sagyouJoukyouDetail.haisetsu_start_date, 'yyyy-mm-dd'),
    haisetsu_start_time: convertStrToTime(sagyouJoukyouDetail.haisetsu_start_time_h, sagyouJoukyouDetail.haisetsu_start_time_m),
    haisetsu_estimated_date: dtFormat(sagyouJoukyouDetail.haisetsu_estimated_date, 'yyyy-mm-dd'),
    haisetsu_estimated_time: convertStrToTime(sagyouJoukyouDetail.haisetsu_estimated_time_h, sagyouJoukyouDetail.haisetsu_estimated_time_m),
    haisetsu_end_date: dtFormat(sagyouJoukyouDetail.haisetsu_end_date, 'yyyy-mm-dd'),
    haisetsu_end_time: convertStrToTime(sagyouJoukyouDetail.haisetsu_end_time_h, sagyouJoukyouDetail.haisetsu_end_time_m),
  };
}
