
import { Allocation,
  EditStatuses,
  ProgressTableTimeBlock,
  TimeBlock,
  SagyouJoukyou,
  ProgressTable,
  Haisetsu,
  Josetsu,
  ShinchokuJissekiJosetsu,
  JoukyouInfo,
  Detail,
  SagyouJoukyouDetail } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { TaskForce,
  PlanningBlock,
  HoursPerKMGroupCm,
  PlanningInfo} from '@/models/johaisetsuCommon';
import { getJohaisetsuPlanningBlocks, getJohaisetsuHoursPerKmGroupCm } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { unpackTimeInteger } from '@/lib/dateTimeUtil';
import { ensureDate } from '@/lib/dateHelper';
import { mixStrNumToString, mixStrNumToNum } from '@/lib/stringUtil';

export const PREPARE_TYPE_HAISETSU_KEISAN = 'haisetsu_keisan';
export const PREPARE_TYPE_PROGRESS_TABLE = 'progress_table';
export const PREPARE_TYPE_OTHER = 'other';
export const blockIdxs = [0, 1, 2, 3, 4, 5];
export const hoursPerBlock = 12;

function filterJohaisetsuPlanningBlocksByRole(planningBlocks: PlanningBlock[], role: string): PlanningBlock[] {
  // ユーザーが本社の場合は全部、局の場合は見れる路線を絞り込む
  if (role === 'honsha') { return planningBlocks; }
  if (!['t-west', 't-east', 'kanagawa'].includes(role)) { return []; }
  return planningBlocks.filter(e => e.area === role);
}

function filterPlanningMastesrBySelectedPlanningInfo(
  planningBlocks: PlanningBlock[],
  hoursPerKmGroupCmArr: HoursPerKMGroupCm[],
  selectedPlanningInfo: JoukyouInfo,
): PlanningInfo {
  let ts: Date = new Date();
  if (selectedPlanningInfo.header) {
    ts = ensureDate(selectedPlanningInfo.header.timestamp) || new Date();
  }

  const ret: PlanningInfo = {
    planningBlocks: planningBlocks.filter(e => {
      if (e.use_end_date == null || e.use_start_date == null) { return false; }
      const endTs = new Date(e.use_end_date.valueOf() + 86400 * 1000);
      return e.use_start_date <= ts && ts < endTs;
    }),
    hoursPerKmGroupCmArr: hoursPerKmGroupCmArr.filter(e => {
      if (e.use_end_date == null || e.use_start_date == null) { return false; }
      const endTs = new Date(e.use_end_date.valueOf() + 86400 * 1000);
      return e.use_start_date <= ts && ts < endTs;
    }),
  };
  return ret;
}

function getEmptyPlanningDetail(planningBlock: PlanningBlock): Detail {
  return {
    planning_block_id: planningBlock.id,
    reopening_priority: null,
    snow_height_cm: null,
    required_hours_per_km_group: null,
    adjusted_required_hours_per_km_group: null,
    time_blocks1: [],
    time_blocks2: [],
  };
}

function ensurePlanningBlockTimeBlocks1(inputMap: Detail) {
  const emptyBlock = {
    time_block_idx: -1,
    allocated_groups: 0,
    haisetsu_hours: 0,
    haisetsu_distance: 0,
    haisetsu_remaining_distance: null,
  };
  const timeBlockMap = inputMap.time_blocks1.reduce((acc: Record<number, TimeBlock>, e) => {
    acc[mixStrNumToNum(e.time_block_idx)] = e; return acc;
  }, {});
  const convArr = blockIdxs.map(blockIdx => {
    if (timeBlockMap[blockIdx]) {
      const timeBlock = timeBlockMap[blockIdx];
      // timeBlock.haisetsu_hours = parseFloat(timeBlock.haisetsu_hours)
      // timeBlock.haisetsu_distance = parseFloat(timeBlock.haisetsu_distance)
      // timeBlock.haisetsu_remaining_distance = parseFloat(timeBlock.haisetsu_remaining_distance)
      return timeBlock;
    } else {
      return Object.assign({}, emptyBlock, { time_block_idx: blockIdx } as TimeBlock);
    }
  });
  inputMap.time_blocks1 = convArr;
}

function ensurePlanningBlockTimeBlocks2(inputMap: Detail) {
  const emptyBlock = {
    time_block_idx: -1,
    josetsu_han: '',
    josetsu_hours: 0,
    josetsu_start_offset: 0,
    haisetsu_start_offset: 0,
  };
  const timeBlockMap = inputMap.time_blocks2.reduce((acc: Record<string, ProgressTableTimeBlock>, e) => {
    acc[e.time_block_idx] = e; return acc;
  }, {});
  const convArr = blockIdxs.map(blockIdx => {
    let ret = {} as ProgressTableTimeBlock;
    if (timeBlockMap[blockIdx]) {
      const timeBlock = timeBlockMap[blockIdx];
      // DB的にはfloatだが、画面上ではintとしてしか扱わない
      timeBlock.josetsu_hours = parseInt(mixStrNumToString(timeBlock.josetsu_hours));
      timeBlock.josetsu_start_offset = parseInt(mixStrNumToString(timeBlock.josetsu_start_offset));
      timeBlock.haisetsu_start_offset = parseInt(mixStrNumToString(timeBlock.haisetsu_start_offset));

      ret = timeBlock;
    } else {
      ret = Object.assign({}, emptyBlock, { time_block_idx: blockIdx });
    }
    ret.haisetsu_start_hour = blockIdx * hoursPerBlock + (mixStrNumToNum(ret.haisetsu_start_offset));
    return ret;
  });
  inputMap.time_blocks2 = convArr;
}

function ensurePlanningBlockTimeBlocks(inputMap: Detail): void {
  inputMap.progressTable = {} as ProgressTable;
  ensurePlanningBlockTimeBlocks1(inputMap);
  ensurePlanningBlockTimeBlocks2(inputMap);
  inputMap.editStatuses = blockIdxs.map(() => {
    return {
      isJosetsuEditMode: false,
      josetsuEditInfo: {
        josetsu_han: '',
        josetsu_start_offset: 0,
        josetsu_hours: 0,
      },
      isHaisetsuEditMode: false,
      showHaisetsuEditControls: false,
      haisetsuEditInfo: {
        haisetsu_start_offset: 0,
        haisetsu_end_offset: 0,
      },
    } as EditStatuses;
  });
}

function createSagyouJoukyouMap(sagyouJoukyouDetails: SagyouJoukyouDetail[]): Record<string, SagyouJoukyouDetail[]> {
  // まずは路線#方向でまとめる
  if (!sagyouJoukyouDetails) { return {}; }
  const ret: Record<string, SagyouJoukyouDetail[]> = {};
  sagyouJoukyouDetails.forEach(e => {
    if (!e.kanri_block) { return; }
    const k1 = e.kanri_block.road_name_disp + '#' + e.kanri_block.direction;
    if (!ret[k1]) { ret[k1] = []; }
    ret[k1].push(e);
  });
  return ret;
}

function dateFromDateAndTime(dateOrDateStr: string | Date, timeInteger: number): Date {
  let date = new Date();
  if (typeof dateOrDateStr === 'string') {
    date = new Date(dateOrDateStr);
  } else {
    date = dateOrDateStr;
  }
  const [h, m, s] = unpackTimeInteger(timeInteger);
  date.setHours(h);
  date.setMinutes(m);
  date.setSeconds(s);
  return date;
}

function calcSagyouJoukyouInfo(planningBlock: PlanningBlock, sagyouJoukyouMap: Record<string, SagyouJoukyouDetail[]>): SagyouJoukyou {
  const emptyRet = {
    josetsuStartDateTime: null,
    josetsuFinishDateTime: null,
    haisetsuStartDateTime: null,
    haisetsuFinishDateTime: null,
  };
  const k1 = planningBlock.road_name_disp + '#' + planningBlock.direction;
  const sagyouJoukyous = sagyouJoukyouMap[k1];
  // // memo debug point
  // const sagyouJoukyous = [{
  //   is_editable: true,
  //   place_type: 'main_line',
  //   start_kp: 0,
  //   end_kp: 100,
  //   josetsu_start_date: '2020-08-16',
  //   josetsu_start_time: 0,
  //   josetsu_end_date: '2020-08-16',
  //   josetsu_end_time: 113000,
  //   haisetsu_start_date: '2020-08-16',
  //   haisetsu_start_time: 113000,
  //   haisetsu_end_date: '2020-08-17',
  //   haisetsu_end_time: 223000,
  // }]
  if (!sagyouJoukyous) { return emptyRet; }
  // 自分の区間に関係あるものだけ拾う.
  // なお、kanri_blockはplanning_blockをまたぐことはないと仮定する.
  const planningBlockStartKp = parseFloat(planningBlock.start_kp);
  const planningBlockEndKp = parseFloat(planningBlock.end_kp);
  const filteredSagyouJoukyous = sagyouJoukyous.filter(sj => {
    const kanriBlock = sj.kanri_block;
    if (!kanriBlock || !kanriBlock.is_editable) { return false; }
    if (kanriBlock.place_type === 'main_line') {
      return kanriBlock.start_kp && planningBlockStartKp <= parseFloat(kanriBlock.start_kp) &&
        parseFloat(kanriBlock.start_kp) < planningBlockEndKp;
    } else {
      // gateway系は、endぴったりのものはそちらに含めて良い気がする
      return kanriBlock.start_kp && planningBlockStartKp <= parseFloat(kanriBlock.start_kp) &&
        parseFloat(kanriBlock.start_kp) <= planningBlockEndKp;
    }
  });

  let isJosetsuFinished = true;
  let isHaisetsuFinished = true;
  let josetsuStartDateTime: Date | null = null;
  let josetsuFinishDateTime: Date | null = null;
  let haisetsuStartDateTime: Date | null = null;
  let haisetsuFinishDateTime: Date | null = null;
  filteredSagyouJoukyous.forEach(sj => {
    // 除雪開始時刻
    if (sj.josetsu_start_date === null || sj.josetsu_start_time === null) {
      isJosetsuFinished = false;
      isHaisetsuFinished = false;
      return;
    }
    let tmpDateTime = null;
    if (sj.josetsu_start_date && sj.josetsu_start_time) {
      tmpDateTime = dateFromDateAndTime(sj.josetsu_start_date, sj.josetsu_start_time);
    }
    if (!josetsuStartDateTime || (tmpDateTime && josetsuStartDateTime > tmpDateTime)) {
      josetsuStartDateTime = tmpDateTime;
    }

    // 除雪終了時刻
    if (sj.josetsu_end_date === null || sj.josetsu_end_time === null) {
      isJosetsuFinished = false;
      isHaisetsuFinished = false;
      return;
    }
    if (sj.josetsu_end_date && sj.josetsu_end_time) {
      tmpDateTime = dateFromDateAndTime(sj.josetsu_end_date, sj.josetsu_end_time);
    }
    if (!josetsuFinishDateTime || (tmpDateTime && josetsuFinishDateTime < tmpDateTime)) {
      josetsuFinishDateTime = tmpDateTime;
    }

    // 排雪開始時刻
    if (sj.haisetsu_start_date === null || sj.haisetsu_start_time === null) {
      isHaisetsuFinished = false;
      return;
    }
    if (sj.haisetsu_start_date && sj.haisetsu_start_time) {
      tmpDateTime = dateFromDateAndTime(sj.haisetsu_start_date, sj.haisetsu_start_time);
    }
    if (!haisetsuStartDateTime || (tmpDateTime && haisetsuStartDateTime > tmpDateTime)) {
      haisetsuStartDateTime = tmpDateTime;
    }

    // 排雪終了時刻
    if (sj.haisetsu_end_date === null || sj.haisetsu_end_time === null) {
      isHaisetsuFinished = false;
      return;
    }
    if (sj.haisetsu_end_date && sj.haisetsu_end_time) {
      tmpDateTime = dateFromDateAndTime(sj.haisetsu_end_date, sj.haisetsu_end_time);
    }
    if (!haisetsuFinishDateTime || (tmpDateTime && haisetsuFinishDateTime < tmpDateTime)) {
      haisetsuFinishDateTime = tmpDateTime;
    }
  });
  if (!isJosetsuFinished) {
    josetsuFinishDateTime = null;
  }
  if (!isHaisetsuFinished) {
    haisetsuFinishDateTime = null;
  }
  return {
    josetsuStartDateTime,
    josetsuFinishDateTime,
    haisetsuStartDateTime,
    haisetsuFinishDateTime,
  };
}

export function recalcPlanningBlockHaisetsuKeisan(planningBlock: PlanningBlock): void {
  const inputMap = planningBlock.inputMap;
  if (!inputMap) {
    return;
  }
  inputMap.required_hours_per_km_group =
    ((inputMap._hours_per_km_group_per_cm || 0) * (inputMap.snow_height_cm || 0)).toString();

  let remDistance = planningBlock.converted_openair_length;
  let haisetsuAccumHours = 0;
  let accumNumGroups = 0;
  let timesAllocated = 0;
  let requiredHoursPerKmGroup = parseFloat(inputMap.required_hours_per_km_group || '0');
  if (inputMap.adjusted_required_hours_per_km_group) {
    requiredHoursPerKmGroup = parseFloat(inputMap.adjusted_required_hours_per_km_group || '0');
  }
  blockIdxs.forEach(blockIdx => {
    const timeBlock1 = inputMap.time_blocks1[blockIdx];
    const numGroups = mixStrNumToNum(timeBlock1.allocated_groups);
    let haisetsuHours = 0;
    let haisetsuDistance = 0;
    if (requiredHoursPerKmGroup > 0) {
      haisetsuDistance = numGroups * hoursPerBlock / requiredHoursPerKmGroup;
      haisetsuDistance = Math.min(remDistance, haisetsuDistance);
      if (numGroups > 0) {
        haisetsuHours = haisetsuDistance * requiredHoursPerKmGroup / numGroups;
      }
    }
    haisetsuAccumHours += haisetsuHours;
    remDistance -= haisetsuDistance;
    timeBlock1.haisetsu_hours = haisetsuHours;
    timeBlock1.haisetsu_accum_hours = haisetsuAccumHours;
    timeBlock1.haisetsu_distance = haisetsuDistance;
    timeBlock1.haisetsu_remaining_distance = remDistance;

    accumNumGroups += numGroups;
    if (numGroups > 0) { timesAllocated++; }
  });

  inputMap.accumNumGroups = accumNumGroups;
  inputMap.timesAllocated = timesAllocated;
  inputMap.haisetsuAccumHours = haisetsuAccumHours;
}

function getShinchokuKeikakuHitMap(planningBlock: PlanningBlock) {
  const inputMap = planningBlock.inputMap;
  const hitMapJosetsu: Record<number, Josetsu> = {};
  const hitMapHaisetsu: Record<number, Haisetsu> = {};
  if (!inputMap) {
    return { hitMapJosetsu, hitMapHaisetsu };
  }
  let hitJosetsuMax = { idx: 0, m0EndMark: false, m30EndMark: false };
  let hitHaisetsuMax = { idx: 0, m0EndMark: false, m30EndMark: false, remDistance: 9999 };
  // 色をつけるidxを記録
  blockIdxs.forEach(blockIdx => {
    const hourBase = blockIdx * hoursPerBlock;
    const timeBlock1 = inputMap.time_blocks1[blockIdx];
    const timeBlock2 = inputMap.time_blocks2[blockIdx];
    const josetsuHours = mixStrNumToNum(timeBlock2.josetsu_hours);
    const josetsuOffset = mixStrNumToNum(timeBlock2.josetsu_start_offset);
    // timeBlock1.haisetsu_hours = 12 // memo debug point
    const haisetsuHours = mixStrNumToNum(timeBlock1.haisetsu_hours); // ここだけtimeBlock1
    const haisetsuOffset = mixStrNumToNum(timeBlock2.haisetsu_start_offset);
    for (let i = 0; i < josetsuHours; i++) {
      // 選択は1時間単位になっているはず
      const idx = hourBase + josetsuOffset + i;
      hitMapJosetsu[idx] = { m0: true, m30: true, m0EndMark: false, m30EndMark: false };
      hitJosetsuMax = { idx, m0EndMark: false, m30EndMark: true };
    }
    for (let i = 0; i < haisetsuHours; i++) {
      // こちらはがんばって計算したやつなので、解像度を0.5hにしとこう
      const idx = hourBase + haisetsuOffset + i;
      hitMapHaisetsu[idx] = { m0: true, m30: haisetsuHours - i > 0.5, m0EndMark: false, m30EndMark: false };
      hitHaisetsuMax = { idx, m0EndMark: !hitMapHaisetsu[idx].m30, m30EndMark: hitMapHaisetsu[idx].m30, remDistance: mixStrNumToNum(timeBlock1.haisetsu_remaining_distance) };
    }
  });

  // 除雪は自分で画面で設定した一番右側が終わりのセル.
  // (今の所、除雪ではendMarkを画面表示していないが)
  let tmp = hitMapJosetsu[hitJosetsuMax.idx];
  if (tmp) {
    tmp.m0EndMark = hitJosetsuMax.m0EndMark;
    tmp.m30EndMark = hitJosetsuMax.m30EndMark;
  }
  // 排雪は計算表画面で残距離0になるように設定されているとは限らないので確認する.
  if (hitHaisetsuMax.remDistance < 0.00000001) {
    tmp = hitMapHaisetsu[hitHaisetsuMax.idx];
    if (tmp) {
      tmp.m0EndMark = hitHaisetsuMax.m0EndMark;
      tmp.m30EndMark = hitHaisetsuMax.m30EndMark;
    }
  }
  return { hitMapJosetsu, hitMapHaisetsu };
}

export function recalcPlanningBlockShinchokuKeikaku(planningBlock: PlanningBlock): void {
  const inputMap = planningBlock.inputMap;
  const { hitMapJosetsu, hitMapHaisetsu } = getShinchokuKeikakuHitMap(planningBlock);

  const shinchokuKeikakuJosetsu: ShinchokuJissekiJosetsu[][] = [];
  const shinchokuKeikakuHaisetsu: ShinchokuJissekiJosetsu[][] = [];
  blockIdxs.forEach(blockIdx => {
    const hourBase = blockIdx * hoursPerBlock;
    const blockHourIdxs = [...Array(hoursPerBlock).keys()];
    shinchokuKeikakuJosetsu.push(blockHourIdxs.map(hourIdx => {
      return {
        hour: hourBase + hourIdx,
        hourInBlock: hourIdx,
        selected: hitMapJosetsu[hourBase + hourIdx] ||
          { m0: false, m30: false, m0EndMark: false, m30EndMark: false },
      };
    }));
    shinchokuKeikakuHaisetsu.push(blockHourIdxs.map(hourIdx => {
      return {
        hour: hourBase + hourIdx,
        hourInBlock: hourIdx,
        selected: hitMapHaisetsu[hourBase + hourIdx] ||
          { m0: false, m30: false, m0EndMark: false, m30EndMark: false },
      };
    }));
  });
  if (inputMap?.progressTable !== undefined) {
    inputMap.progressTable.shinchokuKeikakuJosetsu = shinchokuKeikakuJosetsu;
    inputMap.progressTable.shinchokuKeikakuHaisetsu = shinchokuKeikakuHaisetsu;
  }
}

function getShinchokuJissekiHitMap(planningBlock: PlanningBlock) {
  const sjInfo = planningBlock.sagyouJoukyouInfo;

  const hitMapJosetsu: Record<number, Haisetsu> = {};
  const hitMapHaisetsu: Record<number, Haisetsu> = {};
  if (!sjInfo) {
    return { hitMapJosetsu, hitMapHaisetsu };
  }
  const blockHourIdxs = [...Array(hoursPerBlock).keys()];

  const baseDateTs = planningBlock.taskForceStartDate?.valueOf() || 0;
  const nowHourOffset = (new Date().valueOf() - baseDateTs) / 3600 / 1000;
  let josetsuStartHourOffset = 9999999;
  let josetsuFinishHourOffset = 9999999;
  let josetsuProgressHourOffset = nowHourOffset;
  let haisetsuStartHourOffset = 9999999;
  let haisetsuFinishHourOffset = 9999999;
  let haisetsuProgressHourOffset = nowHourOffset;
  if (sjInfo.josetsuStartDateTime) {
    const msecDiff = sjInfo.josetsuStartDateTime.valueOf() - baseDateTs;
    josetsuStartHourOffset = msecDiff / 3600 / 1000;
  }
  if (sjInfo.josetsuFinishDateTime) {
    const msecDiff = sjInfo.josetsuFinishDateTime.valueOf() - baseDateTs;
    josetsuFinishHourOffset = msecDiff / 3600 / 1000;
    josetsuProgressHourOffset = josetsuFinishHourOffset;
  }
  if (sjInfo.haisetsuStartDateTime) {
    const msecDiff = sjInfo.haisetsuStartDateTime.valueOf() - baseDateTs;
    haisetsuStartHourOffset = msecDiff / 3600 / 1000;
  }
  if (sjInfo.haisetsuFinishDateTime) {
    const msecDiff = sjInfo.haisetsuFinishDateTime.valueOf() - baseDateTs;
    haisetsuFinishHourOffset = msecDiff / 3600 / 1000;
    haisetsuProgressHourOffset = haisetsuFinishHourOffset;
  }

  // 色をつけるidxを記録
  blockIdxs.forEach(blockIdx => {
    const hourBase = blockIdx * hoursPerBlock;
    blockHourIdxs.forEach(hourIdx => {
      const hourOffsetMin0 = hourBase + hourIdx;
      const hourOffsetMin30 = hourOffsetMin0 + 0.5;

      const hitJosetsuMin0 = josetsuStartHourOffset - 0.5 < hourOffsetMin0 &&
        hourOffsetMin0 < josetsuProgressHourOffset;
      const hitJosetsuMin30 = josetsuStartHourOffset - 0.5 < hourOffsetMin30 &&
        hourOffsetMin30 < josetsuProgressHourOffset;
      const josetsuM0EndMark = josetsuFinishHourOffset - hourOffsetMin0 < 0.5000000001;
      const josetsuM30EndMark = josetsuFinishHourOffset - hourOffsetMin30 < 0.5000000001;

      const hitHaisetsuMin0 = haisetsuStartHourOffset - 0.5 < hourOffsetMin0 &&
        hourOffsetMin0 < haisetsuProgressHourOffset;
      const hitHaisetsuMin30 = haisetsuStartHourOffset - 0.5 < hourOffsetMin30 &&
        hourOffsetMin30 < haisetsuProgressHourOffset;
      const haisetsuM0EndMark = haisetsuFinishHourOffset - hourOffsetMin0 < 0.5000000001;
      const haisetsuM30EndMark = haisetsuFinishHourOffset - hourOffsetMin30 < 0.5000000001;

      hitMapJosetsu[hourOffsetMin0] = {
        m0: hitJosetsuMin0,
        m30: hitJosetsuMin30,
        m0EndMark: josetsuM0EndMark,
        m30EndMark: josetsuM30EndMark,
      };
      hitMapHaisetsu[hourOffsetMin0] = {
        m0: hitHaisetsuMin0,
        m30: hitHaisetsuMin30,
        m0EndMark: haisetsuM0EndMark,
        m30EndMark: haisetsuM30EndMark,
      };
    });
  });
  return { hitMapJosetsu, hitMapHaisetsu };
}
export function recalcPlanningBlockShinchokuJisseki(planningBlock: PlanningBlock): void {
  const inputMap = planningBlock.inputMap;
  const { hitMapJosetsu, hitMapHaisetsu } = getShinchokuJissekiHitMap(planningBlock);

  const shinchokuJissekiJosetsu: ShinchokuJissekiJosetsu[][] = [];
  const shinchokuJissekiHaisetsu: ShinchokuJissekiJosetsu[][] = [];
  blockIdxs.forEach(blockIdx => {
    const hourBase = blockIdx * hoursPerBlock;
    const blockHourIdxs = [...Array(hoursPerBlock).keys()];
    shinchokuJissekiJosetsu.push(blockHourIdxs.map(hourIdx => {
      return {
        hour: hourBase + hourIdx,
        hourInBlock: hourIdx,
        selected: hitMapJosetsu[hourBase + hourIdx] ||
          { m0: false, m30: false, m0EndMark: false, m30EndMark: false },
      };
    }));
    shinchokuJissekiHaisetsu.push(blockHourIdxs.map(hourIdx => {
      return {
        hour: hourBase + hourIdx,
        hourInBlock: hourIdx,
        selected: hitMapHaisetsu[hourBase + hourIdx] ||
          { m0: false, m30: false, m0EndMark: false, m30EndMark: false },
      };
    }));
  });
  if (inputMap?.progressTable !== undefined) {
    inputMap.progressTable.shinchokuJissekiJosetsu = shinchokuJissekiJosetsu;
    inputMap.progressTable.shinchokuJissekiHaisetsu = shinchokuJissekiHaisetsu;
  }
}

export function calcGroupAllocationMapForProgressTable(planningBlocks: PlanningBlock[]): Allocation[] {
  return blockIdxs.map(blockIdx => {
    const allocationMap = {
      josetsu: 0,
      haisetsu: 0,
    };
    planningBlocks.forEach(planningBlock => {
      const inputMap = planningBlock.inputMap;
      if (!inputMap) {
        return;
      }
      const timeBlock1 = inputMap.time_blocks1[blockIdx];
      const timeBlock2 = inputMap.time_blocks2[blockIdx];
      const haisetsuAllocGroups = mixStrNumToNum(timeBlock1.allocated_groups);
      // ありそうな区切り文字で適当にsplit.
      // 時間がゼロの場合はカウントしない.
      const josetsuHanStr = timeBlock2.josetsu_han;
      const josetsuHours = timeBlock2.josetsu_hours;
      const josetsuAllocGroups = (josetsuHours === 0 || !josetsuHanStr)
        ? 0 : josetsuHanStr.split(/,|、|・|･/).length;
      allocationMap.josetsu += josetsuAllocGroups;
      // 計算表画面で作業時間0でも班数の入力を許可している.
      // だが進捗表画面で作業時間0なブロックの班数を足してってしまうとバーが見えないのに
      // 数だけ積み上がって明らかに見た目がおかしくなるのでここでは作業時間0の場合は計上しない.
      if (timeBlock1.haisetsu_hours > 0) {
        allocationMap.haisetsu += haisetsuAllocGroups;
      }
    });
    return allocationMap;
  });
}

function prepareInputInfo(planningBlocks: PlanningBlock[], hoursPerKmGroupCmMap: Record<string, HoursPerKMGroupCm>, selectedTaskForce: TaskForce, selectedPlanningInfo: JoukyouInfo, prepareType: string) {
  const detailMap: Record<string, Detail> = (selectedPlanningInfo.details as Detail[]).reduce((acc: Record<string, Detail>, e) => {
    acc[e.planning_block_id] = e; return acc;
  }, {});
  const sagyouJoukyouMap = createSagyouJoukyouMap(selectedPlanningInfo.sagyou_joukyou_details);
  // planning_blockについて一つずつ、対応するplanning_detailと紐付ける.
  // あとで編集されてるかどうかを判定するために比較するので、元のやつから
  // deep copyを生み出す.
  let currentRoadNameDispKukan: string | null = null;
  let currentImportance: number | null = null;
  planningBlocks.forEach(planningBlock => {
    if (!detailMap[planningBlock.id]) {
      detailMap[planningBlock.id] = getEmptyPlanningDetail(planningBlock);
    }
    planningBlock.origInputMap = detailMap[planningBlock.id];
    planningBlock.inputMap = JSON.parse(JSON.stringify(detailMap[planningBlock.id])) as Detail;
    planningBlock.inputMap._hours_per_km_group_per_cm =
      hoursPerKmGroupCmMap[planningBlock.area].hours;
    ensurePlanningBlockTimeBlocks(planningBlock.inputMap);
    planningBlock.taskForceStartDate = selectedTaskForce.start_date as Date;
    planningBlock.taskForceEndDate = selectedTaskForce.end_date as Date;
    planningBlock.sagyouJoukyouInfo = calcSagyouJoukyouInfo(planningBlock, sagyouJoukyouMap);

    if (prepareType === PREPARE_TYPE_HAISETSU_KEISAN) {
      recalcPlanningBlockHaisetsuKeisan(planningBlock);
      // TODO 作業状況から時間的な進捗率を計算
    } else if (prepareType === PREPARE_TYPE_PROGRESS_TABLE) {
      recalcPlanningBlockShinchokuKeikaku(planningBlock);
      recalcPlanningBlockShinchokuJisseki(planningBlock);
    }

    const roadNameDispKukan = `${planningBlock.road_name_disp}_${planningBlock.kukan_name}`;
    planningBlock.firstInRoadNameDispKukan = roadNameDispKukan !== currentRoadNameDispKukan;
    planningBlock.firstInImportance = planningBlock.importance !== currentImportance;
    currentRoadNameDispKukan = roadNameDispKukan;
    currentImportance = planningBlock.importance;
  });
}

export function preparePlanningBlocks(selectedTaskForce: TaskForce, selectedPlanningInfo: JoukyouInfo, johaisetsuRole: string, prepareType: string = PREPARE_TYPE_OTHER): PlanningBlock[] {
  let planningBlocks = getJohaisetsuPlanningBlocks();
  planningBlocks = filterJohaisetsuPlanningBlocksByRole(planningBlocks, johaisetsuRole);
  let hoursPerKmGroupCmArr = getJohaisetsuHoursPerKmGroupCm();

  const filtered = filterPlanningMastesrBySelectedPlanningInfo(
    planningBlocks,
    hoursPerKmGroupCmArr,
    selectedPlanningInfo,
  );
  planningBlocks = filtered.planningBlocks;
  hoursPerKmGroupCmArr = filtered.hoursPerKmGroupCmArr;
  const hoursPerKmGroupCmMap = hoursPerKmGroupCmArr.reduce((acc: Record<string, HoursPerKMGroupCm>, e) => {
    acc[e.area] = e; return acc;
  }, {});

  prepareInputInfo(
    planningBlocks,
    hoursPerKmGroupCmMap,
    selectedTaskForce,
    selectedPlanningInfo,
    prepareType,
  );
  return planningBlocks;
}
