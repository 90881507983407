import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { JohaisetsuMtxsCreateJohaisetsuMTXsParams } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';

export default {
  createJohaisetsuMTXs(params: JohaisetsuMtxsCreateJohaisetsuMTXsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_mtxs'), params);
  },
};
