
import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import { dtFormat } from '@/lib/dateHelper';
import { PlanningBlock, TaskForce } from '@/models/johaisetsuCommon';
import { CommonHeader,
  Allocation,
  Area,
  JoukyouInfo } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { Ability } from '@/models/apis/user/userResponse';
import johaisetsuPlanningApi from '@/apis/johaisetsu_planning';
import { getTaskForceInfos,
  isDataReadOnly,
  onChangeUrlPathParam } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { mixStrNumToNum } from '@/lib/stringUtil';
import {
  preparePlanningBlocks,
  blockIdxs,
  hoursPerBlock,
  PREPARE_TYPE_PROGRESS_TABLE,
  recalcPlanningBlockShinchokuKeikaku,
  calcGroupAllocationMapForProgressTable,
} from '@/lib/johaisetsu/johaisetsuPlanningUtil';
import { notifySuccess } from '@/lib/notificationUtil';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole, isJohaisetsuRoleKyoku } from '@/lib/johaisetsuRoleHelper';

interface PlanningBlocksByArea {
   area: Area;
   planningBlocks: PlanningBlock[][];
}

interface DateDisp {
  dt: Date;
  colSpan: number;
}
interface JohaisetsuProgressTable {
  isReady: boolean;
  isReadOnly: boolean;
  canSendReport: boolean;
  isDataEmpty: boolean;
  isRequesting: boolean;

  taskForces: TaskForce[];
  showTaskForceSelect: boolean;
  selectedTaskForce: TaskForce;
  selectedPlanningInfo?: JoukyouInfo;

  planningHeaders: CommonHeader[];
  planningBlocksMapByAreas: { [k: string]: PlanningBlock[][] };
  groupAllocationMapByAreas: Record<string, Allocation[]>;

  dateDisps: DateDisp[];
  hourIdxs: number[];
  hoursPerDay: 24;
  pixelsPerHour: 16;
  blockIdxs: number[];
  hoursPerBlock: number;
  blockHourIdxs: number[];

  selectedAreaId: string;
  areas: Area[];
  areasForSelect: Area[];

  showInputTutorial: boolean;
}
export default defineComponent({
  name: 'johaisetsu-progress-table',
  setup() {
    const state = reactive<JohaisetsuProgressTable>({
      isReady: false,
      isReadOnly: false,
      canSendReport: false,
      isDataEmpty: false,
      isRequesting: false,

      taskForces: [],
      showTaskForceSelect: false,
      selectedTaskForce: { id: -1, name: '' },

      planningHeaders: [],
      planningBlocksMapByAreas: {},
      groupAllocationMapByAreas: {},

      dateDisps: [],
      hourIdxs: [],
      hoursPerDay: 24,
      pixelsPerHour: 16,
      blockIdxs: blockIdxs,
      hoursPerBlock: hoursPerBlock,
      blockHourIdxs: [...Array(hoursPerBlock).keys()],

      selectedAreaId: '',
      areas: [],
      areasForSelect: [],

      showInputTutorial: false,
    });

    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });
    const filteredPlanningBlocksByAreas = computed<PlanningBlocksByArea[]>(() => {
      if (state.selectedAreaId === 'all') {
        return state.areas.map(area => {
          return {
            area: area,
            planningBlocks: state.planningBlocksMapByAreas[area.id],
          };
        });
      }
      return [
        {
          area: state.areas.filter(area => area.id === state.selectedAreaId)[0],
          planningBlocks: state.planningBlocksMapByAreas[state.selectedAreaId],
        },
      ];
    });
    const canSendReport = computed<boolean>(() => {
      return isJohaisetsuRoleKyoku(userState.johaisetsu_role);
    });
    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const getNormalizedKeikakuOffset = (origVal: string, blockIdx: number, maxOffsetInBlock: number) => {
      let normalizedOffset = parseInt(origVal);
      if (isNaN(normalizedOffset)) {
        normalizedOffset = 0;
      } else {
        if (blockIdx % 2 === 0) {
          normalizedOffset = Math.min(maxOffsetInBlock, Math.max(0, normalizedOffset));
        } else {
          normalizedOffset = Math.min(hoursPerBlock + maxOffsetInBlock, Math.max(hoursPerBlock, normalizedOffset));
          normalizedOffset -= hoursPerBlock;
        }
      }
      return normalizedOffset;
    };
    const updateJosetsuKeikakuParams = (planningBlock: PlanningBlock, blockIdx: number, startEnd: string, val: string) => {
      const normalizedOffset = getNormalizedKeikakuOffset(val, blockIdx, hoursPerBlock);
      if (!planningBlock.inputMap?.editStatuses) {
        return;
      }
      const josetsuEditInfo = planningBlock.inputMap.editStatuses[blockIdx].josetsuEditInfo;
      let startHour = mixStrNumToNum(josetsuEditInfo.josetsu_start_offset);
      let endHour = startHour + mixStrNumToNum(josetsuEditInfo.josetsu_hours);
      if (startEnd === 'start') {
        startHour = normalizedOffset;
      } else {
        endHour = normalizedOffset;
      }
      josetsuEditInfo.josetsu_start_offset = startHour;
      josetsuEditInfo.josetsu_hours = endHour - startHour;
      josetsuEditInfo.josetsu_end_offset = endHour;
    };
    const updateHaisetuKeikakuOffset = (planningBlock: PlanningBlock, blockIdx: number, val: string) => {
      const inputMap = planningBlock.inputMap;
      const normalizedOffset = getNormalizedKeikakuOffset(val, blockIdx, hoursPerBlock - 1);
      // 編集されたblockを含めその右側をいっぺんに変更
      // 運用上、路線ごとに開通していくことがほとんどなので、同一行の棒は基本くっついてる
      // ケースがほとんどであるという仮定. 右側のblockを変更すれば左側と離すことは可能だが、
      // 左側のblockを再度変更すると右側はくっついて保存される.
      for (let i = blockIdx, len = inputMap?.editStatuses?.length; i < (len || 0); i++) {
        if (inputMap?.editStatuses !== undefined) {
          const haisetsuEditInfo = inputMap.editStatuses[i].haisetsuEditInfo;
          haisetsuEditInfo.haisetsu_start_offset = normalizedOffset;
          haisetsuEditInfo.haisetsu_end_offset = normalizedOffset;
        }
      }
    };
    const flipJosetsuEditMode = (planningBlock: PlanningBlock, blockIdx: number) => {
      if (state.isReadOnly) { return; }
      const inputMap = planningBlock.inputMap;

      if (!inputMap?.editStatuses) {
        return;
      }
      const editStatus = inputMap.editStatuses[blockIdx];
      if (editStatus.isJosetsuEditMode) {
        // cancel
        editStatus.isJosetsuEditMode = false;
      } else {
        // edit start
        const josetsuEditInfo = editStatus.josetsuEditInfo;
        josetsuEditInfo.josetsu_han = inputMap.time_blocks2[blockIdx].josetsu_han;
        josetsuEditInfo.josetsu_start_offset = inputMap.time_blocks2[blockIdx].josetsu_start_offset;
        josetsuEditInfo.josetsu_hours = inputMap.time_blocks2[blockIdx].josetsu_hours;
        josetsuEditInfo.josetsu_end_offset = mixStrNumToNum(josetsuEditInfo.josetsu_start_offset) + mixStrNumToNum(josetsuEditInfo.josetsu_hours);
        editStatus.isJosetsuEditMode = true;
      }
    };
    const flipHaisetsuEditMode = (planningBlock: PlanningBlock, blockIdx: number) => {
      if (state.isReadOnly) { return; }
      if (!planningBlock.inputMap?.editStatuses || planningBlock.inputMap.time_blocks1[blockIdx].haisetsu_hours === 0) {
        return;
      }

      const inputMap = planningBlock.inputMap;
      if (!inputMap?.editStatuses) {
        return;
      }
      const editStatus = inputMap.editStatuses[blockIdx];
      if (editStatus.isHaisetsuEditMode) {
        // 行全体を元に戻す
        for (let i = 0, len = inputMap.time_blocks2.length; i < len; i++) {
          inputMap.editStatuses[i].isHaisetsuEditMode = false;
        }
      } else {
        // 押下されたblockとそれより右のblockを準備
        // (押下されたblockより左側は通常モードにする)
        for (let i = 0, len = inputMap.time_blocks2.length; i < len; i++) {
          if (i < blockIdx) {
            inputMap.editStatuses[i].isHaisetsuEditMode = false;
          } else {
            const editStatus = inputMap.editStatuses[i];
            const haisetsuEditInfo = editStatus.haisetsuEditInfo;
            haisetsuEditInfo.haisetsu_start_offset = inputMap.time_blocks2[i].haisetsu_start_offset;
            // haisetsu_hoursはこの画面の見た目上は小数点1桁でalign
            const tmpHaisetsuHours = inputMap.time_blocks1[blockIdx].haisetsu_hours;
            const alignedHaisetsuHours = parseFloat(Number(tmpHaisetsuHours).toFixed(1));
            haisetsuEditInfo.haisetsu_end_offset = mixStrNumToNum(haisetsuEditInfo.haisetsu_start_offset) + alignedHaisetsuHours;
            // edit start
            editStatus.isHaisetsuEditMode = true;
            // 操作用部品はクリックされた枠でしか見せない
            editStatus.showHaisetsuEditControls = i === blockIdx;
          }
        }
      }
    };
    const saveJosetsu = async(planningBlock: PlanningBlock, blockIdx: number) => {
      const inputMap = planningBlock.inputMap;
      if (!inputMap?.editStatuses) {
        return;
      }
      const editStatus = inputMap.editStatuses[blockIdx];
      const josetsuEditInfo = editStatus.josetsuEditInfo;
      const timeBlock2 = inputMap.time_blocks2[blockIdx];
      timeBlock2.josetsu_han = josetsuEditInfo.josetsu_han;
      timeBlock2.josetsu_start_offset = josetsuEditInfo.josetsu_start_offset;
      timeBlock2.josetsu_hours = josetsuEditInfo.josetsu_hours;

      recalcPlanningBlockShinchokuKeikaku(planningBlock);
      // フッターエリアの合計班数表示も更新
      state.groupAllocationMapByAreas = calcGroupAllocationMapByAreas();
      editStatus.isJosetsuEditMode = false;

      // 1要素だけ更新する
      const detail = {
        planning_block_id: planningBlock.id,
        time_blocks2: [timeBlock2],
      };
      await johaisetsuPlanningApi.updateProgressTable(
        state.selectedTaskForce.id,
        { details: [detail] },
      );
      notifySuccess('', 'データを保存しました', { duration: 700 });
    };
    const saveHaisetsu = async(planningBlock: PlanningBlock, blockIdx: number) => {
      const inputMap = planningBlock.inputMap;
      // 押下されたblockとそれより右のblockをいっぺんに保存
      const targetTimeBlocks2 = [];
      if (!inputMap?.editStatuses) {
        return;
      }
      for (let i = blockIdx, len = inputMap.time_blocks2.length; i < len; i++) {
        const editStatus = inputMap.editStatuses[i];
        const timeBlock2 = inputMap.time_blocks2[i];
        timeBlock2.haisetsu_start_offset = editStatus.haisetsuEditInfo.haisetsu_start_offset;
        targetTimeBlocks2.push(timeBlock2);
        // 直接保存ボタン押してないブロックも強制的に元のモードに戻す.
        editStatus.isHaisetsuEditMode = false;
      }

      recalcPlanningBlockShinchokuKeikaku(planningBlock);

      // 1要素だけ更新する
      const detail = {
        planning_block_id: planningBlock.id,
        time_blocks2: targetTimeBlocks2,
      };
      await johaisetsuPlanningApi.updateProgressTable(
        state.selectedTaskForce.id,
        { details: [detail] },
      );
      notifySuccess('', 'データを保存しました', { duration: 700 });
    };
    const proceedFlowStep = async() => {
      if (state.isRequesting) { return; }
      state.isRequesting = true;
      await johaisetsuPlanningApi.proceedFlowStep(
        state.selectedTaskForce.id,
      );
      notifySuccess('', '本社に送信しました');
      state.isRequesting = false;
    };
    const prepareDispInfos = () => {
      const baseDate = state.selectedTaskForce.start_date || new Date();
      const numHours = hoursPerBlock * blockIdxs.length;
      const numDays = Math.ceil(numHours / state.hoursPerDay);
      const dayOffsetIdxs = [...Array(numDays).keys()];
      state.dateDisps = dayOffsetIdxs.map(idx => {
        const hourIdxStart = idx * state.hoursPerDay;
        return {
          dt: new Date(baseDate.valueOf() + idx * 86400 * 1000),
          colSpan: Math.min(hourIdxStart + state.hoursPerDay, numHours) - hourIdxStart,
        };
      });
      state.hourIdxs = [...Array(numHours).keys()];
    };
    const prepareAreas = () => {
      const origAreas = [
        { id: 't-west', name: '西局' },
        { id: 't-east', name: '東局' },
        { id: 'kanagawa', name: '神局' },
      ];
      const areaAll = { id: 'all', name: '全て' };

      let areas = [];
      let areasForSelect = [];
      if (johaisetsuRole.value === 'honsha') {
        areas = origAreas.slice();
        areasForSelect = [areaAll, ...areas];
      } else {
        areas = origAreas.filter(e => johaisetsuRole.value === e.id);
        areasForSelect = areas.slice();
      }
      return { areas, areasForSelect };
    };
    const restructurePlanningBlocks = (planningBlocks: PlanningBlock[]):{ [k: string]: PlanningBlock[][] } => {
      // 路線#方向で1行分になっているのを、
      // 地域 => 路線#方向(両方) => 路線#方向 にまとめ直す
      return Object.fromEntries(state.areas.map(area => {
        const k = area.id;
        const restructuredPlanningBlocks: PlanningBlock[][] = [];
        let tmp: PlanningBlock | null = null;
        planningBlocks
          .filter(e => e.area === k)
          .forEach(e => {
            // 1路線につき方向は必ず2つの想定.
            if (e.firstInRoadNameDispKukan) {
              tmp = e;
            } else {
              if (tmp) {
                restructuredPlanningBlocks.push([tmp, e]);
              }
            }
          });
        return [k, restructuredPlanningBlocks];
      }));
    };
    const calcGroupAllocationMapByAreas = () => {
      const ret: Record<string, Allocation[]> = {};
      filteredPlanningBlocksByAreas.value.forEach(({ area, planningBlocks }) => {
        ret[area.id] = calcGroupAllocationMapForProgressTable(planningBlocks.flat());
      });
      return ret;
    };
    const reloadData = async() => {
      state.isReady = false;
      await getSelectedPlanningInfo();
      state.isReady = true;
    };
    const getSelectedPlanningInfo = async() => {
      const { data: selectedPlanningInfo } = await johaisetsuPlanningApi.show({
        taskForceId: state.selectedTaskForce.id,
        headerId: headerId.value,
      });

      state.selectedPlanningInfo = selectedPlanningInfo;
      state.isDataEmpty = !selectedPlanningInfo.header;

      const planningBlocks = preparePlanningBlocks(
        state.selectedTaskForce,
        selectedPlanningInfo,
        johaisetsuRole.value,
        PREPARE_TYPE_PROGRESS_TABLE,
      );
      state.planningBlocksMapByAreas = restructurePlanningBlocks(planningBlocks);
      state.groupAllocationMapByAreas = calcGroupAllocationMapByAreas();
    };

    onMounted(async() => {
      state.isRequesting = true;
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;
      prepareDispInfos();

      // 待たなくていい
      johaisetsuPlanningApi.index({
        taskForceId: state.selectedTaskForce.id,
      }).then(({ data: planningHeaders }) => {
        state.planningHeaders = planningHeaders;
      });

      const { areas, areasForSelect } = prepareAreas();
      state.areas = areas;
      state.areasForSelect = areasForSelect;
      state.selectedAreaId = state.areasForSelect.length > 0 ? state.areasForSelect[0].id : '';

      await getSelectedPlanningInfo();
      state.isRequesting = false;

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedPlanningInfo?.header,
        johaisetsuRole.value,
      );

      state.isReady = true;
    });
    return {
      ...toRefs(state),
      // computed
      johaisetsuRole,
      abilityMap,
      taskForceId,
      headerId,
      filteredPlanningBlocksByAreas,
      canSendReport,
      // methods
      onSelectedTaskForceChange,
      getNormalizedKeikakuOffset,
      updateJosetsuKeikakuParams,
      updateHaisetuKeikakuOffset,
      flipJosetsuEditMode,
      flipHaisetsuEditMode,
      saveJosetsu,
      saveHaisetsu,
      proceedFlowStep,
      reloadData,
      dtFormat,
    };
  },
});
