


import {
  defineComponent,
  getCurrentInstance,
  computed,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watch,
  ref,
  PropType,
} from '@vue/composition-api';
import Vue from 'vue';
import OlMapWrapper, { MapFeatureInfo, PopupLayer } from '@/lib/OlMapWrapper';
import {
  getCarPopupContentInnerHtml,
  getSettouPatrolReportPopupContentInnerHtml,
  getEnsuiPlantPopupContentInnerHtml,
  getCleaningCarPopupContentInnerHtml,
  getCleaningReportPhotoPopupContentInnerHtml,
  getCleaningMtxPopupContentInnerHtml,
} from '@/lib/olPopupContentHelper';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { cleaningPhotoTypeOptions } from '@/components/CleaningMap/consts/cleaning_photo';
import ExtremeMapCarLayerManager from '@/lib/ExtremeMapCarLayerManager';
import ExtremeMapMovieLayerManager, { QueryArea } from '@/lib/ExtremeMapMovieLayerManager';
import ExtremeMapCleaningCarLayerManager from '@/lib/ExtremeMapCleaningCarLayerManager';
import ExtremeMapCleaningReportLayerManager from '@/lib/ExtremeMapCleaningReportLayerManager';
import ExtremeMapKilopostLayerManager from '@/lib/ExtremeMapKilopostLayerManager';
import ExtremeMapGeoItemLayerManager from '@/lib/ExtremeMapGeoItemLayerManager';
import ExtremeMapStallRiskPointsLayerManager from '@/lib/ExtremeMapStallRiskPointsLayerManager';
import ExtremeMapSettouPatrolReportsLayerManager from '@/lib/ExtremeMapSettouPatrolReportsLayerManager';
import ExtremeMapEnsuiPlantsLayerManager from '@/lib/ExtremeMapEnsuiPlantsLayerManager';
import ExtremeMapDebugMTXMovieLayerManager, { LayerParams } from '@/lib/ExtremeMapDebugMTXMovieLayerManager';
import ExtremeMapDebugCyzenLayerManager, { HistoryGroup } from '@/lib/ExtremeMapDebugCyzenLayerManager';
import ExtremeMapCommentLayerManager from '@/lib/ExtremeMapCommentLayerManager';
import ExtremeMapPinLayerManager from '@/lib/ExtremeMapPinLayerManager';
import movieGeoIndexApi from '@/apis/movie_geo_index';
import { useStore } from '@/hooks/useStore';

import { Settings as UserSettings } from 'src/models/apis/user/userResponse';
import {
  SettouPatrolReportExt,
  CarExt,
  EnsuiPlantExt,
  CleaningCarExt,
  Location,
} from '@/models/index';
import { Movie, MainLine } from '@/models/apis/movie/movieResponse';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { transform } from 'ol/proj';
import { JointFeatureCollection, PoleFeatureCollection } from '@/models/apis/getGeoInfoResponse';
import { RoadGeoItemData } from '@/models/apis/geoItem/geoItemResponse';
import {
  CleaningReportExt,
  CleaningReportPhotoExt,
} from '@/models/apis/cleaning/cleaningReportResponse';
import { CleaningMTX, CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { Coordinate } from 'ol/coordinate';
import {
  redrawCarLayerOpt,
  redrawCleaningCarLayerOpt,
  redrawCleaningReportLayerOpt,
  ExtremeMapState,
  CleaningExtremeMapState,
  MapEssentials,
  RouteCandidacies,
  LayerZIndexMap,
} from '@/models/extremeMap';
import { GIComment, GIPoint, GeoItemMeta } from '@/models/geoItem';

export default defineComponent({
  name: 'extreme-map',
  props: {
    cars: {
      type: Array as PropType<CarExt[]>,
      default: () => {
        return [];
      },
    },
    movies: {
      type: Array as PropType<Movie[]>,
      default: () => {
        return [];
      },
    },
    cleaningCars: {
      type: Array as PropType<CleaningCarExt[]>,
      default: () => {
        return [];
      },
    },
    mapEssentials: {
      type: Object as PropType<MapEssentials>,
      default: () => {
        return null;
      },
    },
    useMovieLayer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const olMap = new OlMapWrapper();

    const state = reactive<ExtremeMapState>({
      isDisplayInitialized: false,

      userSettings: {} as UserSettings,
      kpMap: new Map(),

      carLayerMgr: new ExtremeMapCarLayerManager(),
      movieLayerMgr: new ExtremeMapMovieLayerManager(),
      kpLayerMgr: new ExtremeMapKilopostLayerManager({
        isDebugShowGeoConnections: Vue.prototype.$isDebugShowGeoConnections,
        isDebugShowKpAllLayer: Vue.prototype.$isDebugShowKpAllLayer,
      }),
      settouPatrolReportsLayerMgr: new ExtremeMapSettouPatrolReportsLayerManager(),
      ensuiPlantsLayerMgr: new ExtremeMapEnsuiPlantsLayerManager(),
      commentLayerMgr: new ExtremeMapCommentLayerManager({
        dataName: 'comment', giManager: null,
      }),
      pinLayerMgr: new ExtremeMapPinLayerManager(),
      geoItemLayerNameMap: {},
      layerZIndexMap: {
        road: 10,
        kps: 15,
        geoItemsBase: 50,
        pin: 80,
        movies: 81,
        cars: 100,
        stallRiskPoints: 120,
        settouPatrolReports: 130,
        ensuiPlants: 140,
        currentLayerZIndex: 1000,
      },
      onResizeFunc: () => {},
      popupLayerObj: null,
      carPopupTargetCar: null,
      settouPatrolReportPopupTargetReport: null,
      mapHeight: 0,
    });
    const cleaningState = reactive<CleaningExtremeMapState>({
      cleaningCarLayerMgr: new ExtremeMapCleaningCarLayerManager({
        hideCarIcons: false,
        hideCleaningPhotoIcons: false,
        hideDefectPhotoIcons: false,
      }),
      cleaningReportLayerMgr: new ExtremeMapCleaningReportLayerManager({
        hideCleaningPhotoIcons: false,
        hideDefectPhotoIcons: false,
      }),
      cleaningCarPopupTargetCar: null,
      cleaningReportPhotoPopupTargetPhoto: null,
      cleaningMtxPopupTargetMtx: null,
      samePosPhotos: [],
    });
    const currentInstance = getCurrentInstance();
    const uid = currentInstance?.uid;
    const layerMgrMap: Record<string, ExtremeMapCommentLayerManager> = {};
    const olMapId = computed(() => {
      return `ol-map-${uid}`;
    });

    watch(() => props.movies, () => {
      redrawMovieLayer();
    });
    watch(() => props.mapEssentials, () => {
      if (!props.mapEssentials) { return; }
      initDisplay(props.mapEssentials);
    });

    const popupContainer = ref<HTMLElement>();
    const popupCloser = ref<HTMLElement>();
    const popupContent = ref<HTMLElement>();
    onMounted(() => {
      olMap.initMapManager({
        element: `ol-map-${uid}`,
        center: olMap.convCoord({lat: 35.679100, lon: 139.756932}),
        maxZoom: 18,
        minZoom: 4,
        zoom: 10,
        enablePan: true,
        enableZoomButton: true,
        enableMouseWheelZoom: true,
        enableDoubleClickZoom: true,
        enableScaleLine: true,
        enableAttribution: true,
      });

      olMap.addLayer(olMap.getKokudoChiriinLayer());

      if (popupContainer.value && popupCloser.value && popupContent.value) {
        state.popupLayerObj = olMap.getPopupLayer({
          popupContainer: popupContainer.value,
          popupCloser: popupCloser.value,
          popupContent: popupContent.value,
        });
        if (state.popupLayerObj) {
          olMap.addPopupLayer(state.popupLayerObj as PopupLayer);
        }
      }

      setMapClickEvent();
      setMapMoveEndEvent();
      olMap.enableLayerSwitcher();
      initResizeFunc();
      initializePinAreaLayer();

      // 一旦空で初期化しておく
      if (props.useMovieLayer) {
        redrawMovieLayer();
      }
    });
    onUnmounted(() => {
      window.removeEventListener('resize', state.onResizeFunc);
    });

    const initDisplay = ({ userSettings, kpMap, initialExtent }: MapEssentials) => {
      if (state.isDisplayInitialized) { return; }
      state.isDisplayInitialized = true;
      state.userSettings = userSettings;
      state.kpMap = kpMap;

      const initialMapPos = userSettings.initial_map_pos;
      olMap.setCenter(olMap.convCoord(initialMapPos));

      const initialFitMaxZoom = 20;
      const initialFitDuration = 500;
      if (initialExtent) {
        olMap.fitToExtent(initialExtent, {
          maxZoom: initialFitMaxZoom,
          duration: initialFitDuration,
        });
      } else if (state.userSettings.initial_extent) {
        const coords = state.userSettings.initial_extent.map(e => olMap.convCoord(e));
        olMap.fitToCoordsBoundingExtent(coords, {
          maxZoom: initialFitMaxZoom,
          duration: initialFitDuration,
        });
      }

      userSettings.gis_layers.forEach((e, i) => {
        olMap.addLayer(olMap.getGISLayer(e.layer_name, e.disp_name, i));
      });

      initKpLayer();
    };
    const initKpLayer = () => {
      const { roadLayer, kpLayerFiltered, kpLayerAll } =
        state.kpLayerMgr.prepareLayers(state.userSettings, state.kpMap);
      if (!kpLayerFiltered || !roadLayer) { return; }
      kpLayerFiltered.setZIndex(state.layerZIndexMap.kps);
      roadLayer.setZIndex(state.layerZIndexMap.road);
      if (kpLayerAll) {
        kpLayerAll.setZIndex(state.layerZIndexMap.kps);
        olMap.addLayer(kpLayerAll);
      }
      olMap.addLayer(kpLayerFiltered);
      olMap.addLayer(roadLayer);
    };
    const initResizeFunc = () => {
      // resize map on window resize
      state.onResizeFunc = () => {
        const elem = document.getElementById(olMapId.value);
        if (elem === null) { return; }
        elem.style.height = `${state.mapHeight}px`;
        olMap.updateMapSize();
      };
      state.onResizeFunc();
      window.addEventListener('resize', state.onResizeFunc);
    };
    const redrawCarLayer = (opts?: redrawCarLayerOpt) => {
      if (!currentInstance || !currentInstance.proxy) {
        return;
      }
      const { layer, layerInfo } = state.carLayerMgr.prepareLayer(currentInstance.proxy as any, props.cars, opts);
      if (layer) {
        layer.setZIndex(state.layerZIndexMap.cars);
        olMap.addLayer(layer, {
          interaction: layerInfo.onLayerClick ? {
            click: layerInfo.onLayerClick,
          } : {},
        });
      }
      // 初回描画時のみ、呼び出し側で地図範囲にfitするよう指定する想定.
      if (opts?.fitToExtent) {
        if (layerInfo.extent) {
          olMap.fitToExtent(layerInfo.extent, {
            maxZoom: 20,
            duration: 500,
          });
        }
      }
    };
    const redrawMovieLayer = () => {
      const { layer } = state.movieLayerMgr.prepareLayer(props.movies);
      if (layer) {
        layer.setZIndex(state.layerZIndexMap.movies);
        olMap.addLayer(layer);
      }
    };
    const redrawCleaningCarLayer = (opts: redrawCleaningCarLayerOpt) => {
      if (!currentInstance || !currentInstance.proxy) {
        return;
      }
      const { layer, layerInfo } = cleaningState.cleaningCarLayerMgr.prepareLayer(
        currentInstance.proxy as any,
        props.cleaningCars,
        opts,
        olMap.getZoom() ?? 10,
      );
      if (layer) {
        layer.setZIndex(100);
        olMap.addLayer(layer, {
          interaction: layerInfo.onLayerClick ? {
            click: layerInfo.onLayerClick,
          } : {},
        });
      }
      // 初回描画時のみ、呼び出し側で地図範囲にfitするよう指定する想定.
      if (opts?.fitToExtent && layerInfo.extent) {
        olMap.fitToExtent(layerInfo.extent, {
          maxZoom: 20,
          duration: 500,
        });
      } else if (opts?.setSelectedCarToCenter) {
        const selectedCar = props.cleaningCars.find(e => e.isSelected);
        if (selectedCar && selectedCar.pos) {
          olMap.setCenter(selectedCar.pos);
        }
      }
    };
    const clickedMapBackground = (evt: MapBrowserEvent) => {
      hideMovieQueryArea();
      // ピンをクリックしなかった際にこちらに流れる

      const [lon, lat] = transform(
        evt.coordinate, 'EPSG:3857', 'EPSG:4326',
      );
      if (props.useMovieLayer) {
        const lonLimited = floor(lon, 2).toFixed(2);
        const latLimited = floor(lat, 2).toFixed(2);
        const queryArea = {
          lonMin: parseFloat(lonLimited),
          latMin: parseFloat(latLimited),
          lonMax: parseFloat(lonLimited) + 0.01,
          latMax: parseFloat(latLimited) + 0.01,
        };

        console.log(lonLimited, latLimited);
        console.log(`{ lat: ${lat.toFixed(6)}, lon: ${lon.toFixed(6)} }`);

        movieGeoIndexApi.getMovieGeoIndices({
          lon: lonLimited,
          lat: latLimited,
        }).then(({ data }) => {
          tryShowStoreMoviePopup(data, queryArea);
        });
      }

      // 各レイヤーや外部に、別のとこが選択されたことを通知する
      notifyDeselectAll();

      emit('click-map', { lon, lat });
    };
    const clickedMapFeature = (evt: MapBrowserEvent, info: MapFeatureInfo) => {
      if (info.name === 'pole') {
        if (state.popupLayerObj) {
          olMap.showPolePopupFromFeatureInfo(
            state.popupLayerObj as PopupLayer, info.data as PoleFeatureCollection, evt.coordinate);
        }
      } else if (info.name === 'joint') {
        if (state.popupLayerObj) {
          olMap.showJointPopupFromFeatureInfo(
            state.popupLayerObj as PopupLayer, info.data as JointFeatureCollection, evt.coordinate);
        }
      }
    };
    const setMapClickEvent = () => {
      olMap.onMapSingleClick((evt: MapBrowserEvent) => {
        if (evt.originalEvent.defaultPrevented) { return; }

        olMap.getMapFeatureInfo(evt).then(info => {
          if (!info) {
            clickedMapBackground(evt);
          } else {
            clickedMapFeature(evt, info);
          }
        });
      });
    };
    const setMapMoveEndEvent = () => {
      let currentZoom = olMap.getZoom();
      olMap.onMapMoveEnd(() => {
        const newZoom = olMap.getZoom();
        if (newZoom !== currentZoom) {
          const evtObj = { zoom: newZoom, oldZoom: currentZoom };
          state.kpLayerMgr.refreshLayersOnZoomChange(evtObj);
          if (state.movieLayerMgr.getLayer().layer) {
            state.movieLayerMgr.refreshLayerOnZoomChange(evtObj);
          }
          if (cleaningState.cleaningCarLayerMgr.getLayer().layer) {
            cleaningState.cleaningCarLayerMgr.refreshLayerOnZoomChange();
          }
          if (cleaningState.cleaningReportLayerMgr.getLayer().layer) {
            cleaningState.cleaningReportLayerMgr.refreshLayerOnZoomChange();
          }
          emit('zoom-changed', evtObj);
          currentZoom = newZoom;
        }
        const moveEndEvtObj = {
          zoom: newZoom,
          extent: olMap.getExtent(),
        };
        state.kpLayerMgr.refreshLayersOnMoveEnd(moveEndEvtObj);
      });
    };
    const tryShowStoreMoviePopup = (data: Map<string, Map<string, Map<string, MainLine[][]>>>, queryArea: QueryArea) => {
      // デバッグフラグがONの場合、路線名#方向が入っていないものも表示する
      const routeCandidacies: RouteCandidacies[] = [];
      for (let [roadName, v1] of Object.entries(data)) {
        if (roadName === 'none' && !Vue.prototype.$isDebugShowNoKpMovies) { continue; }
        roadName = roadName === 'none' ? 'なし' : roadName;
        for (let [direction, v2] of Object.entries(v1)) {
          if (direction === 'none' && !Vue.prototype.$isDebugShowNoKpMovies) { continue; }
          direction = direction === 'none' ? 'なし' : direction;
          // 路線名、方向でまとめて、その中の本線それ以外の切り替えは
          // 動画プレイヤー側に任せる
          const movieGeoIndices: MainLine[] = [];
          for (const ent2 of Object.entries(v2 as MainLine[][])) {
            ent2[1].forEach(movieGeoIndex => {
              movieGeoIndex.placeNameDisp =
                movieGeoIndex.place_name === 'main_line'
                  ? '本線' : movieGeoIndex.place_name;
            });
            movieGeoIndices.push(...ent2[1]);
          }
          // このソートで先頭に来たやつが最初に再生される.
          // プレイヤーの候補リストは最初この順番になる.
          movieGeoIndices.sort((a, b) => {
            const pn1 = a.place_name;
            const pn2 = b.place_name;
            // 場所については、本線が先に来てればいいや
            const pnSortKey1 = pn1 === 'main_line' ? 0 : 1;
            const pnSortKey2 = pn2 === 'main_line' ? 0 : 1;
            const ts1 = new Date(a.ts);
            const ts2 = new Date(b.ts);
            const kp1 = parseFloat(a.kp.toString());
            const kp2 = parseFloat(b.kp.toString());
            // データが蓄積されるにしたがって同じKPで何個も動画が
            // できるだろうし、まずは時間順にしてあげた方がよいだろう.
            // (KP数個分をカバーするくらいの範囲で検索してるので、
            // KP自体が数個でてくる)
            if (pnSortKey1 !== pnSortKey2) {
              return pnSortKey1 < pnSortKey2 ? -1 : 1;
            } else if (ts1 !== ts2) {
              // 時間は降順
              return ts2 < ts1 ? -1 : 1;
            }
            // KPは昇順にしておこう
            return kp1 < kp2 ? -1 : (kp1 > kp2 ? 1 : 0);
          });
          routeCandidacies.push({
            roadName,
            direction,
            movieGeoIndices,
          });
        }
      }

      if (routeCandidacies.length > 0) {
        const lonMid = (queryArea.lonMin + queryArea.lonMax) / 2;
        const latMax = queryArea.latMax;
        const popupCoord = olMap.convCoord({ lon: lonMid, lat: latMax });
        showStoredMoviePopup(popupCoord, routeCandidacies);
        showMovieQueryArea(queryArea);
      } else {
        hideMovieQueryArea();
        if (state.popupLayerObj) {
          state.popupLayerObj.popupOverlay.setPosition(undefined);
        }
      }
      emit('notify-deselect-all-cars');
      emit('notify-deselect-all-other-data-layer-items');
    };
    const floor = (value: number, numberOfDigit: number) => {
      return Math.floor(value * Math.pow(10, numberOfDigit)) / Math.pow(10, numberOfDigit);
    };
    const showCarPopup = (car: CarExt) => {
      const coordinate = {lon: car.lon, lat: car.lat};
      if (!state.popupLayerObj) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupOverlay.setOffset([0, -40]);
      popupContent.innerHTML = getCarPopupContentInnerHtml(car);

      state.carPopupTargetCar = car;
      $('#play-movie-button').on('click', onSelectCarMovie);
      $('#start-live-stream-button').on('click', onStartLiveStream);
      $('#stop-live-stream-button').on('click', onStopLiveStream);
      $('#edit-johaisetsu-car-button').on('click', onEditJohaisetsuCar);
      popupOverlay.setPosition(olMap.convCoord(coordinate));
    };
    const showSettouPatrolReportPopup = (report: SettouPatrolReportExt) => {
      if (!state.popupLayerObj || !report.lat || !report.lon) {
        return;
      }
      const coordinate = { lon: report.lon, lat: report.lat };
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupOverlay.setOffset([0, -40]);
      popupContent.innerHTML = getSettouPatrolReportPopupContentInnerHtml(report);

      state.settouPatrolReportPopupTargetReport = report;
      $('#show-settou-patrol-report-detail-page-button').on('click', onShowSettouPatrolReportDetailPage);
      popupOverlay.setPosition(olMap.convCoord(coordinate));
    };
    const showEnsuiPlantPopup = (plant: EnsuiPlantExt) => {
      const coordinate = { lon: plant.lon, lat: plant.lat };
      if (!state.popupLayerObj) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupOverlay.setOffset([0, -40]);
      popupContent.innerHTML = getEnsuiPlantPopupContentInnerHtml(plant);
      popupOverlay.setPosition(olMap.convCoord(coordinate));
    };
    const showCleaningCarPopup = (car: CleaningCarExt) => {
      const coordinate = {
        lon: parseFloat(car.lon),
        lat: parseFloat(car.lat),
      };
      if (!state.popupLayerObj) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupOverlay.setOffset([0, -40]);
      popupContent.innerHTML = getCleaningCarPopupContentInnerHtml(car);

      cleaningState.cleaningCarPopupTargetCar = car;
      popupOverlay.setPosition(olMap.convCoord(coordinate));
    };
    const showCleaningReportPhotoPopup = (photo: CleaningReportPhotoExt) => {
      const coordinate = {
        lon: parseFloat((photo.lon || 0).toString()),
        lat: parseFloat((photo.lat || 0).toString()),
      };
      if (!state.popupLayerObj) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupOverlay.setOffset([0, -30]);
      popupContent.innerHTML = getCleaningReportPhotoPopupContentInnerHtml(photo);
      cleaningState.cleaningReportPhotoPopupTargetPhoto = photo;

      cleaningState.samePosPhotos = convPhotos(photo.samePosPhotos);
      const cleaningMapPhotoElement = document.querySelector('#cleaning-map-photo' + photo.id);
      if (cleaningMapPhotoElement) {
        cleaningMapPhotoElement.addEventListener('click', onShowSamePosPhotos);
      }

      popupOverlay.setPosition(olMap.convCoord(coordinate));
    };
    const convPhotos = (samePosPhotos: CleaningReportPhotoExt[]): CleaningReportPhotoExt[] => {
      const samePosPhotosExt: CleaningReportPhotoExt[] = samePosPhotos.map(e => {
        return {
          ...e,
          isSelected: false,
          cleaningCompanyName: '',
          cleaningHanName: '',
          reportContents: '',
          reportRoadNames: '',
          samePosPhotos: [],
        };
      });
      samePosPhotosExt.forEach(async(photo) => {
        if (photo.savedImage) return;
        photo.savedImage = await fetchImageAsObjectURL(photo.image_path);
      });
      return samePosPhotosExt;
    };
    const showCleaningMtxPopup = (mtx: CleaningMtxExt) => {
      const coordinate = {
        lon: parseFloat((mtx.lon || 0).toString()),
        lat: parseFloat((mtx.lat || 0).toString()),
      };
      if (!state.popupLayerObj) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupOverlay.setOffset([0, -10]);
      popupContent.innerHTML = getCleaningMtxPopupContentInnerHtml(mtx);

      cleaningState.cleaningMtxPopupTargetMtx = mtx;
      popupOverlay.setPosition(olMap.convCoord(coordinate));
    };
    const onSelectCarMovie = () => {
      emit('select-car-movie', state.carPopupTargetCar);
    };
    const onStartLiveStream = () => {
      emit('start-live-stream', state.carPopupTargetCar);
    };
    const onStopLiveStream = () => {
      emit('stop-live-stream', state.carPopupTargetCar);
    };
    const onEditJohaisetsuCar = () => {
      emit('edit-johaisetsu-car', state.carPopupTargetCar);
    };
    const onShowSettouPatrolReportDetailPage = () => {
      emit('show-settou-patrol-report-detail-page', state.settouPatrolReportPopupTargetReport);
    };
    const showStoredMoviePopup = (coord: Coordinate, routeCandidacies: RouteCandidacies[]) => {
      const evtObjs: RouteCandidacies[] = [];
      let html = `
        <div class="stored-movie-candidacies-popup">
          <div class="header-caption">候補</div>
          <table class="table">`;

      routeCandidacies.forEach((row, i) => {
        const btnId = `route-btn-${i}-${new Date().valueOf()}`;
        html += `
          <tr>
            <td class="vm cell road-name">
              ${row.roadName}
            </td>
            <td class="vm cell direction">
              ${row.direction}
            </td>
            <td class="vm cell show-button">
              <button id="${btnId}"
                type="submit" class="btn btn-primary btn-sm">再生</button>
            </td>
          </tr>`;

        evtObjs.push({ btnId, ...row });
      });
      html += '</table>';
      html += '</div>';

      // ポップアップ表示store
      if (state.popupLayerObj) {
        const { popupOverlay, popupContent } = state.popupLayerObj;
        popupOverlay.setOffset([0, 5]);
        popupContent.innerHTML = html;
        popupOverlay.setPosition(coord);
      }

      // イベント追加
      for (const evtObj of evtObjs) {
        $(`#${evtObj.btnId}`).on('click', () => {
          emit('select-stored-movie', {
            candidacies: evtObj.movieGeoIndices,
          });
        });
      }
    };
    const showMovieQueryArea = (queryArea: QueryArea) => {
      state.movieLayerMgr.showMovieQueryArea(queryArea);
    };
    const hideMovieQueryArea = () => {
      state.movieLayerMgr.hideMovieQueryArea();
    };
    const hidePopup = () => {
      if (!state.popupLayerObj) { return; }
      const { popupOverlay, popupContent } = state.popupLayerObj;
      popupContent.innerHTML = '';
      popupOverlay.setPosition(undefined);
      state.carPopupTargetCar = null;
      state.settouPatrolReportPopupTargetReport = null;
      cleaningState.cleaningCarPopupTargetCar = null;
      cleaningState.cleaningReportPhotoPopupTargetPhoto = null;
    };
    const onClickCar = (car: CarExt) => {
      emit('click-car', car);
    };
    const onClickCleaningCar = (car: CleaningCarExt) => {
      emit('click-cleaning-car', car);
    };
    const onClickCleaningReportPhoto = (photo: CleaningReportPhotoExt) => {
      emit('click-cleaning-report-photo', photo);
    };
    const onShowSamePosPhotos = () => {
      const photoTypeDisp = cleaningPhotoTypeOptions().find(e =>
        e.value === cleaningState.cleaningReportPhotoPopupTargetPhoto?.photo_type,
      )?.text ?? '';
      emit('show-same-pos-photos', photoTypeDisp, cleaningState.samePosPhotos);
    };
    const onClickCleaningMtx = (mtx: CleaningMTX) => {
      emit('click-cleaning-mtx', mtx);
    };
    const onClickSettouPatrolReport = (report: SettouPatrolReportExt) => {
      if (report.isSelected) {
        showSettouPatrolReportPopup(report);
      } else {
        hidePopup();
      }
      emit('click-settou-patrol-report', report);
    };
    const onClickEnsuiPlant = (plant: EnsuiPlantExt) => {
      if (plant.isSelected) {
        showEnsuiPlantPopup(plant);
      } else {
        hidePopup();
      }
      emit('click-ensui-plant', plant);
    };
    const deselectAllSettouPatrolReports = () => {
      const mgr = state.settouPatrolReportsLayerMgr;
      mgr.deselectAll();
    };
    const deselectAllEnsuiPlants = () => {
      const mgr = state.ensuiPlantsLayerMgr;
      mgr.deselectAll();
    };
    const getSettouPatrolReportMap = () => {
      const mgr = state.settouPatrolReportsLayerMgr;
      return mgr.getResourceMap();
    };
    const getEnsuiPlantMap = () => {
      const mgr = state.ensuiPlantsLayerMgr;
      return mgr.getResourceMap();
    };
    const store = useStore();
    const addGeoItemsLayer = (dataType: string, data: RoadGeoItemData, zIndexOffset = 0) => {
      const mgr = new ExtremeMapGeoItemLayerManager(dataType, store);
      const { layer } = mgr.prepareLayer(data);
      if (!layer) { return; }
      const zIndex = state.layerZIndexMap.geoItemsBase + zIndexOffset;
      layer.setZIndex(zIndex);
      olMap.addLayer(layer);

      // 首都高の色変更
      if (getVisibleDataLayersCount() === 0) {
        state.kpLayerMgr.setWeakColor();
      }
      if (layer.name) {
        state.geoItemLayerNameMap[dataType] = layer.name;
      }
    };
    const removeGeoItemsLayer = (dataType: string) => {
      const layerName = state.geoItemLayerNameMap[dataType];
      if (layerName) {
        olMap.removeLayer(layerName);
        state.geoItemLayerNameMap[dataType] = null;
        // 首都高の色変更
        if (getVisibleDataLayersCount() === 0) {
          state.kpLayerMgr.setDefaultColor();
        }
      }
    };
    const getVisibleDataLayersCount = () => {
      const visibleGeoItemLayersCount =
        Object.entries(state.geoItemLayerNameMap).filter(e => !!e[1]).length;
      return visibleGeoItemLayersCount;
    };
    const showStallRiskPointsLayer = () => {
      const mgr = new ExtremeMapStallRiskPointsLayerManager();
      const { layer } = mgr.prepareLayer();
      if (!layer) { return; }
      layer.setZIndex(state.layerZIndexMap.stallRiskPoints);
      olMap.addLayer(layer);
    };
    const removeStallRiskPointsLayer = () => {
      const mgr = new ExtremeMapStallRiskPointsLayerManager();
      olMap.removeLayer(mgr.getStallRiskPointsLayerName());
    };
    const showSettouPatrolReportLayer = (reports: SettouPatrolReportExt[]) => {
      const mgr = state.settouPatrolReportsLayerMgr;
      if (!currentInstance) {
        return;
      }
      if (!currentInstance || !currentInstance.proxy) {
        return;
      }
      const { layer, layerInfo } = mgr.prepareLayer(currentInstance.proxy as any, reports);
      if (!layer) { return; }
      layer.setZIndex(state.layerZIndexMap.settouPatrolReports);
      olMap.addLayer(layer, {
        interaction: layerInfo.onLayerClick ? {
          click: layerInfo.onLayerClick,
        } : {},
      });
    };
    const removeSettouPatrolReportLayer = () => {
      const mgr = state.settouPatrolReportsLayerMgr;
      olMap.removeLayer(mgr.layerName);
      state.settouPatrolReportsLayerMgr = new ExtremeMapSettouPatrolReportsLayerManager();
    };
    const showEnsuiPlantLayer = (plants: EnsuiPlantExt[]) => {
      const mgr = state.ensuiPlantsLayerMgr;
      if (!currentInstance || !currentInstance.proxy) {
        return;
      }
      const { layer, layerInfo } = mgr.prepareLayer(currentInstance.proxy as any, plants);
      if (!layer || !layerInfo.onLayerClick) { return; }
      layer.setZIndex(state.layerZIndexMap.ensuiPlants);
      olMap.addLayer(layer, {
        interaction: layerInfo.onLayerClick ? {
          click: layerInfo.onLayerClick,
        } : {},
      });
    };
    const removeEnsuiPlantLayer = () => {
      const mgr = state.ensuiPlantsLayerMgr;
      olMap.removeLayer(mgr.layerName);
      state.ensuiPlantsLayerMgr = new ExtremeMapEnsuiPlantsLayerManager();
    };
    const removeCleaningCarLayer = () => {
      const mgr = cleaningState.cleaningCarLayerMgr;
      olMap.removeLayer(mgr.layerName);
      cleaningState.cleaningCarLayerMgr = new ExtremeMapCleaningCarLayerManager({
        hideCarIcons: false,
        hideCleaningPhotoIcons: false,
        hideDefectPhotoIcons: false,
      });
    };
    const showCleaningReportLayer = (cleaningReport: CleaningReportExt, opts: redrawCleaningReportLayerOpt) => {
      if (!currentInstance || !currentInstance.proxy) {
        return;
      }
      const { layer, layerInfo } = cleaningState.cleaningReportLayerMgr.prepareLayer(
        currentInstance.proxy as any,
        cleaningReport,
        opts,
        olMap.getZoom() ?? 10,
      );
      // 一括印刷だと先にmapEssentialsが作成された状態でExtremeMapコンポーネントが生成される都合上、
      // 上のほうで定義してるwatchが発火しないのでここで呼ぶ.
      if (props.mapEssentials) {
        initDisplay({
          ...props.mapEssentials,
          ...(layerInfo.extent ? { initialExtent: layerInfo.extent } : null),
        });
      }
      if (layer) {
        layer.setZIndex(100);
        olMap.addLayer(layer, {
          interaction: layerInfo.onLayerClick ? {
            click: layerInfo.onLayerClick,
          } : {},
        });
      }
      if (opts?.fitToExtent && layerInfo.extent) {
        olMap.fitToExtent(layerInfo.extent, {
          maxZoom: 20,
          duration: 500,
        });
      }
    };
    const removeCleaningReportLayer = () => {
      const mgr = cleaningState.cleaningReportLayerMgr;
      olMap.removeLayer(mgr.layerName);
      cleaningState.cleaningReportLayerMgr = new ExtremeMapCleaningReportLayerManager({
        hideCleaningPhotoIcons: false,
        hideDefectPhotoIcons: false,
      });
    };
    const showDebugMTXMovieLayer = (data: LayerParams) => {
      const mgr = new ExtremeMapDebugMTXMovieLayerManager();
      const { layer } = mgr.prepareLayer(data);
      if (!layer) { return; }
      layer.setZIndex(1000);
      olMap.addLayer(layer);
    };
    const removeDebugMTXMovieLayer = () => {
      const mgr = new ExtremeMapDebugMTXMovieLayerManager();
      olMap.removeLayer(mgr.getDebugLayerName());
    };
    const showDebugCyzenLayer = (data: { historyGroups: HistoryGroup[] }) => {
      const mgr = new ExtremeMapDebugCyzenLayerManager();
      const { layer } = mgr.prepareLayer(data);
      if (!layer) { return; }
      layer.setZIndex(1000);
      olMap.addLayer(layer);
    };
    const removeDebugCyzenLayer = () => {
      const mgr = new ExtremeMapDebugCyzenLayerManager();
      olMap.removeLayer(mgr.getDebugLayerName());
    };
    const hidePin = () => {
      state.pinLayerMgr.hidePins();
    };
    const notifyDeselectAll = () => {
      hidePin();
      // 各データレイヤー
      for (const ent of Object.entries(layerMgrMap)) {
        const layerMgr = ent[1];
        layerMgr.deselectAll();
      }
      // ポップアップ
      hidePopup();
      // 外部
      emit('all-deselected');
    };
    // 指定されたレイヤーを除いたレイヤーの選択表示を解除する
    const deselectLayersExcept = (exceptLayer: string) => {
      // 各データレイヤー
      for (const [dataName, layerMgr] of Object.entries(layerMgrMap)) {
        if (exceptLayer !== dataName) {
          layerMgr.deselectAll();
        }
      }
      // ポップアップ
      hidePopup();
    };
    const showDataLayer = (metaItem: GeoItemMeta, data: GIComment[], zIndexOffset = 0) => {
      removeDataLayer(metaItem.name);
      const { layer, layerInfo } = metaItem.layerManager.prepareLayer(data);
      const zIndex = metaItem.name in state.layerZIndexMap
        ? state.layerZIndexMap[metaItem.name as keyof LayerZIndexMap]
        : state.layerZIndexMap.currentLayerZIndex + zIndexOffset;
      if (!layer) { return; }
      layer.setZIndex(zIndex);
      olMap.addLayer(layer, {
        interaction: layerInfo.onLayerClick ? {
          click: layerInfo.onLayerClick,
        } : {},
      });
      metaItem.layerManager.connectWithExtremeMap(currentInstance?.proxy);
      layerMgrMap[metaItem.name] = metaItem.layerManager;
    };
    const removeDataLayer = (layerName: string) => {
      if (!layerMgrMap[layerName]) { return; }
      const { layer } = layerMgrMap[layerName].getLayer();
      if (!layer?.name) { return; }
      olMap.removeLayer(layer.name);
      layerMgrMap[layerName].destroy();
      delete layerMgrMap[layerName];
    };
    /**
     * layerManager側で発火したイベントを受け取るための関数群
     * (とりあえずclickしかないが)
     */
    const handleLayerManagerEventClick = (evtObj: MouseEvent) => {
      emit('click-item', evtObj);
    };
    const showPin = (location: Location) => {
      const point: GIPoint = {
        id: 'query-pin',
        lat: location.lat,
        lon: location.lon,
      };
      state.pinLayerMgr.showPin([point]);
    };
    // 中心点を移動して特定のズームに設定する.
    // (animationしようかとも思ったが、連打すると目が回るのでやめた.)
    const moveCenterTo = (data: Location) => {
      const pos = olMap.convCoord({
        lat: data.lat,
        lon: data.lon,
      });
      olMap.setCenter(pos);
    };
    const triggerResize = () => {
      state.onResizeFunc();
    };
    const setMapHeight = (h: number) => {
      const prevH = state.mapHeight;
      state.mapHeight = h;
      if (h !== prevH) {
        triggerResize();
      }
    };
    const initializePinAreaLayer = () => {
      const { layer } = state.pinLayerMgr.prepareLayer([]);
      if (!layer) {
        return;
      }
      layer.setZIndex(state.layerZIndexMap.pin);
      olMap.addLayer(layer);
    };
    return {
      ...toRefs(state),
      // computed
      olMapId,
      // refs
      popupContainer,
      popupCloser,
      popupContent,
      // methods
      initDisplay,
      initKpLayer,
      initResizeFunc,
      redrawCarLayer,
      redrawMovieLayer,
      redrawCleaningCarLayer,
      clickedMapBackground,
      clickedMapFeature,
      setMapClickEvent,
      setMapMoveEndEvent,
      tryShowStoreMoviePopup,
      floor,
      showCarPopup,
      showSettouPatrolReportPopup,
      showEnsuiPlantPopup,
      showCleaningCarPopup,
      showCleaningReportPhotoPopup,
      showCleaningMtxPopup,
      onSelectCarMovie,
      onStartLiveStream,
      onStopLiveStream,
      onEditJohaisetsuCar,
      onShowSettouPatrolReportDetailPage,
      showStoredMoviePopup,
      showMovieQueryArea,
      hideMovieQueryArea,
      hidePopup,
      onClickCar,
      onClickSettouPatrolReport,
      onClickEnsuiPlant,
      onClickCleaningCar,
      onClickCleaningReportPhoto,
      onClickCleaningMtx,
      deselectAllSettouPatrolReports,
      deselectAllEnsuiPlants,
      getSettouPatrolReportMap,
      getEnsuiPlantMap,
      addGeoItemsLayer,
      removeGeoItemsLayer,
      getVisibleDataLayersCount,
      showStallRiskPointsLayer,
      removeStallRiskPointsLayer,
      showSettouPatrolReportLayer,
      removeSettouPatrolReportLayer,
      showEnsuiPlantLayer,
      removeEnsuiPlantLayer,
      removeCleaningCarLayer,
      showCleaningReportLayer,
      removeCleaningReportLayer,
      showDebugMTXMovieLayer,
      removeDebugMTXMovieLayer,
      showDebugCyzenLayer,
      removeDebugCyzenLayer,
      deselectLayersExcept,
      showDataLayer,
      removeDataLayer,
      handleLayerManagerEventClick,
      showPin,
      moveCenterTo,
      triggerResize,
      setMapHeight,
    };
  },
});
