import { unpackTimeInteger } from '@/lib/dateTimeUtil';
const dayDispArr = [
  '日', '月', '火', '水', '木', '金', '土',
];

export function dateStrToDate(str?: string | null): Date | null {
  if (!str) { return null; }

  let m;
  m = str.match(/^(\d{4})[^\d](\d{2})[^\d](\d{2})$/);
  if (m) {
    // yyyy/mm/dd的な
    return new Date(parseInt(m[1]), parseInt(m[2]) - 1, parseInt(m[3]));
  }
  m = str.match(/^(\d{2})[^\d](\d{2})[^\d](\d{4})$/);
  if (m) {
    // dd/mm/yyyy的な
    return new Date(parseInt(m[3]), parseInt(m[2]) - 1, parseInt(m[1]));
  }

  /*
   * 2018-12-10 12:13:14
   * 2018-12-10 12:13:14Z
   * 2018-12-10 12:13:14+0900
   * 2018-12-10T12:13:14+0900
   * 2018-12-10 12:13:14.000Z
   * 2018-12-10 12:13:14.000+0900
   * 2018-12-10T12:13:14.000+0900
   * timezone部分は2桁or4桁に対応(コードは2-4桁になってしまってるけど)
   */
  m = str.match(/^(\d{4})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})[^0-9](\d{2})(\.\d{3})?(Z)?([+-]\d{2,4})?$/);
  if (m) {
    const year = parseInt(m[1]);
    const month = parseInt(m[2]) - 1;
    const date = parseInt(m[3]);
    const hours = parseInt(m[4]);
    const minutes = parseInt(m[5]);
    const seconds = parseInt(m[6]);
    const mseconds = m[7] ? parseInt(m[7].slice(1)) : 0;
    if (m[8]) { // Z
      return new Date(Date.UTC(year, month, date, hours, minutes, seconds, mseconds));
    } else if (m[9]) { // +09, +0900
      // tz反映済の数値を一旦UTCとして解釈して、UTCからtz分だけずれたtimestampを取得
      const msecTimestamp = Date.UTC(year, month, date, hours, minutes, seconds, mseconds);
      const tzStr = (m[9] + '00').slice(0, 5); // 記号プラス4桁を保証
      const sign = tzStr[0] === '+' ? 1 : -1;
      const offsetHours = parseInt(tzStr.slice(1, 3));
      const offsetMinutes = parseInt(tzStr.slice(3, 5));
      const offsetMsec = (offsetHours * 60 + offsetMinutes) * 60 * 1000 * sign;
      // ずれていた分のmsecを相殺する
      return new Date(msecTimestamp - offsetMsec);
    } else {
      return new Date(year, month, date, hours, minutes, seconds, mseconds);
    }
  }

  return new Date(str.replace(/ /, 'T'));
}

export function ensureDateWithoutNull(dt: string): Date {
  return ensureDate(dt) || new Date();
}

export function ensureDate(dt?: Date | string | null): Date | null {
  if (!dt) { return null; }

  if (typeof dt === 'string') {
    return dateStrToDate(dt);
  }
  return dt;
}

export function dateStrToDateWithoutTime(str: string | null): Date | null {
  const tmpDate = dateStrToDate(str);
  if (!tmpDate) { return tmpDate; }
  tmpDate.setHours(0);
  return tmpDate;
}
export function timeToString(timeInt: number | null): (string | null)[] {
  let hour : string | null = null;
  let minutes : string | null = null;
  if (timeInt !== null) {
    const [h, m] = unpackTimeInteger(timeInt);
    hour = `0${h}`.slice(-2);
    minutes = `0${m}`.slice(-2);
  } else {
    hour = null;
    minutes = null;
  }
  return [hour, minutes];
}

export const dtFormat = (date?: Date | string | null, format = 'yyyy/mm/dd HH:MM:SS'): string => {
  date = ensureDate(date);
  if (!date || isNaN(date.valueOf())) { return ''; }
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const h = date.getHours();
  const min = date.getMinutes();
  const s = date.getSeconds();
  const ms = date.getMilliseconds();
  const youbi = dayDispArr[date.getDay()];
  return format
    .replace(/yyyy/g, y.toString())
    .replace(/mm/g, ('0' + m).slice(-2))
    .replace(/m/g, m.toString())
    .replace(/dd/g, ('0' + d).slice(-2))
    .replace(/d/g, d.toString())
    .replace(/HH/g, ('0' + h).slice(-2))
    .replace(/H/g, h.toString())
    .replace(/MM/g, ('0' + min).slice(-2))
    .replace(/M/g, min.toString())
    .replace(/SS/g, ('0' + s).slice(-2))
    .replace(/S/g, s.toString())
    .replace(/\.sss/g, '.' + ('00' + ms).slice(-3))
    .replace(/\.ss/g, '.' + ('0' + ms).slice(-2))
    .replace(/\.s/g, '.' + ms)
    .replace(/a/g, youbi);
};

export const fuzzyTimeDisp = (compDate: Date | string | null, baseDate: Date | string | null): string => {
  baseDate = ensureDate(baseDate);
  compDate = ensureDate(compDate);
  if (!baseDate || !compDate) { return ''; }
  const secDiff = (baseDate.getTime() - compDate.getTime()) / 1000;

  if (secDiff < 60) {
    return `${Math.round(secDiff)}秒前`;
  } else if (secDiff < 3600) {
    const minDiff = Math.round(secDiff / 60);
    return `${minDiff}分前`;
  } else if (secDiff < 86400) {
    const hourDiff = Math.round(secDiff / 3600);
    return `${hourDiff}時間前`;
  } else {
    const baseY = baseDate.getFullYear();
    const baseM = baseDate.getMonth();
    const baseD = baseDate.getDate();
    const compY = compDate.getFullYear();
    const compM = compDate.getMonth();
    const compD = compDate.getDate();
    if (secDiff < 86400 * 31) {
      // xx日前と表示する場合は、カレンダー的な日数の差でないとしっくりこない
      const tmpBaseDate = new Date(baseY, baseM, baseD);
      const tmpCompDate = new Date(compY, compM, compD);
      const dayDiff = Math.round((tmpBaseDate.getTime() - tmpCompDate.getTime()) / 86400 / 1000);
      return `${dayDiff}日前`;
    } else if (baseY === compY) {
      return `${compM + 1}月${compD}日`;
    } else {
      return `${compY}年${compM + 1}月${compD}日`;
    }
  }
};
