
export function isRoleHonsha(role: string | null): boolean {
  return role === 'honsha';
}
export function isRoleKyoku(role: string | null): boolean {
  if (!role) return false;
  return ['t-west', 't-east', 'kanagawa'].includes(role);
}
export function isDefaultRole(role: string | null): boolean {
  return role?.includes('default') || false;
}
export function isRoleDriver(role: string | null): boolean {
  return role?.includes('driver') || false;
}
