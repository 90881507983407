


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import boardMessageApi from '@/apis/board_message';
import { MessageBoardBase, MessageBoard } from '@/models/apis/messageBoard/messageBoardResponse';
import { lineBreakToBR } from '@/lib/utils';
import { dtFormat, dateStrToDate } from '@/lib/dateHelper';
import { KindChoice } from '@/models/index';

interface MessageBoardEdit {
  id?: number;
  kind?: string | null;
  tsH?: string | null;
  tsM?: string | null;
  ts?: string | null;
  content: string | null;
  kindDisp?: string;
  formattedContent?: string;
  registered_ts?: Date | null;
}
interface MessageBoardState {
  kindChoices: KindChoice[];
  kindChoicesMap: Record<string, string>;
  boardMessages: MessageBoardEdit[];
  newForm: MessageBoardEdit;
  editingBoardMessage: MessageBoardEdit;
  editingBoardMessageOrig?: MessageBoardEdit;
  showNewForm: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  showNewFormClearConfirmModal: boolean;
  showEditClearConfirmModal: boolean;
  showErrorModal: boolean;
  errorBody: string;
  destroyId: number | null;

}

export default defineComponent({
  name: 'boardMessage',
  setup() {
    const state = reactive<MessageBoardState>({
      kindChoices: [
        { value: 'broadcast', text: '周知' },
        { value: 'report', text: '報告' },
        { value: 'instruction', text: '指示' },
      ],
      kindChoicesMap: {},
      boardMessages: [],
      newForm: {
        kind: null,
        tsH: null,
        tsM: null,
        ts: null,
        content: null,
      },
      editingBoardMessage: {
        kind: null,
        tsH: null,
        tsM: null,
        ts: null,
        content: null,
      },
      showNewForm: false,
      showEditModal: false,
      showDeleteModal: false,
      showNewFormClearConfirmModal: false,
      showEditClearConfirmModal: false,
      showErrorModal: false,
      errorBody: '',
      destroyId: null,
    });
    const store = useStore();
    const userState = store.state.user;
    const userId = computed<number>(() => {
      return userState.id;
    });
    const isAdmin = computed<boolean>(() => {
      return userState.has_role_admin;
    });
    const shouldShowEditModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      if (state.showEditClearConfirmModal) { return false; }
      return state.showEditModal;
    });
    const shouldShowDeleteModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      return state.showDeleteModal;
    });

    onMounted(() => {
      state.kindChoicesMap = {};
      for (const choice of state.kindChoices) {
        state.kindChoicesMap[choice.value] = choice.text.toString();
      }
      reloadBoardMessages();
    });

    const reloadBoardMessages = () => {
      boardMessageApi.index().then(({ data }) => {
        state.boardMessages = convBoardMessagesResponse(data);
      });
    };
    const convBoardMessagesResponse = (data: MessageBoard[]): MessageBoardEdit[] => {
      return data.map(e => {
        const kind = (e.kind || '').trim();
        return {
          ...e,
          // なぜか列がchar(255)になっているのでtrim
          kind: kind,
          kindDisp: (state.kindChoicesMap[kind] || '-'),
          formattedContent: lineBreakToBR(e.content),
          registered_ts: (dateStrToDate(e.registered_ts) || null),
        };
      });
    };
    const validateNewFormHour = () => {
      state.newForm.tsH = (Number(state.newForm.tsH) > 23 ? 23 : Number(state.newForm.tsH)).toString();
    };
    const validateNewFormMinute = () => {
      state.newForm.tsM = (Number(state.newForm.tsM) > 59 ? 59 : Number(state.newForm.tsM)).toString();
    };
    const validateEditingBoardMessageHour = () => {
      state.editingBoardMessage.tsH = (Number(state.editingBoardMessage.tsH) > 23 ? 23 : Number(state.editingBoardMessage.tsH)).toString();
    };
    const validateEditingBoardMessageMinute = () => {
      state.editingBoardMessage.tsM = (Number(state.editingBoardMessage.tsM) > 59 ? 59 : Number(state.editingBoardMessage.tsM)).toString();
    };
    const isInteger = (v: string) => {
      return /^\d*$/.test(v) && v !== '';
    };
    const calcTsFromHM = (h: string, m: string) => {
      let dt = new Date();
      const tmpH = ('0' + h).slice(-2);
      const tmpM = ('0' + m).slice(-2);
      let ret = dtFormat(dt, 'yyyy-mm-dd');
      ret += ` ${tmpH}:${tmpM}:00`;
      return ret;
    };
    const dateFromTimeStr = (timeStr: string) => {
      let dt = new Date();
      let ret = dtFormat(dt, 'yyyy-mm-dd');
      ret += ` ${timeStr}:00`;
      return ret;
    };
    const openNewForm = () => {
      const now = new Date();
      const formObj: MessageBoardEdit = {
        kind: null,
        tsH: dtFormat(now, 'HH'),
        tsM: dtFormat(now, 'MM'),
        content: null,
      };
      prepareOrigValues(formObj);
      state.newForm = formObj;
      state.showNewForm = true;
    };
    const closeNewForm = () => {
      if (isEdited(state.newForm)) {
        state.showNewFormClearConfirmModal = true;
      } else {
        state.showNewForm = false;
      }
    };
    const clearNewForm = () => {
      state.showNewFormClearConfirmModal = false;
      state.showNewForm = false;
    };
    const addBoardMessage = () => {
      if (!checkInputs(state.newForm)) { return; }

      try {
        boardMessageApi.create({
          registered_ts: new Date(),
          kind: state.newForm.kind || '',
          ts: calcTsFromHM(state.newForm.tsH || '', state.newForm.tsM || ''),
          content: validateContent(state.newForm.content || ''),
        }).then(() => {
          reloadBoardMessages();
          state.showNewForm = false;
        });
      } catch (e) {
        console.error('error', e);
        state.errorBody = '保存に失敗しました。再度保存を行ってください';
        state.showErrorModal = true;
      }
    };
    const editBoardMessage = (origFormObj: MessageBoardBase) => {
      const formObj = {
        id: origFormObj.id,
        registered_ts: origFormObj.registered_ts,
        kind: origFormObj.kind,
        tsH: dtFormat(origFormObj.ts, 'HH'),
        tsM: dtFormat(origFormObj.ts, 'MM'),
        content: origFormObj.content,
      };
      prepareOrigValues(formObj);
      state.editingBoardMessage = formObj;
      state.showEditModal = true;
    };
    const tryCancelEditBoardMessage = () => {
      if (isEdited(state.editingBoardMessage)) {
        state.showEditClearConfirmModal = true;
      } else {
        state.showEditModal = false;
      }
    };
    const cancelEditBoardMessage = () => {
      state.showEditClearConfirmModal = false;
      state.showEditModal = false;
    };
    const updateBoardMessage = () => {
      if (!checkInputs(state.editingBoardMessage)) { return; }

      try {
        boardMessageApi.update(
          state.editingBoardMessage.id || 0,
          {
            registered_ts: state.editingBoardMessage.registered_ts,
            kind: state.editingBoardMessage.kind,
            ts: calcTsFromHM(state.editingBoardMessage.tsH || '', state.editingBoardMessage.tsM || ''),
            content: validateContent(state.editingBoardMessage.content || ''),
          },
        ).then(() => {
          state.showEditModal = false;
          reloadBoardMessages();
        }).catch(e => {
          console.error('error', e);
          state.errorBody = '保存に失敗しました。再度操作を行ってください';
          state.showErrorModal = true;
        });
      } catch (e) {
        console.error('error', e);
        state.errorBody = '保存に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const validateContent = (v: string) => {
      if (v.length < 256) {
        return v;
      }
      return v.substr(0, 255);
    };
    const tryDeleteBoardMessage = (formObj: MessageBoardEdit) => {
      if (formObj.id) {
        state.destroyId = formObj.id;
      }
      state.showDeleteModal = true;
    };
    const deleteBoardMessage = () => {
      try {
        boardMessageApi.destroy(state.destroyId || 0).then(() => {
          reloadBoardMessages();
          state.showDeleteModal = false;
        }).catch(e => {
          console.error('error', e);
          state.errorBody = '削除に失敗しました。再度操作を行ってください';
          state.showErrorModal = true;
        });
      } catch (e) {
        console.error('error', e);
        state.errorBody = '削除に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const isReadyToSubmit = (formObj: MessageBoardEdit) => {
      return checkInputs(formObj);
    };
    const checkInputs = (formObj: MessageBoardEdit) => {
      if (!formObj.kind ||
          formObj.tsH === null ||
          formObj.tsM === null ||
          !formObj.content ||
          formObj.content.match(/^\s*$/)) {
        return false;
      }
      if (!isInteger(formObj.tsH || '') || !isInteger(formObj.tsM || '')) {
        return false;
      }
      return true;
    };
    const isEdited = (formObj: MessageBoardEdit) => {
      if (formObj.kind !== state.editingBoardMessageOrig?.kind) { return true; }
      if (formObj.tsH !== state.editingBoardMessageOrig?.tsH) { return true; }
      if (formObj.tsM !== state.editingBoardMessageOrig?.tsM) { return true; }
      if (formObj.content !== state.editingBoardMessageOrig?.content) { return true; }
      return false;
    };
    const prepareOrigValues = (formObj: MessageBoardEdit) => {
      state.editingBoardMessageOrig = (Object.assign({}, formObj) as MessageBoardEdit);
      return false;
    };
    const isEditable = (item: MessageBoard) => {
      if (isAdmin.value) {
        return true;
      }
      return userId.value === item.user_id;
    };
    const isDeletable = (item: MessageBoard) => {
      if (isAdmin.value) {
        return true;
      }
      return userId.value === item.user_id;
    };
    return {
      ...toRefs(state),
      // computed
      userId,
      isAdmin,
      shouldShowEditModal,
      shouldShowDeleteModal,
      // methods
      reloadBoardMessages,
      convBoardMessagesResponse,
      validateNewFormHour,
      validateNewFormMinute,
      validateEditingBoardMessageHour,
      validateEditingBoardMessageMinute,
      isInteger,
      calcTsFromHM,
      dateFromTimeStr,
      openNewForm,
      closeNewForm,
      clearNewForm,
      addBoardMessage,
      editBoardMessage,
      tryCancelEditBoardMessage,
      cancelEditBoardMessage,
      updateBoardMessage,
      validateContent,
      tryDeleteBoardMessage,
      deleteBoardMessage,
      isReadyToSubmit,
      checkInputs,
      isEdited,
      prepareOrigValues,
      isEditable,
      isDeletable,
      dtFormat,
    };
  },
});
