
import { defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { SnowHeight } from '@/models/apis/johaisetsu/sekisetsuInputRequest';
import { CommonHeader, JoukyouInfo } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { PlanningBlock, TaskForce } from '@/models/johaisetsuCommon';

import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import johaisetsuPlanningApi from '@/apis/johaisetsu_planning';
import { getTaskForceInfos, isDataReadOnly, onChangeUrlPathParam } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { preparePlanningBlocks } from '@/lib/johaisetsu/johaisetsuPlanningUtil';
import { notifySuccess } from '@/lib/notificationUtil';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole } from '@/lib/johaisetsuRoleHelper';

interface JohaisetsuSekisetsuInput {
  isReady: boolean;
  isReadOnly: boolean;
  isRequesting: boolean;
  planningBlocks: PlanningBlock[];

  taskForces: TaskForce[];
  showTaskForceSelect: boolean;
  selectedTaskForce: TaskForce;

  planningHeaders: CommonHeader[];
  selectedPlanningInfo?: JoukyouInfo;
}
export default defineComponent({
  name: 'johaisetsu-sekisetsu-input',
  setup() {
    const state = reactive<JohaisetsuSekisetsuInput>({
      isReady: false,
      isReadOnly: false,
      isRequesting: false,
      planningBlocks: [],

      taskForces: [],
      showTaskForceSelect: false,
      selectedTaskForce: { id: -1, name: '' },

      planningHeaders: [],
      selectedPlanningInfo: undefined,
    });

    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });

    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const saveItemsDetail = async(shouldProceed: boolean) => {
      if (state.isRequesting) { return; }

      const details: SnowHeight[] = [];
      const len = state.planningBlocks.length;
      for (let i = 0; i < len; i += 2) {
        const pb = state.planningBlocks[i];
        if (!pb.inputMap || !pb.origInputMap) { continue; }
        const snowHeightCm = pb.inputMap.snow_height_cm;
        // この画面はスマホで編集される可能性がある割に広範囲に表示して
        // しまっている(路線ごとに別の人が編集するかも)ため、変更されたやつだけ送る.
        if (snowHeightCm === pb.origInputMap.snow_height_cm) { continue; }
        // 画面表示の都合上、路線_区間ごとに先頭行のtextinputしか出してないので、
        // 路線_区間ごとの先頭行が更新されてたらその次の行も送る.
        details.push({
          planning_block_id: pb.id,
          snow_height_cm: snowHeightCm,
        });
        const pbNext = state.planningBlocks[i + 1];
        details.push({
          planning_block_id: pbNext.id,
          snow_height_cm: snowHeightCm,
        });
      }

      state.isRequesting = true;
      await johaisetsuPlanningApi.updateSnowHeight(
        state.selectedTaskForce.id,
        { details: details, proceed_flow_step: shouldProceed },
      );
      notifySuccess('', 'データを保存しました');
      // 再取得
      await getSelectedPlanningInfo();
      state.isRequesting = false;
    };
    const saveItems = async() => {
      await saveItemsDetail(false);
    };
    // この画面単体で本社に送信する意味はない
    // async saveItemsAndProceed() {
    //   await this.saveItems_(true)
    // },
    const getSelectedPlanningInfo = async() => {
      const { data: selectedPlanningInfo } = await johaisetsuPlanningApi.show({
        taskForceId: state.selectedTaskForce.id,
        headerId: headerId.value,
      });
      state.selectedPlanningInfo = selectedPlanningInfo;

      state.planningBlocks =
        preparePlanningBlocks(
          state.selectedTaskForce,
          state.selectedPlanningInfo,
          johaisetsuRole.value,
        );
    };

    onMounted(async() => {
      state.isRequesting = true;
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;

      // 待たなくていい
      johaisetsuPlanningApi.index({
        taskForceId: state.selectedTaskForce.id,
      }).then(({ data: planningHeaders }) => {
        state.planningHeaders = planningHeaders;
      });

      await getSelectedPlanningInfo();
      state.isRequesting = false;

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedPlanningInfo?.header,
        johaisetsuRole.value,
      );

      state.isReady = true;
    });
    return {
      ...toRefs(state),
      // computed
      johaisetsuRole,
      taskForceId,
      headerId,
      // methods
      onSelectedTaskForceChange,
      saveItems,
      getSelectedPlanningInfo,
    };
  },
});
