import { Store, useStore } from '@/hooks/useStore';
import { UserActionTypes, UserState } from '@/store/modules/user';
import {
  MasterData,
  Lovs,
  RoadNameDirection,
  RoadNameDirectionMix,
  RoadNameDirectionShortcut,
} from '@/models';
import {
  CommentType,
  Lov,
  RoadName,
  RoadNameDisp,
} from '@/models/apis/master/masterResponse';
import { ShareScopeLov } from '@/models/apis/user/userResponse';

import masterApi from '@/apis/master';
import { convKiloposts_, getRoadNameDirections_, getRoadNameDirectionShortcuts_ } from './kilopostHelper';
import { GIKilopost } from '@/models/geoItem';

export async function ensureUserAndMasters(store: Store): Promise<{
  user: UserState;
  masters: MasterData;
}> {
  const promises: [Promise<UserState>, Promise<MasterData>] = [
    store.dispatch(UserActionTypes.GET_ME),
    window.master.$promise,
  ];
  // window.masterの中身が変更されることもあるので、
  // $promiseで最初に取得したものではなく、window.masterそのものを返す
  const [user] = await Promise.all(promises);
  return { user, masters: window.master };
}

export async function waitForMasters<T>(arg: T): Promise<T> {
  await window.master.$promise;
  return arg;
}

export async function waitForUserAndMasters<T>(arg?: T): Promise<T | undefined> {
  const store = useStore();
  const promises: [Promise<UserState>, Promise<MasterData>] = [
    store.dispatch(UserActionTypes.GET_ME),
    window.master.$promise,
  ];
  await Promise.all(promises);
  return arg;
}

export function loadUserAndMasters(): void {
  const userPromise = loadUser();
  loadMasters(userPromise);
}
function loadUser(): Promise<UserState> {
  const store = useStore();
  return store.dispatch(UserActionTypes.GET_ME);
}
export function loadMasters(userPromise: Promise<UserState>): void {
  const prms = masterApi.getAll()
    .then(({ data }) => {
      const lovs: Lovs = {
        car_kind: {
          vals: data.lovs.car_kind,
          map: data.lovs.car_kind.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
        role: {
          vals: data.lovs.role,
          map: data.lovs.role.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
        share_scope: {
          vals: [],
          map: {},
        },
        default_page: {
          vals: data.lovs.default_page,
          map: data.lovs.default_page.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
        johaisetsu_role: {
          vals: data.lovs.johaisetsu_role,
          map: data.lovs.johaisetsu_role.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
        cleaning_role: {
          vals: data.lovs.cleaning_role,
          map: data.lovs.cleaning_role.reduce<Record<string, Lov>>((acc, e) => {
            acc[e.key] = e; return acc;
          }, {}),
        },
      };

      const roadNameDirections = getRoadNameDirections_(data.kp_set_name);
      const roadNameDirectionShortcuts = getRoadNameDirectionShortcuts_(data.kp_set_name);
      const kpSetId = data.kiloposts[0].n; // kp_set_id
      const roadNameStuff = convRoadNameStuff(kpSetId, roadNameDirections, roadNameDirectionShortcuts);
      const commentType = {
        vals: data.comment_types,
        map: data.comment_types.reduce<Record<string, CommentType>>((acc, e) => {
          acc[e.comment_type] = e; return acc;
        }, {}),
      };
      const commentTypeIconPaths = Object.values(data.comment_type_icon_paths);
      const masterData: MasterData = {
        lovs,
        kpMap: convKiloposts_({
          kiloposts: data.kiloposts,
          kilopost_col_map: data.kilopost_col_map,
          kilopost_val_map: data.kilopost_val_map,
        }),
        kpSetName: data.kp_set_name,
        roadNameDirections,
        roadNameDirectionShortcuts,
        kpMapByKpUid: new Map<string, GIKilopost>(),
        commentTypeIconPaths,
        roadNames: roadNameStuff.roadNames,
        roadNameDisps: roadNameStuff.roadNameDisps,
        roadNameDispMap: roadNameStuff.roadNameDispMap,
        commentType,
        geoConnections: {},
        roadNameToRoadNameDispMap: roadNameStuff.roadNameToRoadNameDispMap,
      };
      const prms = window.master.$promise;
      window.master = {
        $promise: prms,
        ...masterData,
      };
      // wait for user info
      return userPromise;
    })
    .then(user => {
      // 公開範囲
      window.master.lovs.share_scope = {
        vals: user.settings.share_scope_lov,
        map: user.settings.share_scope_lov.reduce<Record<string, ShareScopeLov>>((acc, e) => {
          acc[e.key] = e; return acc;
        }, {}),
      };
    })
    .then(() => {
      return window.master;
    });
  window.master = { $promise: prms } as any;
}

export function refreshCommentTypeMaster(commentTypes: CommentType[]): void {
  window.master.commentType = {
    vals: commentTypes,
    map: commentTypes.reduce<Record<string, CommentType>>((acc, e) => {
      acc[e.comment_type] = e; return acc;
    }, {}),
  };
}

export function convRoadNameStuff(kpSetId: number, origRoadNames: RoadNameDirectionMix[], roadNameDirectionShortcuts: RoadNameDirectionShortcut[]): {
  roadNames: RoadName[];
  roadNameDisps: RoadNameDisp[];
  roadNameDispMap: Record<string, RoadNameDisp>;
  roadNameToRoadNameDispMap: Record<string, string>;
} {
  if (!origRoadNames) {
    return { roadNames: [], roadNameDisps: [], roadNameDispMap: {}, roadNameToRoadNameDispMap: {} };
  }

  const roadNames: RoadName[] = [];
  const tmpMap1: Record<string, RoadNameDisp> = {};
  const roadNameDisps: RoadNameDisp[] = [];

  const areaNameInfo = roadNameDirectionShortcuts.find(e => e.key === 'area')?.arr;

  let roadIdIndex = 1;
  origRoadNames.forEach(x => {
    if (x.isDummy) {
      return;
    }
    const e = x as RoadNameDirection;
    const area = areaNameInfo?.find(y => y.key === e.area)?.name || '他';
    const roadNameObj: RoadName = {
      area: area,
      road_name_disp: e.roadNameReal,
      road_name_disp_short: e.roadNameDisp,
      road_names: [ e.roadNameReal ],
      directions: e.directions.map(z => z.direction),
      id: roadIdIndex++,
      kp_set_id: kpSetId,
      road_name: e.roadNameReal,
      disp_order: roadIdIndex,
    };
    roadNames.push(roadNameObj);

    // road_name_dispごとにまとめたものも作る
    const roadNameDisp = roadNameObj.road_name_disp;
    if (!tmpMap1[roadNameDisp]) {
      tmpMap1[roadNameDisp] = {
        area: area,
        road_name_disp: e.roadNameReal,
        road_name_disp_short: e.roadNameDisp,
        road_names: [],
        directions: [],
      };
      roadNameDisps.push(tmpMap1[roadNameDisp]);
    }
    const roadNameDispObj = tmpMap1[roadNameDisp];
    roadNameDispObj.road_names.push(e.roadNameDisp);
    roadNameDispObj.directions = [...new Set([...roadNameDispObj.directions, ...e.directions.map(z => z.direction)])];
  });

  const roadNameDispMap: Record<string, RoadNameDisp> = {};
  const roadNameToRoadNameDispMap: Record<string, string> = {};
  roadNameDisps.forEach(e => {
    roadNameDispMap[e.road_name_disp] = e;
    e.road_names.forEach((roadName: string) => {
      roadNameToRoadNameDispMap[roadName] = e.road_name_disp;
    });
  });

  return { roadNames, roadNameDisps, roadNameDispMap, roadNameToRoadNameDispMap };
}
