


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  ref,
} from '@vue/composition-api';

import cleaningHanMastersApi from '@/apis/cleaning_han_masters';

import { SearchState } from '@/models/cleaningMap';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import RealtimeView from './components/RealtimeView/index.vue';
import ReportsView from './components/ReportsView/index.vue';
import ManualDownloadLinks from './components/common/ManualDownloadLinks/index.vue';
import MapMiscInfo from './components/common/MapMiscInfo.vue';
import { CleaningHanWithCleaningCompany } from '@/models/apis/cleaningHanMasters/cleaningHanMastersResponse';
import { CleaningCompany } from '@/models/apis/cleaning/cleaningCommon';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { waitForCleaningMasters } from '@/lib/cleaningHelper';
import { dtFormat } from '@/lib/dateHelper';
import { initSearchConds, validSearchDateRange } from '@/components/CleaningMap/utils';
import {
  VIEW_MODE_REALTIME,
  VIEW_MODE_REPORT,
  cleaningAreas,
  statusChoices,
} from '@/components/CleaningMap//consts/cleaning_map';
import { ITEMS_PER_PAGE_DEFAULT, CURRENT_PAGE_DEFAULT } from '@/components/CleaningMap/consts/cleaning_print';
import useCleaningCars, { provideCleaningCars } from '@/components/CleaningMap/composables/useCleaningCars';
import { provideCleaningReports, useCleaningReports } from '@/components/CleaningMap/composables/useCleaningReports';
import { provideWaitSpinner } from '@/components/CleaningMap/composables/useWaitSpinner';

export default defineComponent({
  name: 'cleaning-map',
  setup() {
    const viewMode = ref<string>('');

    const searchState = reactive<SearchState>({
      areaChoices: [],
      cleaningCompanies: [],
      cleaningCompanyAreaMap: {},
      cleaningHanMasters: [],

      searchConds: {
        cleaningArea: null,
        cleaningCompanyId: null,
        cleaningHanName: null,
        status: '全て',
        dateFrom: new Date(),
        dateTo: new Date(),
      },
      decidedSearchConds: {
        cleaningArea: null,
        cleaningCompanyId: null,
        cleaningHanName: null,
        status: '全て',
        dateFrom: new Date(),
        dateTo: new Date(),
      },
    });
    const waitSpinnerState = provideWaitSpinner();

    const store = useStore();
    const userState = store.state.user;
    const isRealtimeMode = computed<boolean>(() => {
      return viewMode.value === VIEW_MODE_REALTIME;
    });
    const isReportMode = computed<boolean>(() => {
      return viewMode.value === VIEW_MODE_REPORT;
    });
    const cleaningCompanyChoices = computed<CleaningCompany[]>(() => {
      if (!searchState.searchConds.cleaningArea) {
        return searchState.cleaningCompanies.slice();
      }
      return searchState.cleaningCompanies.filter(e =>
        e.area === searchState.searchConds.cleaningArea,
      );
    });
    const cleaningHanChoices = computed<Record<string, string>>(() => {
      const hanMasters = filterHanMasters();
      return hanMasters.reduce((acc: Record<string, string>, e) => {
        acc[e.name] = e.name;
        return acc;
      }, {});
    });
    const filterHanMasters = (): CleaningHanWithCleaningCompany[] => {
      // 選択された管轄、清掃会社で清掃班を絞り込む
      // 絞り込みは清掃会社>管轄の優先順位とする
      if (searchState.searchConds.cleaningCompanyId) {
        return searchState.cleaningHanMasters.filter(han =>
          han.cleaning_company[0].id === searchState.searchConds.cleaningCompanyId);
      }
      if (searchState.searchConds.cleaningArea) {
        return searchState.cleaningHanMasters.filter(han =>
          han.cleaning_company[0].area === searchState.searchConds.cleaningArea);
      }
      return searchState.cleaningHanMasters.slice();
    };

    const isDateParamOk = computed<boolean>(() => {
      return validSearchDateRange(searchState.searchConds);
    });

    const { route, router } = useRoute();

    onMounted(async() => {
      await Promise.all([waitForUserAndMasters(), waitForCleaningMasters()]);
      redirectIfNoAbility(userState, route.value);

      // cleaning_roleから協力会社(通常)の情報を取得
      const companyRoleCleaningCompany = window.cleaningMaster.cleaningCompanies.find(e =>
        e.cleaning_company_role.map(e => e.cleaning_role).includes(userState.cleaning_role),
      );
      searchState.areaChoices = cleaningAreas.filter((e) => {
        if (userState.cleaning_role === 'honsha') {
          return true;
        }
        if (companyRoleCleaningCompany) {
          return e.name === companyRoleCleaningCompany.area;
        }
        return e.cleaningRole === userState.cleaning_role;
      });
      searchState.cleaningCompanies = window.cleaningMaster.cleaningCompanies.filter((e) => {
        if (userState.cleaning_role === 'honsha') {
          return true;
        }
        if (companyRoleCleaningCompany) {
          return e.id === companyRoleCleaningCompany.id;
        }
        const area = cleaningAreas.find((e) => e.cleaningRole === userState.cleaning_role)?.name;
        return e.area === area;
      });
      searchState.cleaningCompanyAreaMap = searchState.cleaningCompanies
        .reduce((acc: Record<string, number[]>, e) => {
          if (!acc[e.area]) {
            acc[e.area] = [];
          }
          acc[e.area].push(e.id);
          return acc;
        }, {});
      const hanResult = await cleaningHanMastersApi.getByUser();
      searchState.cleaningHanMasters = hanResult.data;
      if (searchState.cleaningHanMasters.length === 1) {
        searchState.searchConds.cleaningHanName = searchState.cleaningHanMasters[0].name;
      }

      searchState.searchConds = initSearchConds(searchState.areaChoices, searchState.cleaningCompanies);
      viewMode.value = VIEW_MODE_REALTIME;
    });

    const {
      filterCars,
      clearCars,
    } = (() => {
      const injectedState = provideCleaningCars(searchState);
      return useCleaningCars(injectedState);
    })();

    const {
      searchReports,
      clearReports,
    } = (() => {
      const reportState = provideCleaningReports(searchState);
      return useCleaningReports(reportState);
    })();

    const changeViewMode = async(mode: string) => {
      if (mode === viewMode.value) return;

      searchState.searchConds = initSearchConds(searchState.areaChoices, searchState.cleaningCompanies);
      viewMode.value = mode;
      if (mode === VIEW_MODE_REALTIME) {
        clearReports();
      }
      if (mode === VIEW_MODE_REPORT) {
        clearCars();
      }
    };

    const onClickReportPrintAllButton = () => {
      // 管轄を選んだ時には、その管轄の清掃会社のみを検索対象にする
      const cleaningCompanyIds = [];
      if (searchState.searchConds.cleaningArea) {
        cleaningCompanyIds.push(...searchState.cleaningCompanyAreaMap[searchState.searchConds.cleaningArea]);
      }
      if (searchState.searchConds.cleaningCompanyId) {
        cleaningCompanyIds.push(searchState.searchConds.cleaningCompanyId);
      }

      const routeObj = {
        name: 'PrintCleaningReportsAll',
        params: {
          dateFrom: dtFormat(searchState.searchConds.dateFrom, 'yyyy-mm-dd'),
          dateTo: dtFormat(searchState.searchConds.dateTo, 'yyyy-mm-dd'),
          cleaningCompanyIds: cleaningCompanyIds.join(',') || 'all',
          cleaningHanName: searchState.searchConds.cleaningHanName || 'all',
          currentPage: CURRENT_PAGE_DEFAULT.toString(),
          itemsPerPage: ITEMS_PER_PAGE_DEFAULT.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };

    return {
      ...toRefs(searchState),
      waitSpinnerState,
      // const
      statusChoices,
      VIEW_MODE_REALTIME,
      VIEW_MODE_REPORT,
      // computed
      isRealtimeMode,
      isReportMode,
      cleaningCompanyChoices,
      cleaningHanChoices,
      isDateParamOk,
      // methods
      changeViewMode,
      filterCars,
      searchReports,
      onClickReportPrintAllButton,
    };
  },
  components: {
    RealtimeView,
    ReportsView,
    ManualDownloadLinks,
    MapMiscInfo,
  },
});
