











import {
  defineComponent,
} from '@vue/composition-api';
import OtherSanpuHelper from '@/lib/geo_item/other_sanpuHelper';
import { DispData } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-other-sanpu',
  setup() {
    const otherSanpuHelper = new OtherSanpuHelper();
    const dispData: DispData[] = [
      { val: 1, label: '1' },
      { val: 2, label: '2' },
      { val: 3, label: '3' },
      { val: 4, label: '4' },
      { val: 5, label: '5' },
      { val: 6, label: '6' },
      { val: 7, label: '7' },
      { val: 8, label: '8' },
      { val: 9, label: '9' },
      { val: 10, label: '10' },
    ];
    dispData.forEach(e => {
      e.color = otherSanpuHelper.objKeyToColor(otherSanpuHelper.dataToObjKey(e.val));
    });
    return { dispData };
  },
});
