export const getApiUrl = (...args: string[]): string => {
  if (args.length !== 1) {
    throw new Error('wrong number of arguments for utils.getApiUrl');
  }
  const path = args[0];
  return path;
};

export const transformFormData = (params: Record<string, any>): FormData => {
  const formData = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    // true', 'falseのままで送信するとサーバー側ではいずれもtrueになるため、
    // boolvalを利用する前提で'1', '0'で送信する。
    if (typeof value === 'boolean') {
      formData.append(key, value ? '1' : '0');
    } else {
      formData.append(key, value ?? '');
    }
  });
  return formData;
};
