











































































































































































































































































































































































































































































































































































































































import {
  ref,
  defineComponent,
  onMounted,
} from '@vue/composition-api';
import { BlockCodeNumInfo } from '@/models/johaisetsuCommon';
const areaNameList = ['t-west', 't-east', 'kanagawa'];
export default defineComponent({
  name: 'ensui-sanpu-joukyou-map',
  props: {
    area: {
      type: String,
      required: true,
      validator: (value: string) => {
        return ['t-west', 't-east', 'kanagawa', 'all'].indexOf(value) !== -1;
      },
    },
  },
  setup(props) {
    const svg = ref<SVGElement>();
    const initMapInner = () => {
      if (props.area === 'all') { return; }
      const areaNames = areaNameList.filter(e => e !== props.area);
      const kukanWrapperElems = svg.value?.querySelectorAll<HTMLElement>(areaNames.map(e => '#kukan-' + e).join(','));
      const numWrapperElems = svg.value?.querySelectorAll<HTMLElement>(areaNames.map(e => '#num-' + e).join(','));
      if (kukanWrapperElems) {
        kukanWrapperElems.forEach(e => { e.style.display = 'none'; });
      }
      if (numWrapperElems) {
        numWrapperElems.forEach(e => { e.style.display = 'none'; });
      }
    };
    const updateMapElement = (info: BlockCodeNumInfo) => {
      const numElemId = 'num-' + info.blockCode;
      const numElem = svg.value?.querySelector('#' + numElemId);

      if (!numElem) {
        console.warn(`element:${numElemId} not found...`);
        return;
      }
      const numElemSvg = numElem.querySelector<SVGTextElement>('text');
      if (numElemSvg) {
        numElemSvg.innerHTML = info.num.toString();
      }
    };
    const updateMapElements = (infos: BlockCodeNumInfo[]) => {
      infos.forEach(info => {
        updateMapElement(info);
      });
    };
    onMounted(async() => {
      initMapInner();
    });
    return {
      // refes
      svg,
      // methods
      initMapInner,
      updateMapElement,
      updateMapElements,
    };
  },
});
