import {
  CarExt,
  SettouPatrolReportExt,
  EnsuiPlantExt,
  CleaningCarExt,
} from '@/models/index';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { cleaningPhotoTypeOptions } from '@/components/CleaningMap/consts/cleaning_photo';
import { dtFormat } from './dateHelper';

export function getNormalCarPopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.device?.car_name}】</p>`;
  html += `
    <p class="info-row">
      <span class="lbl">状態:</span>
      <span class="val">${car.status_disp}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">車種：</span>
      <span class="val">${car.device?.carKindDisp}</span>
    </p>`;
  if (car.device?.is_publishing) {
    html += `
      <p class="btn-row">
        <button id="play-movie-button" class="btn btn-sm btn-success">
          <i class="fas fa-video"></i><span class="ib ml2">動画を再生</span>
        </button>
        <button id="stop-live-stream-button" class="btn btn-sm btn-info ml8">
          配信停止
        </button>
      </p>`;
  } else if (car.device?.is_publish_preparing) {
    html += `
      <p class="btn-row">
        <i class="fas fa-spinner fa-spin text-success"></i>
        <span class="text-success">配信準備中</span>
      </p>`;
  } else if (car.isMoving) {
    html += `
      <p class="btn-row">
        <button id="start-live-stream-button" class="btn btn-sm btn-info">
          配信開始
        </button>
      </p>`;
  }
  return html;
}
export function getExtCyzenPopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.username}】</p>`;
  html += `
    <p class="info-row">
      <span class="lbl">グループ:</span>
      <span class="val">${car.group_name}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">状態:</span>
      <span class="val">${car.status_disp}</span>
    </p>`;
  return html;
}
export function getExtSafiePopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.device?.car_name}】</p>`;
  if (car.device?.is_publishing) {
    html += `
      <p class="btn-row">
        <button id="play-movie-button" class="btn btn-sm btn-success">
          <i class="fas fa-video"></i><span class="ib ml2">動画を再生</span>
        </button>
        <button id="stop-live-stream-button" class="btn btn-sm btn-info ml8" disabled>
          配信停止
        </button>
      </p>`;
  } else {
    html += `
      <p class="btn-row">
        <button id="start-live-stream-button" class="btn btn-sm btn-info" disabled>
          配信開始
        </button>
      </p>`;
  }
  return html;
}
export function getExtJohaisetsuPopupContent_(car: CarExt): string {
  let html = `<p class="title">【${car.device?.car_name}】</p>`;
  if (!car.detail?.is_working) {
    html += `
      <p class="info-row">
        <span class="lbl">状態:</span>
        <span class="val">${car.status_disp}</span>
      </p>`;
    html += `
      <p class="info-row">
        <span class="lbl">車種：</span>
        <span class="val">${car.device?.carKindDisp}</span>
      </p>`;
  } else {
    html += `
      <p class="info-row lg">
        <span class="lbl">状態:</span>
        <span class="val">${car.detail?.status_disp}</span>
      </p>`;
    html += `
      <p class="info-row lg">
        <span class="lbl">車種：</span>
        <span class="val">${car.device?.carKindDisp}</span>
      </p>`;
    if (car.detail?.sanpu_num) {
      html += `
        <p class="info-row lg">
          <span class="lbl">散布回数:</span>
          <span class="val">${car.detail?.sanpu_num}回目</span>
        </p>`;
    }
    html += `
      <p class="info-row lg">
        <span class="lbl">備考:</span>
        <span class="val">${car.detail?.bikou1}</span>
      </p>`;
    if (car.detail?.is_editable) {
      html += `
        <p class="btn-row">
          <button id="edit-johaisetsu-car-button" class="btn btn-sm btn-info">
            編集
          </button>
        </p>`;
    }
  }
  return html;
}
export function getExternalCarPopupContent_(car: CarExt): string {
  let ret = '';
  if (car.external_type === 'cyzen') {
    ret = getExtCyzenPopupContent_(car);
  } else if (car.external_type === 'safie') {
    ret = getExtSafiePopupContent_(car);
  } else if (car.external_type === 'johaisetsu') {
    ret = getExtJohaisetsuPopupContent_(car);
  }
  return ret;
}
export function getNormalSettouPatrolReportPopupContent_(report: SettouPatrolReportExt): string {
  let html = `<p class="title">【${report.user_display_name}】</p>`;
  html += `
    <p class="info-row">
      <span class="lbl">日時:</span>
      <span class="val">${report.ts}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">天候:</span>
      <span class="val">${report.weather}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">気温：</span>
      <span class="val">${report.temperature ?? ''}&#8451;</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">路温：</span>
      <span class="val">${report.road_temperature ?? ''}&#8451;</span>
    </p>`;
  if (report.showDetailPageButton) {
    html += `
      <p class="btn-row">
        <button id="show-settou-patrol-report-detail-page-button" class="btn btn-sm btn-info">
          <i class="fas fa-external-link-alt"></i>
          詳細
        </button>
      </p>`;
  }
  return html;
}
export function getNormalEnsuiPlantPopupContent_(plant: EnsuiPlantExt): string {
  let html = `<p class="title">【${plant.name} 塩水プラント】</p>`;
  html += `
    <p class="info-row">
      <span class="val">${plant.stock_info.as_of} 現在</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">塩水:</span>
      <span class="val">${plant.stock_info.ensui_quantity}m&sup3;</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="lbl">塩ナト:</span>
      <span class="val">${plant.stock_info.enka_natoriumu_quantity}t</span>
    </p>`;
  return html;
}
export function getCarPopupContentInnerHtml(car: CarExt): string {
  return !car.is_external_car
    ? getNormalCarPopupContent_(car)
    : getExternalCarPopupContent_(car);
}
export function getSettouPatrolReportPopupContentInnerHtml(report: SettouPatrolReportExt): string {
  return getNormalSettouPatrolReportPopupContent_(report);
}
export function getEnsuiPlantPopupContentInnerHtml(plant: EnsuiPlantExt): string {
  return getNormalEnsuiPlantPopupContent_(plant);
}
export function getCleaningCarPopupContentInnerHtml(car: CleaningCarExt): string {
  let html = `<p class="title">【${car.car_name}】</p>`;
  html += `
    <p class="info-row">
      <span class="val">会社名:</span>
      <span class="val ml2">${car.cleaningCompanyName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業班:</span>
      <span class="val ml2">${car.cleaning_han_name}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">状態:</span>
      <span class="val ml2">${car.status_disp}</span>
    </p>`;
  if (!car.isMoving || !car.reportExt) {
    return html;
  }

  html += `
    <p class="info-row-wrap">
      <span >作業内容: ${car.reportExt.contentsDisp}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span>作業路線: ${car.reportExt.road_names}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">基地出発時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.base_departure_ts, 'HH:MM')}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業開始時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.cleaning_start_ts, 'HH:MM')}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業終了時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.cleaning_end_ts, 'HH:MM')}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">基地到着時刻:</span>
      <span class="val ml2">${dtFormat(car.reportExt.base_arrival_ts, 'HH:MM')}</span>
    </p>`;
  return html;
}
export function getCleaningReportPhotoPopupContentInnerHtml(photo: CleaningReportPhotoExt): string {
  const photoTypeDisp = cleaningPhotoTypeOptions().find(e => e.value === photo.photo_type)?.text ?? '';
  let html =
    `<p class="title">【${photoTypeDisp}写真${photo.disp_order}】</p>`;
  html += _getCleaningReportInfoContentInnerHtml(photo);
  html += `
    <div class="image-area" id="cleaning-map-photo${photo.id}">
      <div class="image-box">
        <div class="image-wrap">
          <img src="${photo.savedImage}" class="image" />
        </div>
      </div>
    </div>`;
  return html;
}
export function getCleaningMtxPopupContentInnerHtml(mtx: CleaningMtxExt): string {
  return _getCleaningReportInfoContentInnerHtml(mtx);
}
function _getCleaningReportInfoContentInnerHtml(info: CleaningReportPhotoExt | CleaningMtxExt): string {
  let html = `

    <p class="info-row">
      <span class="val">会社名:</span>
      <span class="val ml2">${info.cleaningCompanyName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業班:</span>
      <span class="val ml2">${info.cleaningHanName}</span>
    </p>`;
  html += `
    <p class="info-row">
      <span class="val">作業日時:</span>
      <span class="val ml2">${dtFormat(info.ts)}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span>作業内容: ${info.reportContents}</span>
    </p>`;
  html += `
    <p class="info-row-wrap">
      <span>作業路線: ${info.reportRoadNames}</span>
    </p>`;
  return html;
}
