











import {
  defineComponent,
} from '@vue/composition-api';
import OtherSoukouHelper from '@/lib/geo_item/other_soukouHelper';
import { DispData } from '@/models/geoItem';

export default defineComponent({
  name: 'legend-other-soukou',
  setup() {
    const otherSoukouHelper = new OtherSoukouHelper();
    const dispData: DispData[] = [
      { val: 1, label: '1' },
      { val: 2, label: '2' },
      { val: 3, label: '3' },
      { val: 5, label: '5' },
      { val: 10, label: '10' },
      { val: 20, label: '20' },
    ];
    dispData.forEach(e => {
      e.color = otherSoukouHelper.objKeyToColor(otherSoukouHelper.dataToObjKey(e.val));
    });
    return { dispData };
  },
});
