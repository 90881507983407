


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { UserActionTypes } from '@/store/modules/user';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import johaisetsuEnsuiPlantApi from '@/apis/johaisetsu_ensui_plant';
import johaisetsuEnsuiPlantStockHistoryApi from '@/apis/johaisetsu_ensui_plant_stock_history';
import { setValue } from '@/lib/utils';
import { EnsuiPlant } from '@/models/apis/ensui/johaisetsuEnsuiPlantResponse';
import { JohaisetsuEnsuiPlantParams } from '@/models/apis/ensui/johaisetsuEnsuiPlantRequest';
import { JohaisetsuEnsuiPlantStockHistoriesCreateParams } from '@/models/apis/johaisetsu/johaisetsuEnsuiPlantStockHistoriesRequest';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { useRoute } from '@/hooks/useRoute';

interface PlusMinusSignMap {
  plus: number;
  minus: number;
}
interface EnsuiPlantStockHistory {
  ensuiPlantId: string | null;
  enkaNatoriumuQuantityDiffSign: number | null;
  enkaNatoriumuQuantityDiff: number | null;
  ensuiQuantity: number | null;
}

interface EnsuiPlantStockReportState {
  isReady: boolean;
  isRequesting: boolean;

  ensuiPlants: EnsuiPlant[];
  plusMinusSignMap: PlusMinusSignMap;

  ensuiPlantStockHistory: EnsuiPlantStockHistory;

  errorObj: Record<string, boolean>;

  errorModalMsg: string;
  showErrorModal: boolean;
  showConfirmCreateModal: boolean;
  showCompleteCreateModal: boolean;
}

export default defineComponent({
  name: 'sp-ensui-plant-stock-report',
  setup() {
    const state = reactive<EnsuiPlantStockReportState>({
      isReady: false,
      isRequesting: false,

      ensuiPlants: [],
      plusMinusSignMap: {
        plus: 1,
        minus: -1,
      },

      ensuiPlantStockHistory: {
        ensuiPlantId: null,
        enkaNatoriumuQuantityDiffSign: null,
        enkaNatoriumuQuantityDiff: null,
        ensuiQuantity: null,
      },

      errorObj: {},

      errorModalMsg: '',
      showErrorModal: false,
      showConfirmCreateModal: false,
      showCompleteCreateModal: false,
    });
    const store = useStore();
    const userState = store.state.user;
    const displayName = computed<string>(() => {
      return userState.display_name;
    });
    const hasError = computed<boolean>(() => {
      return Object.values(state.errorObj).includes(true);
    });
    const selectedEnsuiPlant = computed<EnsuiPlant|undefined>(() => {
      return state.ensuiPlants.find(e => Number(e.id) === Number(state.ensuiPlantStockHistory.ensuiPlantId));
    });

    const initEnsuiPlantStockHistory = () => {
      const ensuiPlantId = selectedEnsuiPlant.value?.id ?? state.ensuiPlants[0].id;
      const obj = {
        ensuiPlantId: ensuiPlantId.toString(),
        enkaNatoriumuQuantityDiffSign: null,
        enkaNatoriumuQuantityDiff: null,
        ensuiQuantity: null,
      };
      state.ensuiPlantStockHistory = obj;
    };
    const getEnsuiPlants = async() => {
      try {
        const reqObj: JohaisetsuEnsuiPlantParams = { ts_to: new Date() };
        const { data } = await johaisetsuEnsuiPlantApi.index(reqObj);
        state.ensuiPlants = data;
      } catch (e) {
        console.error('error', e);
      }
    };
    const onEnkaNatoriumuQuantityDiffSignChange = () => {
      if (!state.ensuiPlantStockHistory.enkaNatoriumuQuantityDiffSign) {
        state.ensuiPlantStockHistory.enkaNatoriumuQuantityDiff = null;
      }
    };
    const onIntParamChange = (evt: Event, prop: keyof EnsuiPlantStockHistory) => {
      const val = (evt.target as HTMLInputElement).value;
      const shouldIgnoreInput = val && !val.match(/^\d*$/);
      (evt.target as HTMLInputElement).value = ((shouldIgnoreInput ? state.ensuiPlantStockHistory[prop] : val.trim()) || '').toString();
      setValue(state.ensuiPlantStockHistory, prop, (evt.target as HTMLInputElement).value);
    };
    const checkItems = () => {
      const input = state.ensuiPlantStockHistory;
      const errorObj: Record<string, boolean> = {};
      let isOk = true;
      const requiredParams = [];
      if (input.enkaNatoriumuQuantityDiffSign) {
        requiredParams.push('enkaNatoriumuQuantityDiff');
      }
      requiredParams.forEach(x => {
        const param = x as keyof EnsuiPlantStockHistory;
        errorObj[param] = input[param] === null || input[param] === '';

        isOk = isOk && !errorObj[param];
      });

      state.errorObj = Object.assign({}, errorObj);
      return isOk;
    };
    const tryShowConfirmCreateModal = () => {
      if (!checkItems()) { return; }
      state.showConfirmCreateModal = true;
    };
    const getParamsForReq = (data: EnsuiPlantStockHistory) => {
      const reqObj: JohaisetsuEnsuiPlantStockHistoriesCreateParams = {
        ensui_plant_id: Number(data.ensuiPlantId),
      };
      if (data.enkaNatoriumuQuantityDiffSign) {
        reqObj.enka_natoriumu_quantity_diff =
          (data.enkaNatoriumuQuantityDiff || 0) * data.enkaNatoriumuQuantityDiffSign;
      }
      if (data.ensuiQuantity) {
        reqObj.ensui_quantity = data.ensuiQuantity;
      }

      return reqObj;
    };
    const saveEnsuiPlantStockHistory = async() => {
      state.isRequesting = true;
      try {
        const reqObj = getParamsForReq(state.ensuiPlantStockHistory);
        await johaisetsuEnsuiPlantStockHistoryApi.create(reqObj);
        await getEnsuiPlants();
        state.isRequesting = false;
        initEnsuiPlantStockHistory();
        state.showConfirmCreateModal = false;
        state.showCompleteCreateModal = true;
      } catch (e) {
        state.isRequesting = false;
        state.showConfirmCreateModal = false;
        state.errorModalMsg = '保存に失敗しました。再度操作を行ってください。';
        state.showErrorModal = true;
      }
    };
    const logout = async() => {
      await store.dispatch(UserActionTypes.LOGOUT);
      // want to explicitly reload
      location.href = '/login';
    };
    const { route } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
      await getEnsuiPlants();

      initEnsuiPlantStockHistory();

      state.isReady = true;
    });
    return {
      ...toRefs(state),
      // computed
      displayName,
      hasError,
      selectedEnsuiPlant,
      // methods
      initEnsuiPlantStockHistory,
      onEnkaNatoriumuQuantityDiffSignChange,
      onIntParamChange,
      checkItems,
      tryShowConfirmCreateModal,
      getParamsForReq,
      saveEnsuiPlantStockHistory,
      logout,
    };
  },
});
