import { GeoItemMeta, GeoItemMetaContext } from '@/models/geoItem';
import { WaitSpinnerState } from '@/models/top';
import { GeoItemInner, ImageViewModalState, State as UseCommentState } from '@/models/useComment';
import { Tab } from '@/models/tab';
import { CommentIndexParams } from '@/models/apis/comment/commentRequest';
import { MapElemInfo, Location } from '@/models';
import GeoItemSearchComment from '@/components/Top/geoItemSearchComponents/GeoItemSearchComment.vue';

const initGeoItemMetaContextState = (): GeoItemMetaContext => {
  return {
    list: [],
    map: {},
    show: {},
    order: {},
    countsDisp: {},
  };
};
const initWaitSpinnerState = (): WaitSpinnerState => {
  return {
    show: true,
    msg: 'データロード中...',
    msgDefault: 'データロード中...',
    msgUpdating: '更新中...',
  };
};
const initImageViewModal = (): ImageViewModalState => {
  return {
    show: false,
    imageSrc: '',
    title: '',
    downloadFilenameTpl: '',
  };
};

export const getInitUseCommentState = (): UseCommentState => {
  return {
    tabs: [],
    defaultActiveTab: 'comment',
    commentUpdateTimer: null,
    geoItemMetaContext: initGeoItemMetaContextState(),
    mapSelectedElemCreateFailed: 0,
    mapSelectedElemUpdateFailed: 0,
    mapSelectedElemInfo: null,
    mapDeleteConfirmElem: null,
    waitSpinner: initWaitSpinnerState(),
    getGeoItemByIdFailed: 0,
    imageViewModal: initImageViewModal(),
  };
};

export const getCommentTab = (): Tab => {
  return {
    dataName: 'comment',
    label: '新着の付箋',
    resources: {
      ready: false,
      elements: [],
    },
  };
};

export const getCommentIndexParams = (): CommentIndexParams => {
  // 直近2週間の付箋
  const dayRange = 14;
  const now = new Date();
  return {
    ts_from: new Date(now.valueOf() - 86400 * (dayRange - 1) * 1000),
    ts_to: now,
  };
};

export const getNewObjForMapSelectedElem = (location: Location): MapElemInfo<Location> => {
  return {
    dataName: 'new',
    data: location,
  };
};

export const getGeoItemsInner = async(
  reqItems: GeoItemMeta[],
  gISearchcomment: Array<InstanceType<typeof GeoItemSearchComment>> | undefined,
): Promise<Array<GeoItemInner>> => {
  const promises = [];
  for (const item of reqItems) {
    const componentRef = item.name === 'comment' ? gISearchcomment : null;
    if (!componentRef) { continue; }
    const prms = item.manager.getResources(componentRef[0]).then(data => {
      return { item, data };
    });
    promises.push(prms);
  }
  return Promise.all(promises);
};
