


import {
  defineComponent,
  computed,
  onMounted,
  onBeforeUnmount,
  reactive,
  toRefs,
} from '@vue/composition-api';
import johaisetsuSettouPatrolReportApi from '@/apis/johaisetsu_settou_patrol_report';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { fetchImageAsObjectURL } from '@/lib/imageHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { RoadNameDirection } from '@/models/index';
import { SettouPatrolReportRoadConditionMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { SettouPatrolReport } from '@/models/apis/settou/settouPatrolReportsResponse';
import { SettouPatrolReportRaw } from '@/models/settouPatrol';
import { redirectIfNoAbility } from '@/lib/abilityHelper';

interface SettouPatrolReportDetailState {
  isReady: boolean;
  isRequesting: boolean;
  isDownloading: boolean;

  report: SettouPatrolReportRaw | null;

  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  // directions: [],
  roadConditions: SettouPatrolReportRoadConditionMap[];
  roadConditionMap: { [key: string]: SettouPatrolReportRoadConditionMap };

  showErrorModal: boolean;
  errorBody: string;
}
export default defineComponent({
  name: 'settou-patrol-report-detail',
  setup() {
    const state = reactive<SettouPatrolReportDetailState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,

      report: null,

      roadNames: [],
      roadNameMap: {},
      // directions: [],
      roadConditions: [],
      roadConditionMap: {},

      showErrorModal: false,
      errorBody: '',
    });

    const store = useStore();
    const userState = store.state.user;
    const { route } = useRoute();
    const id = computed<number>(() => {
      return Number(route.value.params.id);
    });
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady || state.isRequesting;
    });

    const convReport = async(data: SettouPatrolReport) => {
      let roadNameDisp = data.road_name_disp;
      const roadNameObj = state.roadNameMap[data.road_name_disp];
      if (roadNameObj) {
        roadNameDisp = roadNameObj.roadNameDisp;
      }

      let roadConditionText = '';
      const roadConditionObj = state.roadConditionMap[data.road_condition];
      if (roadConditionObj) {
        roadConditionText = roadConditionObj.text;
        const consitionsWithCm = [7, 8];
        if (consitionsWithCm.includes(parseInt(data.road_condition))) {
          roadConditionText = roadConditionText.replace(/\$\$/, data.snow_height.toString());
        }
      }

      data.hasImage = data.photos.length > 0;

      const prms: Promise<(string | null)>[] = [];
      data.photos.forEach(e => {
        prms.push(fetchImageAsObjectURL(e.image_path));
      });

      return {...data,
        ts: new Date(data.ts),
        roadNameDisp: roadNameDisp,
        roadConditionText: roadConditionText,
        hasImage: data.photos.length > 0,
        savedImages: await Promise.all(prms),
      };
    };
    const getReport = async() => {
      state.isRequesting = true;
      try {
        const { data } = await johaisetsuSettouPatrolReportApi.show(id.value);
        state.report = await convReport(data);
        state.isRequesting = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'データの取得に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isRequesting = false;
      }
    };
    const downloadImage = async(image: Blob, idx: number) => {
      state.isDownloading = true;
      try {
        const reportId = id.value;
        const dispOrder = idx + 1;
        const timestamp = dtFormat(new Date(), 'yyyymmddHHMMSS');
        const filename = `雪凍パトロール報告_${reportId}_写真_${dispOrder}_${timestamp}.jpg`;
        await downloadBlob(image, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = '写真のダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const releaseImageObjectURLs = () => {
      if (!state.report || !state.report.savedImages) {
        return;
      }
      state.report.savedImages.forEach(e => {
        if (e != null) {
          URL.revokeObjectURL(e);
        }
      });
    };

    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      await waitForJohaisetsuMasters();
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce(
        (acc: Record<string, RoadNameDirection>, e: RoadNameDirection) => { acc[e.roadNameReal] = e; return acc; }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;

      await getReport();

      state.isReady = true;
    });

    onBeforeUnmount(() => {
      releaseImageObjectURLs();
    });
    return {
      ...toRefs(state),
      // computed
      id,
      showWaitSpinner,
      // methods
      convReport,
      getReport,
      fetchImageAsObjectURL,
      downloadImage,
      releaseImageObjectURLs,
      dtFormat,
    };
  },
});
