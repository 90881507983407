import johaisetsuEnsuiPlantApi from '@/apis/johaisetsu_ensui_plant';

import { JohaisetsuEnsuiPlantParams } from '@/models/apis/ensui/johaisetsuEnsuiPlantRequest';
import { EnsuiPlant } from '@/models/apis/ensui/johaisetsuEnsuiPlantResponse';
import { EnsuiPlantExt } from 'src/models';
import { dtFormat } from '@/lib/dateHelper';

export interface JohaisetsuEnsuiPlantsParams {
  startTs: Date;
  endTs: Date;
}
export async function getJohaisetsuEnsuiPlants(params: JohaisetsuEnsuiPlantsParams): Promise<EnsuiPlantExt[]> {
  const reqObj = {
    ts_from: params.startTs,
    ts_to: params.endTs,
  };
  return getJohaisetsuEnsuiPlantsByParams(reqObj);
}
export async function getJohaisetsuEnsuiPlantsByParams(params: JohaisetsuEnsuiPlantParams): Promise<EnsuiPlantExt[]> {
  try {
    const { data } = await johaisetsuEnsuiPlantApi.index(params);
    return convJohaisetsuEnsuiPlants(data);
  } catch (e) {
    return [];
  }
}
export function convJohaisetsuEnsuiPlants(data: EnsuiPlant[]): EnsuiPlantExt[] {
  const result = data.map(e => {
    return {
      ...e,
      lat: parseFloat(e.lat),
      lon: parseFloat(e.lon),
      stock_info: (e.stock_info ? {
        ...e.stock_info,
        as_of: dtFormat(new Date(e.stock_info.as_of), 'yyyy/mm/dd HH:MM'),
      } : e.stock_info),
      isSelected: false,
    };
  });

  result.sort((a, b) => {
    return a.disp_order < b.disp_order ? -1 : a.disp_order > b.disp_order ? 1 : 0;
  });
  return result;
}
