





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  ref,
  defineComponent,
  onMounted,
} from '@vue/composition-api';
import { MapBlockInfo } from '@/models/johaisetsuCommon';
import { getMainlineElementIds } from '@/components/Johaisetsu/SagyouJoukyouMap/sagyouJoukyouMapHelper';

const mainlineDefaultClassNames = ['st0', 'st1', 'st2', 'st3', 'st4', 'st5'];
const gatewayDefaultClassNames = ['st6', 'st7', 'st8', 'st9', 'st10', 'st11'];
const mainlineStatusClassNames = ['mainline-0', 'mainline-10', 'mainline-20', 'mainline-30', 'mainline-40', 'mainline-50', 'mainline-60'];
const gatewayStatusClassNames = ['gateway-0', 'gateway-10', 'gateway-20', 'gateway-30', 'gateway-40', 'gateway-50', 'gateway-60'];

export default defineComponent({
  name: 'sagyou-joukyou-map',
  setup() {
    const svg = ref<SVGElement>();
    const initialClearMapInner = () => {
      const classNamesArr = [
        [mainlineDefaultClassNames, mainlineStatusClassNames],
        [gatewayDefaultClassNames, gatewayStatusClassNames],
      ];
      classNamesArr.forEach(([origClassNames, newClassNames]) => {
        const elems = svg.value?.querySelectorAll(
          origClassNames.map(e => '.' + e).join(','));
        if (elems) {
          elems.forEach(e => {
            e.classList.remove(...origClassNames);
            e.classList.add(newClassNames[0]);
          });
        }
      });
      setDefaults();
    };
    const clearMap = () => {
      const classNamesArr = [mainlineStatusClassNames, gatewayStatusClassNames];
      classNamesArr.forEach(classNames => {
        const elems = svg.value?.querySelectorAll(
          classNames.map(e => '.' + e).join(','));
        if (elems) {
          elems.forEach(e => {
            e.classList.remove(...classNames);
            e.classList.add(classNames[0]);
          });
        }
      });
      setDefaults();
    };
    const setDefaults = () => {
      // 北西線のトンネル部分の色を変えておく
      const hokuseiStuffs = [
        ['#hokusei-up-m-002', 'mainline-tunnel'],
        ['#hokusei-down-m-002', 'mainline-tunnel'],
      ];
      hokuseiStuffs.forEach(([elemId, className]) => {
        const elem = svg.value?.querySelector(elemId);
        if (elem) {
          elem.classList.add(className);
        }
      });
    };
    const updateMainlineElementInners = (info: MapBlockInfo) => {
      const elemIds = getMainlineElementIds(info);
      elemIds.forEach(elemId => {
        updateMainlineElementInner(elemId, info.status);
      });
    };
    const updateMainlineElementInner = (elemId: string, status: string) => {
      const elem = svg.value?.querySelector('#' + elemId);
      if (!elem) {
        console.warn(`mainline element:${elemId} not found...`);
        return;
      }
      elem.classList.remove(...mainlineStatusClassNames);
      elem.classList.add(`mainline-${status}`);
    };
    const updateGatewayElementInner = (info: MapBlockInfo) => {
      const elemId = info.name;
      const elem = svg.value?.querySelector('#' + elemId);
      if (!elem) {
        const knownIgnores = ['西新宿JCT'];
        if (!knownIgnores.includes(elemId)) {
          console.warn(`gateway element:${elemId} not found...`);
        }
        return;
      }
      elem.classList.remove(...gatewayStatusClassNames);
      elem.classList.add(`gateway-${info.status}`);
    };
    const updateMapElement = (info: MapBlockInfo) => {
      if (info.placeType === 'main_line') {
        updateMainlineElementInners(info);
      } else if (info.placeType && ['gateway', 'start', 'end'].includes(info.placeType)) {
        updateGatewayElementInner(info);
      } else {
        console.warn(`unknown map element type: ${info.placeType}. ignore.`);
        console.warn(info);
      }
    };
    const updateMapElements = (infos: MapBlockInfo[]) => {
      infos.forEach(info => {
        updateMapElement(info);
      });
    };

    onMounted(async() => {
      initialClearMapInner();
    });

    return {
      // refs
      svg,
      // methods
      clearMap,
      updateMapElement,
      updateMapElements,
    };
  },
});
