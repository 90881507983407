import {
  ABILITY_TOUKETSU_BOUSHI_INPUT,
  ABILITY_YUSETSU_INPUT,
  ABILITY_KISEI_INPUT,
  ABILITY_JOSETSU_INPUT,
  ABILITY_HAISETSU_INPUT,
  ABILITY_SETTOU_PATROL_REPORT_INPUT,
  ABILITY_ENSUI_PLANT_STOCK_INPUT,
  ABILITY_ENSUI_JUTEN_KARADANPU_INPUT,
  ABILITY_ENSUI_PLANT_STOCK_TABLE,
  ABILITY_ENSUI_JUTEN_KARADANPU_MAP,
  ABILITY_HAISETSU_KEISAN_INPUT,
  ABILITY_PROGRESS_TABLE,
  ABILITY_SAGYOU_JOUKYOU_INPUT,
  ABILITY_SAGYOU_JOUKYOU_MAP,
  ABILITY_SEKISETSU_INPUT,
  ABILITY_SETTOU_PATROL_REPORT_LIST,
  ABILITY_CLEANING_REPORT,
  ABILITY_CLEANING_MAP,
  JOHAISETSU_ABILITY_MIN_ID,
  JOHAISETSU_ABILITY_MAX_ID,
  CLEANING_ABILITY_MIN_ID,
  CLEANING_ABILITY_MAX_ID,
} from '@/consts/ability';
import { AdminAbility } from '@/models/apis/ability/adminAbilityResponse';

import { isRoleHonsha, isRoleKyoku, isRoleDriver, isDefaultRole } from '@/lib/roleHelper';
import { isJohaisetsuRoleHonsha, isJohaisetsuRoleDriver } from '@/lib/johaisetsuRoleHelper';
import { UserState } from '@/store/modules/user';
import { Route } from 'vue-router';

export function getAvailableDriverAbilities(): number[] {
  // johaisetsu_roleがドライバーの場合に選択可能な操作権限
  return [
    ABILITY_TOUKETSU_BOUSHI_INPUT,
    ABILITY_YUSETSU_INPUT,
    ABILITY_KISEI_INPUT,
    ABILITY_JOSETSU_INPUT,
    ABILITY_HAISETSU_INPUT,
    ABILITY_SETTOU_PATROL_REPORT_INPUT,
    ABILITY_ENSUI_PLANT_STOCK_INPUT,
  ];
}
export function getCleaningAvailableDriverAbilities(): number[] {
  // cleaning_roleがドライバーの場合に選択可能な操作権限
  return [
    ABILITY_CLEANING_REPORT,
  ];
}

const abilitiesFilter = (id: number, minId: number, maxId: number):boolean => {
  return id >= minId && id <= maxId;
};

export const filterJohaisetsuAbilities = (abilities: AdminAbility[]): AdminAbility[] => {
  return abilities.filter(e => abilitiesFilter(e.id, JOHAISETSU_ABILITY_MIN_ID, JOHAISETSU_ABILITY_MAX_ID));
};
export const filterCleaningAbilities = (abilities: AdminAbility[]): AdminAbility[] => {
  return abilities.filter(e => abilitiesFilter(e.id, CLEANING_ABILITY_MIN_ID, CLEANING_ABILITY_MAX_ID));
};

export function filterAbilitiesByJohaisetsuRole(abilitiesOrig: AdminAbility[], role: string | null): AdminAbility[] {
  let ret: AdminAbility[] = [];
  if (!role) { return ret; }

  const availableDriverAbilities = getAvailableDriverAbilities();
  const abilities = filterJohaisetsuAbilities(abilitiesOrig);
  if (isJohaisetsuRoleDriver(role)) {
    ret = abilities.filter(e => availableDriverAbilities.includes(e.id));
  } else {
    ret = abilities.filter(e => !availableDriverAbilities.includes(e.id));
  }

  return ret;
}

export function filterAbilitiesByCleaningRole(abilitiesOrig: AdminAbility[], role: string | null): AdminAbility[] {
  let ret: AdminAbility[] = [];
  if (!role) { return ret; }

  const availableDriverAbilities = getCleaningAvailableDriverAbilities();
  const abilities = filterCleaningAbilities(abilitiesOrig);
  if (
    isRoleHonsha(role) ||
    isRoleKyoku(role) ||
    isDefaultRole(role)
  ) {
    ret = abilities.filter(e => !availableDriverAbilities.includes(e.id));
  } else if (isRoleDriver(role)) {
    ret = abilities.filter(e => availableDriverAbilities.includes(e.id));
  }

  return ret;
}

const routeNameToRequiredAbilityName: Record<string, number> = {
  JohaisetsuEnsuiSanpuJoukyouInput: ABILITY_ENSUI_JUTEN_KARADANPU_INPUT,
  JohaisetsuJutenSanpuJoukyouInput: ABILITY_ENSUI_JUTEN_KARADANPU_INPUT,
  EnsuiPlantStockTable: ABILITY_ENSUI_PLANT_STOCK_TABLE,
  JohaisetsuEnsuiSanpuJoukyouMap: ABILITY_ENSUI_JUTEN_KARADANPU_MAP,
  JohaisetsuJutenSanpuJoukyouMap: ABILITY_ENSUI_JUTEN_KARADANPU_MAP,
  JohaisetsuHaisetsuKeisanInput: ABILITY_HAISETSU_KEISAN_INPUT,
  JohaisetsuProgressTable: ABILITY_PROGRESS_TABLE,
  JohaisetsuSagyouJoukyouInput: ABILITY_SAGYOU_JOUKYOU_INPUT,
  JohaisetsuSagyouJoukyouMap: ABILITY_SAGYOU_JOUKYOU_MAP,
  JohaisetsuSekisetsuInput: ABILITY_SEKISETSU_INPUT,
  JohaisetsuSettouPatrolReportInput: ABILITY_SETTOU_PATROL_REPORT_INPUT,
  SettouPatrolReportList: ABILITY_SETTOU_PATROL_REPORT_LIST,
  SettouPatrolReportDetail: ABILITY_SETTOU_PATROL_REPORT_LIST,
  SpEnsuiPlantStockReport: ABILITY_ENSUI_PLANT_STOCK_INPUT,
  SpSettouPatrolReport: ABILITY_SETTOU_PATROL_REPORT_INPUT,
  CleaningMap: ABILITY_CLEANING_MAP,
};

function isAbilityControlledPage(routeName: string): boolean {
  return Object.keys(routeNameToRequiredAbilityName).includes(routeName);
}

function isQualifiedForAbilityControlledPage(routeName: string, userState: UserState): boolean {
  const requiredAbility = routeNameToRequiredAbilityName[routeName];
  return userState.abilityMap[requiredAbility] !== undefined;
}

function isJohaisetsuTaskForcePage(routeName: string): boolean {
  return routeName === 'JohaisetsuTaskForce';
}

function isQualifiedForJohaisetsuTaskForcePage(userState: UserState): boolean {
  return isJohaisetsuRoleHonsha(userState.johaisetsu_role);
}

function isGeneralPage(routeName: string): boolean {
  return routeName === 'Route' || routeName === 'Top';
}

export function isQualifiedForGeneralPage(userState: UserState): boolean {
  return !userState.is_johaisetsu_only && !userState.is_cleaning_only;
}

export function redirectIfNoAbility(userState: UserState, route: Route): void {
  if (!route.name) {
    return;
  }

  let redirectTo = '/';
  if (isAbilityControlledPage(route.name)) {
    if (isQualifiedForAbilityControlledPage(route.name, userState)) {
      return;
    }
  } else if (isJohaisetsuTaskForcePage(route.name)) {
    if (isQualifiedForJohaisetsuTaskForcePage(userState)) {
      return;
    }
  } else if (isGeneralPage(route.name)) {
    if (isQualifiedForGeneralPage(userState)) {
      return;
    } else {
      // TopやRouteさえ見れないユーザーはloginへ飛ばす
      redirectTo = '/login';
    }
  }

  location.href = redirectTo;
}
