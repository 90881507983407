import { Style, Stroke } from 'ol/style';
import { DataContent } from '@/models/geoItem';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
export default class OtherSanpuHelper extends AbstractGeoItem<number, number, number, string> {
  dataToObjKey(inputData: number): number {
    let data: DataContent = { v: Number.MAX_VALUE };
    if (typeof inputData !== 'object') { data = { v: inputData }; }

    return parseInt(data.v.toString());
  }
  objKeyToColor(intputObjKey: number): string {
    const objKey = parseInt(intputObjKey.toString());
    let ret = '#b1b1b1';
    const x = objKey;
    if (x < 2) {
      // 1 <= x < 2
      ret = '#ee8383';
    } else if (x < 3) {
      // 2 <= x < 3
      ret = '#4274b1';
    } else if (x < 4) {
      // 3 <= x < 4
      ret = '#75fc4c';
    } else if (x < 5) {
      // 4 <= x < 5
      ret = '#c49b33';
    } else if (x < 6) {
      // 5 <= x < 6
      ret = '#ea3323';
    } else if (x < 7) {
      // 6 <= x < 7
      ret = '#5e813f';
    } else if (x < 8) {
      // 7 <= x < 8
      ret = '#ed702d';
    } else if (x < 9) {
      // 8 <= x < 9
      ret = '#75fbfd';
    } else if (x < 10) {
      // 9 <= x < 10
      ret = '#8c1a11';
    } else {
      // 10 <= x
      ret = '#ffff54';
    }
    return ret;
  }
  objKeyToFeatureStyle(objKey: number): Style {
    const color = this.objKeyToColor(objKey);
    const width = 6;

    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    });
    return style;
  }
}
