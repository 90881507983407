


import {
  defineComponent,
  ref,
} from '@vue/composition-api';
import SettouSagyouCommon from '@/components/Sp/SettouSagyou/SettouSagyouCommon/index.vue';
import { ABILITY_HAISETSU_INPUT } from '@/consts/ability';
import { JOHAISETSU_TYPE_GROUP_HAISETSU_SAGYOU } from '@/consts/johaisetsu_car';

export interface SettouSagyouHaisetsuState {
  ability: number;
  johaisetsuTypeGroup: string;
  johaisetsuTypeGroupName: string;
}
export default defineComponent({
  name: 'sp-settou-sagyou-haisetsu',
  components: { SettouSagyouCommon },
  setup() {
    const ability = ref(ABILITY_HAISETSU_INPUT);
    const johaisetsuTypeGroup = ref(JOHAISETSU_TYPE_GROUP_HAISETSU_SAGYOU);
    const johaisetsuTypeGroupName = ref('排雪作業');

    return {
      ability,
      johaisetsuTypeGroup,
      johaisetsuTypeGroupName,
    };
  },
});
