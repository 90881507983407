
import LegendTemperature from '@/components/lib/dataLayerLegend/legendTemperature.vue';
import LegendSalinity from '@/components/lib/dataLayerLegend/legendSalinity.vue';
import LegendSnowfall from '@/components/lib/dataLayerLegend/legendSnowfall.vue';
import LegendFrozen from '@/components/lib/dataLayerLegend/legendFrozen.vue';
import LegendStallRiskPoint from '@/components/lib/dataLayerLegend/legendStallRiskPoint.vue';
import LegendSnowMountain from '@/components/lib/dataLayerLegend/legendSnowMountain.vue';
import LegendJhWorkStatus from '@/components/lib/dataLayerLegend/legendJhWorkStatus.vue';
import LegendTairyu from '@/components/lib/dataLayerLegend/legendTairyu.vue';
import LegendEnsuiSanpu from '@/components/lib/dataLayerLegend/legendEnsuiSanpu.vue';
import LegendShitsuenSanpu from '@/components/lib/dataLayerLegend/legendShitsuenSanpu.vue';
import LegendKokeizaiSanpu from '@/components/lib/dataLayerLegend/legendKokeizaiSanpu.vue';
import LegendKyokushoSanpu from '@/components/lib/dataLayerLegend/legendKyokushoSanpu.vue';
import LegendOtherSanpu from '@/components/lib/dataLayerLegend/legendOtherSanpu.vue';
import LegendSweeperSoukou from '@/components/lib/dataLayerLegend/legendSweeperSoukou.vue';
import LegendOtherSoukou from '@/components/lib/dataLayerLegend/legendOtherSoukou.vue';
import LegendJosetsu from '@/components/lib/dataLayerLegend/legendJosetsu.vue';

export default {
  LegendTemperature,
  LegendSalinity,
  LegendSnowfall,
  LegendFrozen,
  LegendStallRiskPoint,
  LegendSnowMountain,
  LegendJhWorkStatus,
  LegendTairyu,
  LegendEnsuiSanpu,
  LegendShitsuenSanpu,
  LegendKokeizaiSanpu,
  LegendKyokushoSanpu,
  LegendOtherSanpu,
  LegendSweeperSoukou,
  LegendOtherSoukou,
  LegendJosetsu,
};
