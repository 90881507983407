


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { EnsuiPlant } from '@/models/apis/ensui/johaisetsuEnsuiPlantResponse';
import { JohaisetsuEnsuiPlantParams } from '@/models/apis/ensui/johaisetsuEnsuiPlantRequest';
import { Area } from '@/models/apis/johaisetsu/johaisetsuCommon';
import johaisetsuEnsuiPlantApi from '@/apis/johaisetsu_ensui_plant';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { useRoute } from '@/hooks/useRoute';
import { getKyokuIdByJohaisetsuRole } from '@/lib/johaisetsuRoleHelper';

interface SearchParams {
  area: string;
  stockHistoryTsToDate: Date;
  stockHistoryTsToHour: number;
}

interface EnsuiPlantStockTableState {
  isReady: boolean;
  isRequesting: boolean;
  isDownloading: boolean;

  searchParams: SearchParams;

  searchHourChoices: {
    hourNum: number;
    hourDisp: string;
  }[];

  areas: Area[];
  ensuiPlants: EnsuiPlant[];
  hourLabels: string[];

  showErrorModal: boolean;
  errorBody: string;
}

const ONE_DAY_IN_SECONDS = 86400;
const MAX_SEARCH_DATE_RANGE = 180; // 6ヶ月前まで

export default defineComponent({
  name: 'ensui-plant-stock-table',
  setup() {
    const now = new Date();
    const state = reactive<EnsuiPlantStockTableState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,

      searchParams: {
        area: '',
        stockHistoryTsToDate: now,
        stockHistoryTsToHour: now.getHours(),
      },

      searchHourChoices: Array.from({ length: 24 }, (_, i) => {
        return {
          hourNum: i,
          hourDisp: `${('0' + i).slice(-2)}:00`,
        };
      }),

      areas: [],
      ensuiPlants: [],
      hourLabels: [],

      showErrorModal: false,
      errorBody: '',
    });
    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady || state.isRequesting;
    });

    const getAreas = () : Area[] => {
      const origAreas: Area[] = [
        { id: 't-west', name: '西局' },
        { id: 't-east', name: '東局' },
        { id: 'kanagawa', name: '神局' },
      ];
      const areaAll: Area = { id: 'all', name: '全て' };

      var areas: Area[] = [];
      let areasForSelect: Area[] = [];
      if (johaisetsuRole.value === 'honsha') {
        areas = origAreas.slice();
        areasForSelect = [areaAll, ...areas];
      } else {
        areas = origAreas.filter(e => johaisetsuRole.value === e.id);
        areasForSelect = areas.slice();
      }
      return areasForSelect;
    };
    const initSearchParams = () => {
      state.searchParams = {
        area: state.areas.length > 0 ? state.areas[0].id : '',
        stockHistoryTsToDate: state.searchParams.stockHistoryTsToDate,
        stockHistoryTsToHour: state.searchParams.stockHistoryTsToHour,
      };
    };
    const isValidSearchDateParams = computed<boolean>(() => {
      const params = state.searchParams;
      const now = new Date();
      now.setMinutes(0, 0, 0);
      const tsTo = params.stockHistoryTsToDate;
      tsTo.setHours(params.stockHistoryTsToHour, 0, 0, 0);
      const timeDiffFromNowMSec = now.getTime() - tsTo.getTime();
      const searchDateRangeThres = (MAX_SEARCH_DATE_RANGE - 1) * ONE_DAY_IN_SECONDS * 1000;
      const isWithinSearchDateRange = timeDiffFromNowMSec <= searchDateRangeThres;
      return (tsTo <= now) && isWithinSearchDateRange;
    });
    const getSearchParamsForReq = () => {
      const params = state.searchParams;
      const tsTo = params.stockHistoryTsToDate;
      tsTo.setHours(params.stockHistoryTsToHour + 1, 0, 0, 0);
      const tsFrom = new Date(tsTo.getTime() - ONE_DAY_IN_SECONDS * 1000);
      const reqObj: JohaisetsuEnsuiPlantParams = {
        ts_from: tsFrom,
        ts_to: tsTo,
        with_stock_histories: 1,
      };
      if (params.area !== 'all') {
        reqObj.area = params.area;
      }
      return reqObj;
    };
    const getHourLabels = (ensuiPlants: EnsuiPlant[]) => {
      if (ensuiPlants.length === 0) { return []; }
      const ep = ensuiPlants[0];
      return ep.stock_histories.map(e => dtFormat(e.ts, 'HH:00'));
    };
    const getEnsuiPlants = async() => {
      if (!isValidSearchDateParams.value) { return; }

      state.isRequesting = true;
      try {
        const reqObj = getSearchParamsForReq();
        const { data } = await johaisetsuEnsuiPlantApi.index(reqObj);
        state.ensuiPlants = data;
        state.hourLabels = getHourLabels(state.ensuiPlants);
        state.isRequesting = false;
      } catch (e) {
        console.error('error', e);
        state.isRequesting = false;
      }
    };
    const downloadCSV = async() => {
      state.isDownloading = true;
      try {
        const reqObj = getSearchParamsForReq();
        const { data } = await johaisetsuEnsuiPlantApi.downloadCSV(reqObj);
        const timestamp = dtFormat(new Date(), 'yyyymmdd_HHMMSS');
        const filename = `塩水プラント在庫状況_${timestamp}`;
        downloadBlob(data, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'CSVダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const quantityDiffDisp = (num: number): string => {
      let ret = `${num}`;
      if (num === 0) {
        ret = '-';
      } else if (num > 0) {
        ret = `+${num}`;
      }
      return ret;
    };

    const { route } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      state.areas = getAreas();
      initSearchParams();
      await getEnsuiPlants();

      state.isReady = true;
    });

    return {
      ...toRefs(state),
      // computed
      johaisetsuRole,
      showWaitSpinner,
      isValidSearchDateParams,
      // methods
      getAreas,
      initSearchParams,
      getSearchParamsForReq,
      getHourLabels,
      getEnsuiPlants,
      downloadCSV,
      quantityDiffDisp,
    };
  },
});
