import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { EmptyResponse } from '@/models/index';
import { MessageBoardResponse, MessageBoardBase } from '@/models/apis/messageBoard/messageBoardResponse';
export default {
  index(): Promise<AxiosResponse<MessageBoardResponse>> {
    return axios.get(utils.getApiUrl('/board_messages'));
  },
  create(params: MessageBoardBase): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/board_messages'), params);
  },
  update(id: number, params: MessageBoardBase): Promise<AxiosResponse<EmptyResponse>> {
    return axios.put(utils.getApiUrl(`/board_messages/${id}`), params || {});
  },
  destroy(id: number): Promise<AxiosResponse<EmptyResponse>> {
    return axios.delete(utils.getApiUrl(`/board_messages/${id}`));
  },
};
