import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { JohaisetsuEnsuiPlantStockHistoriesCreateParams } from '@/models/apis/johaisetsu/johaisetsuEnsuiPlantStockHistoriesRequest';
import { JohaisetsuEnsuiPlantStockHistoriesCreateResponse } from '@/models/apis/johaisetsu/johaisetsuEnsuiPlantStockHistoriesResponse';

export default {
  create(params: JohaisetsuEnsuiPlantStockHistoriesCreateParams): Promise<AxiosResponse<JohaisetsuEnsuiPlantStockHistoriesCreateResponse>> {
    return axios.post(utils.getApiUrl(`/johaisetsu_ensui_plant_stock_histories`), params || {});
  },
};
