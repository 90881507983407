


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { Pagination } from '@/models/index';
import { TaskForce } from '@/models/apis/taskForce/taskForceResponse';

import { dtFormat } from '@/lib/dateHelper';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import johaisetsuTaskForceApi from '@/apis/johaisetsu_task_force';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { useRoute } from '@/hooks/useRoute';
import { getKyokuIdByJohaisetsuRole } from '@/lib/johaisetsuRoleHelper';

interface TaskForceEdit {
  id?: number;
  name: string | null;
  start_date?: Date | null;
  end_date?: Date | null;
  isNew?: boolean;
}

interface JohaisetsuTaskForce {
  isReady: boolean;

  taskForces: TaskForce[];
  edittingTaskForceOrig?: TaskForceEdit;
  edittingTaskForce: TaskForceEdit;
  deletingTaskForce: TaskForce | null;

  showEditModal: boolean;
  showEditClearConfirmModal: boolean;
  showDeleteModal: boolean;
  showErrorModal: boolean;
  errorBody: string;

  pager: Pagination;
}

export default defineComponent({
  name: 'johaisetsu-task-force',
  setup() {
    const state = reactive<JohaisetsuTaskForce>({
      isReady: false,

      taskForces: [],
      edittingTaskForce: {
        name: null,
        start_date: null,
        end_date: null,
      },
      deletingTaskForce: null,

      showEditModal: false,
      showEditClearConfirmModal: false,
      showDeleteModal: false,
      showErrorModal: false,
      errorBody: '',

      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },
    });
    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady;
    });
    const paginatedTaskForces = computed<TaskForce[]>(() => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      return state.taskForces.filter((e, i) => {
        return i >= minIdx && i < nextPageIdx;
      });
    });
    const shouldShowEditModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      if (state.showEditClearConfirmModal) { return false; }
      return state.showEditModal;
    });
    const shouldShowDeleteModal = computed<boolean>(() => {
      if (state.showErrorModal) { return false; }
      return state.showDeleteModal;
    });
    const isReadyToSave = computed<boolean>(() => {
      return checkInputs();
    });

    const getTaskForces = async() => {
      state.isReady = false;
      const { data } = await johaisetsuTaskForceApi.index();
      state.taskForces = data;
      state.isReady = true;
    };
    const openEditModal = (item?: TaskForce) => {
      const obj: TaskForceEdit = {
        ...(item ? {id: item.id} : null),
        name: item?.name ?? null,
        start_date: item?.start_date ?? new Date(),
        end_date: item?.end_date ?? null,
        isNew: !item || !item.id,
      };
      prepareOrigValues(obj);
      state.edittingTaskForce = obj;
      state.showEditModal = true;
    };
    const tryCloseEditModal = () => {
      if (isEdited()) {
        state.showEditClearConfirmModal = true;
      } else {
        state.showEditModal = false;
      }
    };
    const closeEditModal = () => {
      state.edittingTaskForce = {} as TaskForceEdit;
      state.showEditClearConfirmModal = false;
      state.showEditModal = false;
    };
    const getTaskForceForReq = (taskForce: TaskForceEdit) => {
      const reqObj = JSON.parse(JSON.stringify(taskForce));

      reqObj.start_date = dtFormat(taskForce.start_date, 'yyyy-mm-dd');
      reqObj.end_date = dtFormat(taskForce.end_date, 'yyyy-mm-dd');

      return reqObj;
    };
    const saveTaskForce = async() => {
      try {
        const reqObj = getTaskForceForReq(state.edittingTaskForce);
        if (state.edittingTaskForce.isNew) {
          await johaisetsuTaskForceApi.create(reqObj);
        } else {
          const taskForceId = state.edittingTaskForce.id;
          if (!taskForceId) {
            return;
          }
          await johaisetsuTaskForceApi.update(taskForceId, reqObj);
        }
        await getTaskForces();
        closeEditModal();
      } catch (e) {
        console.error('error', e);
        state.errorBody = '削除に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const openDeleteModal = (item: TaskForce) => {
      state.deletingTaskForce = item;
      state.showDeleteModal = true;
    };
    const closeDeleteModal = () => {
      state.deletingTaskForce = {} as TaskForce;
      state.showDeleteModal = false;
    };
    const deleteTaskForce = async() => {
      try {
        if (state.deletingTaskForce == null) return;
        await johaisetsuTaskForceApi.destroy(state.deletingTaskForce.id);
        await getTaskForces();
        closeDeleteModal();
      } catch (e) {
        console.error('error', e);
        state.errorBody = '削除に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const prepareOrigValues = (obj: TaskForceEdit) => {
      state.edittingTaskForceOrig = obj;
    };
    const checkInputs = () => {
      const obj = state.edittingTaskForce;
      if (
        !obj.name ||
        !obj.start_date
      ) {
        return false;
      }
      if (obj.name.match(/^\s*$/)) {
        return false;
      }

      return true;
    };
    const isEdited = () => {
      const obj = state.edittingTaskForce;
      const objOrig = state.edittingTaskForce;

      if (obj.name !== objOrig.name) return true;
      if (obj.start_date !== objOrig.start_date) return true;
      if (obj.end_date !== objOrig.end_date) return true;
      return false;
    };
    const { route } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);
      await getTaskForces();

      state.isReady = true;
    });
    return {
      ...toRefs(state),
      // computed
      johaisetsuRole,
      showWaitSpinner,
      paginatedTaskForces,
      shouldShowEditModal,
      shouldShowDeleteModal,
      isReadyToSave,
      // methods
      getTaskForces,
      openEditModal,
      tryCloseEditModal,
      closeEditModal,
      getTaskForceForReq,
      saveTaskForce,
      openDeleteModal,
      closeDeleteModal,
      deleteTaskForce,
      dtFormat,
    };
  },
});
