import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import { JohaisetsuCarCreateOrUpdateDetailParams, JohaisetsuCarCreateOrUpdateParams } from '@/models/apis/johaisetsu/johaisetsuCarRequest';
import { JohaisetsuCarShowResponse, JohaisetsuCarCreateOrUpdateDetailResponse, JohaisetsuCarCreateOrUpdateResponse } from '@/models/apis/johaisetsu/johaisetsuCarResponse';

export default {
  show(deviceId: string): Promise<AxiosResponse<JohaisetsuCarShowResponse>> {
    return axios.get(utils.getApiUrl(`/johaisetsu_cars/${deviceId}`));
  },
  // SP画面から新規作成or更新
  createOrUpdate(params: JohaisetsuCarCreateOrUpdateParams): Promise<AxiosResponse<JohaisetsuCarCreateOrUpdateResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_cars'), params || {});
  },
  createOrUpdateDetail(deviceId: string, params: JohaisetsuCarCreateOrUpdateDetailParams): Promise<AxiosResponse<JohaisetsuCarCreateOrUpdateDetailResponse>> {
    return axios.post(utils.getApiUrl(`/johaisetsu_cars/${deviceId}/detail`), params || {});
  },
  // PC画面から更新
  updateDetail(deviceId: string, params: JohaisetsuCarCreateOrUpdateDetailParams): Promise<AxiosResponse<JohaisetsuCarCreateOrUpdateDetailResponse>> {
    return axios.put(utils.getApiUrl(`/johaisetsu_cars/${deviceId}/detail`), params || {});
  },
};
