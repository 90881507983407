

























import {
  defineComponent,
  toRefs,
  reactive,
  ref,
} from '@vue/composition-api';
import axios from 'axios';
import { useStore } from '@/hooks/useStore';
import { LocalStorageActionTypes } from '@/store/modules/localStorage';
import { UserActionTypes } from '@/store/modules/user';

interface LoginState {
 loginForm: {
    username: string;
    password: string;
  };
  showErrorMessage: boolean;
}

export default defineComponent({
  name: 'login',
  setup() {
    const state = reactive<LoginState>({
      loginForm: {
        username: '',
        password: '',
      },
      showErrorMessage: false,
    });
    // methods
    // iOSのchromeだとautofillでchangeが効かないぽいのでがんばる
    const textInputUsername = ref<HTMLInputElement>();
    const textInputPassword = ref<HTMLInputElement>();

    const ensureLoginFormValuesSynced = () => {
      const username = textInputUsername.value?.value;
      const password = textInputPassword.value?.value;
      state.loginForm.username = username || '';
      state.loginForm.password = password || '';
    };
    const store = useStore();
    const tryLogin = async() => {
      state.showErrorMessage = false;
      ensureLoginFormValuesSynced();

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers['Authorization'];

      try {
        const res = await store.dispatch(UserActionTypes.LOGIN, state.loginForm);
        await store.dispatch(LocalStorageActionTypes.SET, {
          key: 'serverToken',
          val: res['access_token'],
        });
        const defaultPage = res.user.default_page;
        if (res.user.is_johaisetsu_only) {
          location.href = '/settou-message-board';
        } else if (res.user.is_cleaning_only) {
          location.href = '/cleaning-map';
        } else if (defaultPage === 'sp_top') {
          location.href = '/sp';
        } else if (defaultPage === 'johaisetsu_sp_touketsu_boushi') {
          location.href = '/sp/touketsu-boushi';
        } else if (defaultPage === 'johaisetsu_sp_yusetsu') {
          location.href = '/sp/yusetsu';
        } else if (defaultPage === 'johaisetsu_sp_kisei') {
          location.href = '/sp/kisei';
        } else if (defaultPage === 'johaisetsu_sp_josetsu') {
          location.href = '/sp/josetsu';
        } else if (defaultPage === 'johaisetsu_sp_haisetsu') {
          location.href = '/sp/haisetsu';
        } else if (defaultPage === 'johaisetsu_sp_settou_patrol_report') {
          location.href = '/sp/settou-patrol-report';
        } else if (defaultPage === 'johaisetsu_sp_ensui_plant_stock_report') {
          location.href = '/sp/ensui-plant-stock-report';
        } else if (defaultPage === 'cleaning_map') {
          location.href = '/cleaning';
        } else if (defaultPage === 'cleaning_sp_report') {
          location.href = '/sp/cleaning-report';
        } else {
          location.href = '/';
        }
      } catch (e) {
        state.showErrorMessage = true;
      }
    };

    return {
      ...toRefs(state),
      // refs
      textInputUsername,
      textInputPassword,
      // methods
      ensureLoginFormValuesSynced,
      tryLogin,
    };
  },
});
