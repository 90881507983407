


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { UserActionTypes } from '@/store/modules/user';

import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import johaisetsuSettouPatrolReportApi from '@/apis/johaisetsu_settou_patrol_report';
import { enableNoSleep, disableNoSleep } from '@/lib/noSleepUtil';
import { RoadNameDirection, Location, Position, GeolocationPositionError, Direction } from '@/models/index';
import { SettouPatrolReportRoadConditionMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { GeolocationOpts } from '@/models/geoItem';
import { SpSettouPatrolReport, SpSettouPatrolReportExt } from '@/models/apis/settou/settouPatrolReportsRequest';
import { setValue } from '@/lib/utils';
import { useRoute } from '@/hooks/useRoute';
import { redirectIfNoAbility } from '@/lib/abilityHelper';

interface SettouPatrolReportState {
  isReady: boolean;
  isRequesting: boolean;

  currentLocation: Location | null;
  geolocationErrors: GeolocationPositionError[];
  geolocationOpts: GeolocationOpts;

  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  directions: Direction[];
  roadConditions: SettouPatrolReportRoadConditionMap[];
  roadConditionMap: { [key: string]: SettouPatrolReportRoadConditionMap };
  weathers: string[];

  settouPatrolReport: SpSettouPatrolReport;

  errorObj: Record<string, boolean>;

  showGeolocationGeneralErrorMsg: boolean;
  showGeolocationPermissionErrorModal: boolean;

  errorModalMsg: string;
  showErrorModal: boolean;
  showConfirmCreateModal: boolean;
  showCompleteCreateModal: boolean;

  geolocationWatchHandler: number | null;
}
export default defineComponent({
  name: 'sp-settou-patrol-report',
  setup() {
    const state = reactive<SettouPatrolReportState>({
      isReady: false,
      isRequesting: false,

      currentLocation: null,
      geolocationErrors: [],
      geolocationOpts: {
        enableHighAccuracy: true,
        timeout: 5 * 1000,
        maximumAge: 0,
      },

      roadNames: [],
      roadNameMap: {},
      directions: [],
      roadConditions: [],
      roadConditionMap: {},
      weathers: [
        '晴れ',
        'くもり',
        '雨',
        '小雨',
        '雪',
        '小雪',
        'みぞれ',
      ],

      settouPatrolReport: {
        lat: null,
        lon: null,
        roadNameDisp: null,
        direction: null,
        kp: null,
        roadCondition: null,
        snowHeight: null,
        weather: null,
        temperature: null,
        roadTemperature: null,
        bikou1: null,
        imageCandidates: [],
      },

      errorObj: {},

      showGeolocationGeneralErrorMsg: false,
      showGeolocationPermissionErrorModal: false,

      errorModalMsg: '',
      showErrorModal: false,
      showConfirmCreateModal: false,
      showCompleteCreateModal: false,

      geolocationWatchHandler: null,
    });
    const store = useStore();
    const userState = store.state.user;
    const displayName = computed<string>(() => {
      return userState.display_name;
    });

    const hasError = computed<boolean>(() => {
      return Object.values(state.errorObj).includes(true);
    });
    const isGettingCurrentLocation = computed<boolean>(() => {
      return !!state.geolocationWatchHandler;
    });

    const clearGeolocationErrors = () => {
      state.geolocationErrors = [];
    };
    const clearGeolocationWatch = () => {
      if (state.geolocationWatchHandler) {
        navigator.geolocation.clearWatch(state.geolocationWatchHandler);
      }
      state.geolocationWatchHandler = null;
      clearGeolocationErrors();
      state.currentLocation = null;
    };
    const setCurrentLocation = (position: Position) => {
      console.log('lat: ', position.coords.latitude, 'lon: ', position.coords.longitude);
      let { latitude, longitude } = position.coords;
      // 小数点第6位まで
      const base = 1000000;
      latitude = Math.floor(latitude * base) / base;
      longitude = Math.floor(longitude * base) / base;
      state.currentLocation = { lat: latitude, lon: longitude };

      // 取得に成功したらエラーを消す
      clearGeolocationErrors();
    };
    const onGeolocationError = (evt: GeolocationPositionError) => {
      console.error('geolocation error', evt);
      const code = evt.code;
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
      // 1 PERMISSION_DENIED
      // 2 POSITION_UNAVAILABLE
      // 3 TIMEOUT
      if (code === 1) {
        // 位置情報の利用が許可されていない場合
        stopGettingCurrentLocation();
        state.showGeolocationPermissionErrorModal = true;
      } else {
        // 位置情報の取得に失敗した場合

        // エラーの内容が1(PERMISSION_DENIED)以外であれば、
        // 位置情報の利用自体は許可されているはず.
        state.geolocationErrors.push(evt);
        if (state.geolocationErrors.length > 5) {
          // エラーが何回か続いたら、画面下にエラーメッセージ出す
          state.showGeolocationGeneralErrorMsg = true;
        }
      }
    };
    const startGettingCurrentLocation = () => {
      clearGeolocationWatch();
      state.geolocationWatchHandler = navigator.geolocation.watchPosition(
        setCurrentLocation,
        onGeolocationError,
        state.geolocationOpts,
      );
    };
    const stopGettingCurrentLocation = () => {
      clearGeolocationWatch();
    };
    const initSettouPatrolReport = () => {
      const obj = {
        lat: null,
        lon: null,
        roadNameDisp: null,
        direction: null,
        kp: null,
        roadCondition: state.roadConditions[0].key,
        snowHeight: null,
        weather: state.weathers[0],
        temperature: null,
        roadTemperature: null,
        bikou1: null,
        imageCandidates: [],
      };
      state.settouPatrolReport = obj;
    };
    const onRoadNameChange = () => {
      const selectedRoadNameDisp = state.settouPatrolReport.roadNameDisp || '';
      const roadNameObj = state.roadNameMap[selectedRoadNameDisp];
      state.directions = roadNameObj ? roadNameObj.directions : [];
      state.settouPatrolReport.direction = null;
    };

    const onFloatParamChange = (evt: Event, inputProp: string, gt: number, lt: number) => {
      const val = (evt.target as HTMLInputElement).value;
      const isPartial = !val || !!val.match(/^(\+|-)?(\.)?$/);
      const shouldCheckInput = !isPartial;
      const prop = inputProp as keyof SpSettouPatrolReport;
      if (shouldCheckInput) {
        const isNumeric = !isNaN(Number(val));
        const isWithinRange = parseFloat(val) > gt && parseFloat(val) < lt;
        const isDecimalPlaceOk = !val.match(/(\.\d{3,})$/);
        const shouldIgnoreInput = !(isNumeric && isWithinRange && isDecimalPlaceOk);
        (evt.target as HTMLInputElement).value = ((shouldIgnoreInput ? state.settouPatrolReport[prop] : val.trim()) || '').toString();
      }
      setValue(state.settouPatrolReport, prop, (evt.target as HTMLInputElement).value);
    };
    const onIntParamChange = (evt: Event, prop: keyof SpSettouPatrolReport) => {
      const val = (evt.target as HTMLInputElement).value;
      const shouldIgnoreInput = val && !val.match(/^\d*$/);
      (evt.target as HTMLInputElement).value = ((shouldIgnoreInput ? state.settouPatrolReport[prop] : val.trim()) || '').toString();
      setValue(state.settouPatrolReport, prop, (evt.target as HTMLInputElement).value);
    };
    const onImageFileUpload = (evt: Event) => {
      const fileList = (evt.target as HTMLInputElement).files;
      if (!fileList || fileList.length === 0) { return; }

      // 画像が10MB超えてないか確認する
      const fileSize = fileList[0].size;
      const limitSize = 10 * 1024 * 1024;
      if (fileSize > limitSize) {
        state.errorModalMsg = '添付画像の最大サイズは10MBです。';
        state.showErrorModal = true;
        return;
      }

      const file = fileList[0];
      if (state.settouPatrolReport.imageCandidates) {
        state.settouPatrolReport.imageCandidates.push({
          file: file,
          src: URL.createObjectURL(file),
          isSaved: false,
        });
      }
    };
    const removeImageCandidate = (idx: number) => {
      if (!state.settouPatrolReport.imageCandidates) { return; }
      URL.revokeObjectURL(state.settouPatrolReport.imageCandidates[idx].src);
      state.settouPatrolReport.imageCandidates.splice(idx, 1);
    };
    const releaseImageObjectURLs = () => {
      if (state.settouPatrolReport.imageCandidates) {
        state.settouPatrolReport.imageCandidates.forEach(e => URL.revokeObjectURL(e.src));
      }
    };
    const checkItems = () => {
      const input = state.settouPatrolReport;
      const errorObj: Record<string, boolean> = {};
      let isOk = true;
      const requiredParams: string[] = [];
      requiredParams.forEach(x => {
        const param = x as keyof SpSettouPatrolReport;
        errorObj[param] = input[param] === null || input[param] === '';

        isOk = isOk && !errorObj[param];
      });

      state.errorObj = Object.assign({}, errorObj);
      return isOk;
    };
    const tryShowConfirmCreateModal = () => {
      if (!checkItems()) { return; }
      state.showConfirmCreateModal = true;
    };
    const getParamsForReq = (data: SpSettouPatrolReport) => {
      const { lat, lon } = state.currentLocation || { lat: 0, lon: 0 };
      let kp = null;
      if (data.kp) {
        kp = parseFloat(data.kp).toFixed(2);
      }
      let temperature = null;
      if (data.temperature) {
        temperature = parseFloat(data.temperature).toFixed(2);
      }
      let roadTemperature = null;
      if (data.roadTemperature) {
        roadTemperature = parseFloat(data.roadTemperature).toFixed(2);
      }
      const reqObj: SpSettouPatrolReportExt = {
        lat: lat,
        lon: lon,
        road_name_disp: data.roadNameDisp,
        direction: data.direction,
        kp: kp,
        road_condition: data.roadCondition,
        snow_height: data.snowHeight,
        weather: data.weather,
        temperature: temperature,
        road_temperature: roadTemperature,
        bikou1: data.bikou1,
      };

      if (data.imageCandidates && data.imageCandidates[0]) {
        // 1枚目の写真がある場合は一緒に保存する
        reqObj.image = data.imageCandidates[0].file;
      }

      return reqObj;
    };
    const saveSettouPatrolReportInner = async() => {
      // 写真は複数枚まとめて送るとphp_post_max_size(10MB)の上限を超えてしまう可能性があるため、
      // 1枚ずつ順番にリクエストを投げる.

      // 文字と1枚目の写真を保存
      const reqObj = getParamsForReq(state.settouPatrolReport);
      const { data: savedReport } = await johaisetsuSettouPatrolReportApi.create(reqObj);

      if (state.settouPatrolReport.imageCandidates && state.settouPatrolReport.imageCandidates[0]) {
        state.settouPatrolReport.imageCandidates[0].isSaved = true;
      }

      // 2枚目以降の写真を保存
      for (const imageCandidate of (state.settouPatrolReport.imageCandidates || [])) {
        if (imageCandidate.isSaved) { continue; }

        const reqObj = { image: imageCandidate.file };
        await johaisetsuSettouPatrolReportApi.addPhoto(savedReport.id, reqObj);
      }
    };
    const saveSettouPatrolReport = async() => {
      state.isRequesting = true;
      try {
        await saveSettouPatrolReportInner();
        state.isRequesting = false;
        releaseImageObjectURLs();
        initSettouPatrolReport();
        state.showConfirmCreateModal = false;
        state.showCompleteCreateModal = true;
      } catch (e) {
        state.isRequesting = false;
        state.showConfirmCreateModal = false;
        state.errorModalMsg = '報告書の作成に失敗しました。再度操作を行ってください。';
        state.showErrorModal = true;
      }
    };

    const logout = async() => {
      await store.dispatch(UserActionTypes.LOGOUT);
      // want to explicitly reload
      location.href = '/login';
    };
    const { route } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
      enableNoSleep();
      startGettingCurrentLocation();

      await waitForJohaisetsuMasters();
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce(
        (acc: Record<string, RoadNameDirection>, e: RoadNameDirection) => { acc[e.roadNameReal] = e; return acc; }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;

      initSettouPatrolReport();

      state.isReady = true;
    });
    onBeforeUnmount(() => {
      releaseImageObjectURLs();
      clearGeolocationWatch();
      disableNoSleep();
    });
    return {
      ...toRefs(state),
      // computed
      displayName,
      hasError,
      isGettingCurrentLocation,
      // methods
      clearGeolocationErrors,
      clearGeolocationWatch,
      setCurrentLocation,
      onGeolocationError,
      startGettingCurrentLocation,
      stopGettingCurrentLocation,
      initSettouPatrolReport,
      onRoadNameChange,
      onFloatParamChange,
      onIntParamChange,
      onImageFileUpload,
      removeImageCandidate,
      releaseImageObjectURLs,
      checkItems,
      tryShowConfirmCreateModal,
      getParamsForReq,
      saveSettouPatrolReportInner,
      saveSettouPatrolReport,
      logout,
    };
  },
});
