


import {
  defineComponent,
  ref,
} from '@vue/composition-api';
import SettouSagyouCommon from '@/components/Sp/SettouSagyou/SettouSagyouCommon/index.vue';
import { ABILITY_YUSETSU_INPUT } from '@/consts/ability';
import { JOHAISETSU_TYPE_GROUP_YUSETSU_SAGYOU } from '@/consts/johaisetsu_car';
export interface SettouSagyouYusetsuState {
  ability: number;
  johaisetsuTypeGroup: string;
  johaisetsuTypeGroupName: string;
}
export default defineComponent({
  name: 'sp-settou-sagyou-yusetsu',
  components: { SettouSagyouCommon },
  setup() {
    const ability = ref(ABILITY_YUSETSU_INPUT);
    const johaisetsuTypeGroup = ref(JOHAISETSU_TYPE_GROUP_YUSETSU_SAGYOU);
    const johaisetsuTypeGroupName = ref('融雪作業');

    return {
      ability,
      johaisetsuTypeGroup,
      johaisetsuTypeGroupName,
    };
  },
});
