


import {
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import johaisetsuSettouPatrolReportApi from '@/apis/johaisetsu_settou_patrol_report';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import { downloadBlob } from '@/lib/downloadHelper';
import { dtFormat } from '@/lib/dateHelper';
import { RoadNameDirection } from '@/models/index';
import { SettouPatrolReportRoadConditionMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { SettouPatrolReport } from '@/models/apis/settou/settouPatrolReportsResponse';
import { SettouPatrolReportRaw } from '@/models/settouPatrol';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import useSessionStorageParameterStore from '@/composables/useSessionStorageParameterStore';

const SESSION_STORAGE_KEY = 'SettouPatrolReportListSearchParams';

interface Pagination {
  currentPage: number;
  itemsPerPage: number;
}

interface SearchParams {
  dateFrom: Date;
  dateTo: Date;
}

interface SearchParamsRaw {
  dateFrom: string;
  dateTo: string;
}

interface SettouPatrolReportListState {
  isReady: boolean;
  isRequesting: boolean;
  isDownloading: boolean;

  searchParams: SearchParams;

  reports: SettouPatrolReportRaw[];

  roadNames: RoadNameDirection[];
  roadNameMap: Record<string, RoadNameDirection>;
  // directions: [],
  roadConditions: SettouPatrolReportRoadConditionMap[];
  roadConditionMap: { [key: string]: SettouPatrolReportRoadConditionMap };

  showErrorModal: boolean;
  errorBody: string;

  pager: Pagination;
}

export default defineComponent({
  name: 'settou-patrol-report-list',
  setup() {
    const getInitSearchParams = () => {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      return {
        dateFrom: new Date(now.getTime() - 1000 * 60 * 60 * 24 * 3),
        dateTo: now,
      };
    };
    const state = reactive<SettouPatrolReportListState>({
      isReady: false,
      isRequesting: false,
      isDownloading: false,

      searchParams: getInitSearchParams(),

      reports: [],

      roadNames: [],
      roadNameMap: {},
      // directions: [],
      roadConditions: [],
      roadConditionMap: {},

      showErrorModal: false,
      errorBody: '',

      pager: {
        currentPage: 1,
        itemsPerPage: 50,
      },
    });
    const { getStoredParams, storeParams } = useSessionStorageParameterStore<SearchParams, SearchParamsRaw>({
      storageKey: SESSION_STORAGE_KEY,
      initParams: getInitSearchParams(),
      beforeSerialize: (params: SearchParams) => {
        return {
          dateFrom: params.dateFrom.toISOString(),
          dateTo: params.dateTo.toISOString(),
        };
      },
      afterDeserialize: (params: SearchParamsRaw) => {
        return {
          dateFrom: new Date(params.dateFrom),
          dateTo: new Date(params.dateTo),
        };
      },
    });

    const store = useStore();
    const userState = store.state.user;
    const showWaitSpinner = computed<boolean>(() => {
      return !state.isReady || state.isRequesting;
    });
    const paginatedReports = computed<SettouPatrolReportRaw[]>(() => {
      const nextPageIdx = state.pager.currentPage * state.pager.itemsPerPage;
      const minIdx = nextPageIdx - state.pager.itemsPerPage;
      return state.reports.filter((e, i) => {
        return i >= minIdx && i < nextPageIdx;
      });
    });
    const isDateParamOk = computed<boolean>(() => {
      const params = state.searchParams;
      const maxDateRange = 31;
      return (Math.abs(params.dateTo.getTime() - params.dateFrom.getTime()) < (maxDateRange + 1) * 1000 * 60 * 60 * 24);
    });

    const initSearchParams = () => {
      state.searchParams = getStoredParams();
    };
    const convReports = (data: SettouPatrolReport[]): SettouPatrolReportRaw[] => {
      return data.map(e => {
        let roadNameDisp = e.road_name_disp;
        const roadNameObj = state.roadNameMap[e.road_name_disp];
        if (roadNameObj) {
          roadNameDisp = roadNameObj.roadNameDisp;
        }

        let roadConditionText = '';
        const roadConditionObj = state.roadConditionMap[e.road_condition];
        if (roadConditionObj) {
          roadConditionText = roadConditionObj.text;
          const conditionsWithCm = [7, 8];
          if (conditionsWithCm.includes(parseInt(e.road_condition))) {
            roadConditionText = roadConditionText.replace(/\$\$/, e.snow_height.toString());
          }
        }

        return {
          ...e,
          ts: new Date(e.ts),
          roadNameDisp: roadNameDisp,
          roadConditionText: roadConditionText,
          hasImage: e.photos.length > 0,
        };
      });
    };
    const getSearchParamsForReq = () => {
      const params = state.searchParams;
      const reqObj = {
        ts_from: params.dateFrom,
        ts_to: new Date(params.dateTo.getTime() + 1000 * 60 * 60 * 24 - 1),
      };
      return reqObj;
    };
    const getReports = async() => {
      state.isRequesting = true;
      try {
        const reqObj = getSearchParamsForReq();
        const { data } = await johaisetsuSettouPatrolReportApi.index(reqObj);
        state.reports = convReports(data);
        state.pager.currentPage = 1;
        state.isRequesting = false;
        storeParams(state.searchParams);
      } catch (e) {
        console.error('error', e);
        state.isRequesting = false;
      }
    };
    const { route, router } = useRoute();
    const downloadCSV = async() => {
      state.isDownloading = true;
      try {
        const reqObj = getSearchParamsForReq();
        const { data } = await johaisetsuSettouPatrolReportApi.downloadCSV(reqObj);
        const timestamp = dtFormat(new Date(), 'yyyymmdd_HHMMSS');
        const filename = `雪凍パトロール_${timestamp}`;
        downloadBlob(data, filename);
        state.isDownloading = false;
      } catch (e) {
        console.error('error', e);
        state.errorBody = 'CSVダウンロードに失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
        state.isDownloading = false;
      }
    };
    const showDetailPage = (e: SettouPatrolReportRaw) => {
      const routeObj = {
        name: 'SettouPatrolReportDetail',
        params: {
          id: e.id.toString(),
        },
      };
      const obj = router.resolve(routeObj);
      window.open(obj.href, '_blank');
    };
    onMounted(async() => {
      await waitForUserAndMasters();
      redirectIfNoAbility(userState, route.value);

      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }

      await waitForJohaisetsuMasters();
      state.roadNames = JSON.parse(JSON.stringify(window.master.roadNameDirections.filter(e => !e.isDummy)));
      state.roadNameMap = state.roadNames.reduce(
        (acc: Record<string, RoadNameDirection>, e: RoadNameDirection) => { acc[e.roadNameReal] = e; return acc; }, {});
      state.roadConditionMap = window.johaisetsuMaster.settouPatrolReportRoadConditionMap;
      state.roadConditions = window.johaisetsuMaster.settouPatrolReportRoadConditions;

      initSearchParams();
      await getReports();

      state.isReady = true;
    });

    return {
      ...toRefs(state),
      // computed
      showWaitSpinner,
      paginatedReports,
      isDateParamOk,
      // methods
      initSearchParams,
      convReports,
      getSearchParamsForReq,
      getReports,
      downloadCSV,
      showDetailPage,
      dtFormat,
    };
  },
});
