import { Feature } from 'ol';
import { MultiLineString, MultiPoint } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { StyleLike } from 'ol/style/Style';
import { Coordinate } from 'ol/coordinate';
import OlMapWrapper, { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { RoadGeoItemData, GeoItemEntity } from '@/models/apis/geoItem/geoItemResponse';
import { Store } from '@/hooks/useStore';
import ExtremeMapAbstractLayerManager, { LayerWithInfo } from '@/lib/ExtremeMapAbstractLayerManager';
import AbstractGeoItem from '@/lib/geo_item/AbstractGeoItem';
import WorkConfirmedHelper from '@/lib/geo_item/work_confirmedHelper';
import CyzenHaisetsuHelper from '@/lib/geo_item/cyzen_haisetsuHelper';
import CyzenJosetsuHelper from '@/lib/geo_item/cyzen_josetsuHelper';
import CyzenSanpuHelper from '@/lib/geo_item/cyzen_sanpuHelper';
import EnsuiSanpuHelper from '@/lib/geo_item/ensui_sanpuHelper';
import FrozenHelper from '@/lib/geo_item/frozenHelper';
import JhWorkStatusHelper from '@/lib/geo_item/jh_work_statusHelper';
import JosetsuHelper from '@/lib/geo_item/josetsuHelper';
import KokeizaiSanpuHelper from '@/lib/geo_item/kokeizai_sanpuHelper';
import KyokushoSanpuHelper from '@/lib/geo_item/kyokusho_sanpuHelper';
import OtherSanpuHelper from '@/lib/geo_item/other_sanpuHelper';
import OtherSoukouHelper from '@/lib/geo_item/other_soukouHelper';
import SalinityHelper from '@/lib/geo_item/salinityHelper';
import ShitsuenSanpuHelper from '@/lib/geo_item/shitsuen_sanpuHelper';
import SnowMountainHelper from '@/lib/geo_item/snow_mountainHelper';
import SnowfallHelper from '@/lib/geo_item/snowfallHelper';
import SweeperSoukouHelper from '@/lib/geo_item/sweeper_soukouHelper';
import TairyuHelper from '@/lib/geo_item/tairyuHelper';
import TemperatureHelper from '@/lib/geo_item/temperatureHelper';

const dataProcessTypeMap: Record<string, string> = {
  // 'ensui_sanpu': 'sum', 散布はユーザーがスマホから入力するのでsumしなくてよい
  // 'shitsuen_sanpu': 'sum',
  // 'kokeizai_sanpu': 'sum',
  // 'kyokusho_sanpu': 'sum',
  // 'other_sanpu': '',
  'sweeper_soukou': 'sum',
  'other_soukou': 'sum',
  'josetsu': 'sum',
};

interface Options {
  isDebugShowGeoConnections?: boolean;
  isDebugShowKpAllLayer?: boolean;
}

export default class ExtremeMapGeoItemLayerManager extends ExtremeMapAbstractLayerManager {
  opts: Options;
  dataType: string;
  geoItem?: AbstractGeoItem<any, any, any, any>;
  store: Store;
  tweakData?: (data: RoadGeoItemData) => RoadGeoItemData;
  featureSorter?: (objKeyA: string, objKeyB: string) => number;
  getFeatureMode?: () => string;

  constructor(dataType: string, store: Store, opts: Options = {}) {
    super();
    this.opts = opts;
    this.dataType = dataType;
    this.store = store;

    switch (dataType) {
      case 'work_confirmed': this.geoItem = new WorkConfirmedHelper(); break;
      case 'cyzen_haisetsu': this.geoItem = new CyzenHaisetsuHelper(); break;
      case 'cyzen_josetsu': this.geoItem = new CyzenJosetsuHelper(); break;
      case 'cyzen_sanpu': this.geoItem = new CyzenSanpuHelper(); break;
      case 'ensui_sanpu': this.geoItem = new EnsuiSanpuHelper(); break;
      case 'frozen': this.geoItem = new FrozenHelper(); break;
      case 'jh_work_status': this.geoItem = new JhWorkStatusHelper(); break;
      case 'josetsu': this.geoItem = new JosetsuHelper(); break;
      case 'kokeizai_sanpu': this.geoItem = new KokeizaiSanpuHelper(); break;
      case 'kyokusho_sanpu': this.geoItem = new KyokushoSanpuHelper(); break;
      case 'other_sanpu': this.geoItem = new OtherSanpuHelper(); break;
      case 'other_soukou': this.geoItem = new OtherSoukouHelper(); break;
      case 'salinity': this.geoItem = new SalinityHelper(); break;
      case 'shitsuen_sanpu': this.geoItem = new ShitsuenSanpuHelper(); break;
      case 'snow_mountain': {
        const helper = new SnowMountainHelper();
        this.geoItem = helper;
        this.getFeatureMode = helper.getFeatureMode;
        break;
      }
      case 'snowfall': this.geoItem = new SnowfallHelper(); break;
      case 'sweeper_soukou': this.geoItem = new SweeperSoukouHelper(); break;
      case 'tairyu': {
        const helper = new TairyuHelper();
        this.geoItem = helper;
        this.tweakData = helper.tweakData;
        this.featureSorter = helper.featureSorter;
        break;
      }
      case 'temperature': {
        const helper = new TemperatureHelper();
        this.geoItem = helper;
        this.featureSorter = helper.featureSorter;
        break;
      }
    }
  }

  getMultiLineFeature_(multiLine: Coordinate[][], featStyle: StyleLike): Feature {
    const multiLineString = new MultiLineString(multiLine);
    const feat = new Feature(multiLineString);
    feat.setStyle(featStyle);
    return feat;
  }

  getMultiPointFeature_(points: Coordinate[], featStyle: StyleLike):Feature {
    const multiPoint = new MultiPoint(points);
    const feat = new Feature(multiPoint);
    feat.setStyle(featStyle);
    return feat;
  }

  getMergedData_(origObj: GeoItemEntity, newObj: GeoItemEntity, dataProcessType: string): GeoItemEntity {
    if (!origObj) { return { ...newObj }; }

    if (dataProcessType === 'sum') {
      // 値を合算する場合
      return { ...newObj, data: (origObj.data as number) + (newObj.data as number) };
    }
    // デフォルトの挙動は、常に新しい方で上書き
    return { ...newObj };
  }

  /**
   * dataType1 = {
   *   ts: date, lat1: float, lon1: float, lat2: float, lon2: float,
   *   kp2: float, data: mixed,
   * }
   * dataType2 = {
   *   ...dataType1,
   *   road_name: string, direction: string, place_name: ?string,
   * }

   * {
   *   ${road_name}#${direction}: {
   *     // 本線 or それ以外
   *     main_line | ${place_name}: {
   *       // 始点KP
   *       ${kp}: {
   *         // 終点KPが始点KPと同一「路線#方向#場所」にある場合(普通に次のKP)
   *         1: [dataType1, ...],
   *         // 終点KPがどこか違う「路線#方向#場所」の場合(接続)
   *         // 複数定義可能だが、あるとは限らない
   *         ${num>1}: [dataType2],
   *       }
   *     }
   *   }
   * }
  */

  createLayer_(data: RoadGeoItemData): void {
    // データタイプごとに事前になんらか値の加工が必要であればここで行う.
    if (this.tweakData) { data = this.tweakData(data); }

    const olMap = new OlMapWrapper();
    const multiLineMap: Record<string, Coordinate[][]> = {};
    const multiPointMap: Record<string, Coordinate[]> = {};
    const featureMode = (this.getFeatureMode && this.getFeatureMode()) || 'line';
    const userGroupInfo = this.store.state.user.g_info;
    for (const ent1 of Object.entries(data)) {
      // const roadNameDirection = ent1[0]
      for (const ent2 of Object.entries(ent1[1])) {
        // const roadPlaceName = ent2[0]
        for (const ent3 of Object.entries(ent2[1])) {
          // const srcKp = ent3[0]
          for (const ent4 of Object.entries(ent3[1])) {
            // const num = ent4[0]
            const objArr = ent4[1];
            if (!objArr) { continue; }
            // 同一KP1-KP2で全く同じ開始地点&終了地点の場合、後勝ちにする
            const tmpMap: Record<string, GeoItemEntity> = {};
            objArr
              .slice()
              .sort((a, b) => a.ts < b.ts ? -1 : 1)
              .forEach(obj => {
                // objのlat1,lon1はKP1の地点情報、lat2,lon2はKP2の地点情報.
                const tmpKey = [obj.lat1, obj.lon1, obj.lat2, obj.lon2]
                  .map(e => parseFloat(e).toFixed(6))
                  .join(',');
                tmpMap[tmpKey] = this.getMergedData_(tmpMap[tmpKey], obj, dataProcessTypeMap[this.dataType]);
              });

            for (const ent5 of Object.entries(tmpMap)) {
              const obj = ent5[1];
              const dataToObjKeyInput = obj.data;

              const dataKey = this.geoItem?.dataToObjKey(dataToObjKeyInput, userGroupInfo);

              const pt1 = olMap.coordFromLonLat(parseFloat(obj.lon1), parseFloat(obj.lat1));
              const pt2 = olMap.coordFromLonLat(parseFloat(obj.lon2), parseFloat(obj.lat2));
              if (featureMode === 'point') {
                if (!multiPointMap[dataKey]) { multiPointMap[dataKey] = []; }
                multiPointMap[dataKey].push(pt1);
              } else {
                if (!multiLineMap[dataKey]) { multiLineMap[dataKey] = []; }
                multiLineMap[dataKey].push([pt1, pt2]);
              }
            }
          }
        }
      }
    }

    const features = [];
    let keys = Object.entries(multiLineMap).map(ent => ent[0]);
    if (this.featureSorter) { keys = keys.sort(this.featureSorter); }
    for (const key of keys) {
      const featureStyle = this.geoItem?.objKeyToFeatureStyle(key, userGroupInfo);
      if (featureStyle) {
        const feature = this.getMultiLineFeature_(
          multiLineMap[key],
          featureStyle,
        );
        features.push(feature);
      }
    }

    keys = Object.entries(multiPointMap).map(ent => ent[0]);
    if (this.featureSorter) { keys = keys.sort(this.featureSorter); }
    for (const key of keys) {
      const featureStyle = this.geoItem?.objKeyToFeatureStyle(key, userGroupInfo);
      if (featureStyle) {
        const feature = this.getMultiPointFeature_(
          multiPointMap[key],
          featureStyle,
        );
        features.push(feature);
      }
    }

    const layer: NamedVectorLayer = new VectorLayer({
      visible: true,
      source: new VectorSource({features: features}),
    });
    layer.name = this.dataType;
    this.layer = layer;
  }

  prepareLayer(data: RoadGeoItemData): LayerWithInfo {
    this.createLayer_(data);
    return this.getLayer();
  }
}
