import { CommonHeader,
  SanpuBlock } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { TaskForceInfos,
  PlanningBlock,
  TaskForce,
  HoursPerKMGroupCm } from '@/models/johaisetsuCommon';
import VueRouter from 'vue-router';
import { JohaisetsuSagyouJoukyouRoadName } from '@/models/apis/master/masterResponse';
import { dtFormat } from '@/lib/dateHelper';

export interface AreaShowOption {
  name: string;
  order: number;
}

const ROLE_HONSHA = 'honsha';
const COVERED_AREA_ALL = 'all';
const AREA_DISP_MAP1: Record<string, AreaShowOption> = {
  't-west': { name: '西', order: 1 },
  't-east': { name: '東', order: 2 },
  'kanagawa': { name: '神', order: 3 },
};

export function getTaskForceInfos(taskForceId: number | string): TaskForceInfos {
  const taskForces = window.johaisetsuMaster.taskForces;
  const tmpTaskForce = window.johaisetsuMaster.taskForceMap[taskForceId.toString()];
  const selectedTaskForce = tmpTaskForce || taskForces[0];
  return { taskForces, selectedTaskForce };
}

export function getJohaisetsuRoadNameInfos(): JohaisetsuSagyouJoukyouRoadName[] {
  return JSON.parse(JSON.stringify(
    window.johaisetsuMaster.sagyouJoukyouRoadNameDirections)) as JohaisetsuSagyouJoukyouRoadName[];
}

export function getJohaisetsuPlanningBlocks(): PlanningBlock[] {
  return (window.johaisetsuMaster.planningBlocks).map(e => {
    return Object.assign({}, e);
  });
}

export function getJohaisetsuHoursPerKmGroupCm(): HoursPerKMGroupCm[] {
  return (window.johaisetsuMaster.hoursPerKmGroupCm).map(e => {
    return Object.assign({}, e);
  });
}

export function getJohaisetsuEnsuiSanpuBlocks(): SanpuBlock[] {
  return (window.johaisetsuMaster.ensuiSanpuBlocks).map(e => {
    return Object.assign({}, e);
  });
}

export function getJohaisetsuJutenSanpuBlocks(): SanpuBlock[] {
  return (window.johaisetsuMaster.jutenSanpuBlocks).map(e => {
    return Object.assign({}, e);
  });
}

export function isDataReadOnly(selectedTaskForce: TaskForce, selectedHeader: CommonHeader | undefined, johaisetsuRole: string): boolean {
  // * 対策本部に終了日が入っている場合はRO
  // * headerがcurrentではない場合(スナップショットの場合)はRO
  //   (headerがない場合はcurrentを見ていると推定する)
  // * 本社はRO
  return !!selectedTaskForce.end_date ||
    (selectedHeader && selectedHeader.name !== 'current') ||
    johaisetsuRole === ROLE_HONSHA;
}

export function onChangeUrlPathParam(router: VueRouter, routeName: string, taskForceId: number | string, headerId: string): void {
  const routeObj = {
    name: routeName,
    params: {
      taskForceId: taskForceId.toString(),
      headerId: headerId,
    },
  };
  router.push(routeObj);
}

export function convHeadersForList(taskForce: TaskForce, headers: CommonHeader[], johaisetsuRole: string): CommonHeader[] {
  // currentの表示時刻はmin(現在, taskForceの終了時刻)とする
  let nowForCurrent = new Date();
  if (taskForce.end_date) {
    const taskForceEndDate = new Date(taskForce.end_date);
    taskForceEndDate.setHours(23);
    taskForceEndDate.setMinutes(59);
    if (taskForceEndDate.valueOf() < nowForCurrent.valueOf()) {
      nowForCurrent = taskForceEndDate;
    }
  }
  const nameDispOfTypeCurrent = dtFormat(nowForCurrent, 'mm/dd HH:MM 現在');
  // 空だったらダミーのcurrentが入った配列を返しとく
  if (!headers || headers.length === 0) {
    return [{
      idConv: 'current',
      name: 'current',
      nameDisp: nameDispOfTypeCurrent,
    }];
  }

  return headers.filter(e => {
    // 局の場合はcovered_areaに自分が入ってなかったら関係ないので見せない
    if (johaisetsuRole === ROLE_HONSHA) { return true; }
    if (e.covered_area === COVERED_AREA_ALL) { return true; }
    return e.covered_area?.includes(johaisetsuRole);
  }).map(e => {
    const ret = Object.assign({}, e);
    let idConv = ret.id?.toString();
    let nameDisp = ret.name;
    if (ret.type === 'current') {
      idConv = 'current';
      nameDisp = nameDispOfTypeCurrent;
    } else {
      if (johaisetsuRole === ROLE_HONSHA) {
        // 本社側ではどこの局が定時報告送信済か見えるようにする
        const coveredAreaConv = e.covered_area?.split(',')
          .sort((a, b) => {
            const v1 = (AREA_DISP_MAP1[a] || { order: 99 }).order;
            const v2 = (AREA_DISP_MAP1[b] || { order: 99 }).order;
            return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
          })
          .map(area => {
            return (AREA_DISP_MAP1[area] || { name: '' }).name;
          }).join(',');
        nameDisp += `(${coveredAreaConv})`;
      }
    }
    ret.idConv = idConv || '';
    ret.nameDisp = nameDisp;
    return ret;
  });
}

export function getDefaultNameForRegularReport(): string {
  let tm = new Date();
  const minutes = tm.getMinutes();
  if (minutes < 15) {
    tm.setMinutes(0);
  } else if (minutes < 45) {
    tm.setMinutes(30);
  } else {
    if (tm.getHours() === 23) {
      tm = new Date(tm.valueOf() + 86400 * 1000);
    }
    tm.setHours(tm.getHours() + 1);
    tm.setMinutes(0);
  }
  const dateTimeDisp = dtFormat(tm, 'mm/dd HH:MM');
  return `${dateTimeDisp} 定時報告`;
}
