


import {
  defineComponent,
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  toRefs,
} from '@vue/composition-api';

import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { UserActionTypes } from '@/store/modules/user';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import {
  timeDifferenceInSeconds,
  secondsToTimeInteger,
  timeIntegerToSeconds,
  unpackTimeInteger,
  timeInteger,
} from '@/lib/dateTimeHelper';
import useSpeech from '@/composables/useSpeech';
import johaisetsuCarApi from '@/apis/johaisetsu_car';
import johaisetsuMtxApi from '@/apis/johaisetsu_mtx';
import { enableNoSleep, disableNoSleep } from '@/lib/noSleepUtil';
import { JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU } from '@/consts/johaisetsu_car';
import DateTimeInput from '@/components/Sp/SettouSagyou/SettouSagyouCommon/DateTimeInput.vue';
import { GeolocationOpts } from '@/models/geoItem';
import { Location, Position, GeolocationPositionError } from '@/models/index';
import { JohaisetsuTypeMap } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { Ability } from '@/models/apis/user/userResponse';
import { dtFormat } from '@/lib/dateHelper';
import { JohaisetsuCarDetail } from '@/models/apis/johaisetsu/johaisetsuCarResponse';
import { JohaisetsuCarCreateOrUpdateDetailParams } from '@/models/apis/johaisetsu/johaisetsuCarRequest';
import { JohaisetsuMTX } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';

const STATUS_RUNNING = 'running';
const STATUS_STOPPED = 'stopped';

interface JohaisetsuCarDetailExt {
  bikou1: string | null;
  sanpuNum: number | null;
  startTs: Date | null;
  endTs: Date | null;
}
interface JohaisetsuCarExt {
  deviceId: string | null;
  detail: JohaisetsuCarDetailExt;
}

interface SettouSagyouCommonState {
  isReady: boolean;
  isRequesting: boolean;

  isMoving: boolean;
  isWorking: boolean;
  isSuspending: boolean;

  currentLocation: Location | null;
  geolocationErrors: GeolocationPositionError[];
  geolocationOpts: GeolocationOpts;

  johaisetsuTypes: JohaisetsuTypeMap[];
  johaisetsuTypeMap: { [key: string]: JohaisetsuTypeMap };
  sanpuNums: number[];
  maxSanpuNum: number;

  selectedJohaisetsuType: string | null;

  johaisetsuCar: JohaisetsuCarExt;

  statusDispMap: Record<string, string>;

  errorObj: Record<string, boolean>;

  currentTime: Date | null;
  workStartTime: Date | null;

  johaisetsuMtxs: JohaisetsuMTX[];
  lastJohaisetsuMtxTs: Date | null;

  showGeolocationGeneralErrorMsg: boolean;
  showGeolocationPermissionErrorModal: boolean;

  errorModalMsg: string;
  showErrorModal: boolean;
  showConfirmStartWorkingModal: boolean;
  showConfirmStopWorkingModal: boolean;
  showConfirmTemporarilyStopWorkingModal: boolean;

  geolocationWatchHandler: number | null;

  johaisetsuCarUpdateTimer: number | null;
  workElapsedTimeNotifyTimer: number | null;
  johaisetsuMtxStoreTimer: number | null;
  johaisetsuMtxCreateTimer: number | null;

  johaisetsuCarUpdateTimerSec: number; // seconds
  workElapsedTimeNotifyTimerSec: number; // seconds
  johaisetsuMtxStoreTimerSec: number; // seconds
  johaisetsuMtxCreateTimerSec: number; // seconds
}
export default defineComponent({
  name: 'sp-settou-sagyou-common',
  components: { DateTimeInput },
  props: {
    ability: {
      type: Number,
      required: true,
    },
    johaisetsuTypeGroup: {
      type: String,
      required: true,
    },
    johaisetsuTypeGroupName: {
      type: String,
      required: true,
    },
    shouldSaveJohaisetsuMtx: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { speechSynthesisVoices, doSpeech } = useSpeech();
    const state = reactive<SettouSagyouCommonState>({
      isReady: false,
      isRequesting: false,

      isMoving: false,
      isWorking: false,
      isSuspending: false,

      currentLocation: null,
      geolocationErrors: [],
      geolocationOpts: {
        enableHighAccuracy: true,
        timeout: 5 * 1000,
        maximumAge: 0,
      },

      johaisetsuTypes: [],
      johaisetsuTypeMap: {},
      sanpuNums: [],
      maxSanpuNum: 30,

      selectedJohaisetsuType: null,

      johaisetsuCar: {
        deviceId: null,
        detail: {
          bikou1: null,
          sanpuNum: null,
          startTs: null,
          endTs: null,
        },
      },

      statusDispMap: {
        [STATUS_RUNNING]: '移動中',
        [STATUS_STOPPED]: '停止中',
      },

      errorObj: {},

      currentTime: null,
      workStartTime: null,

      johaisetsuMtxs: [],
      lastJohaisetsuMtxTs: null,

      showGeolocationGeneralErrorMsg: false,
      showGeolocationPermissionErrorModal: false,

      errorModalMsg: '',
      showErrorModal: false,
      showConfirmStartWorkingModal: false,
      showConfirmStopWorkingModal: false,
      showConfirmTemporarilyStopWorkingModal: false,

      geolocationWatchHandler: null,

      johaisetsuCarUpdateTimer: null,
      workElapsedTimeNotifyTimer: null,
      johaisetsuMtxStoreTimer: null,
      johaisetsuMtxCreateTimer: null,

      johaisetsuCarUpdateTimerSec: 10, // seconds
      workElapsedTimeNotifyTimerSec: 1, // seconds
      johaisetsuMtxStoreTimerSec: 1, // seconds
      johaisetsuMtxCreateTimerSec: 60, // seconds

      // speechMixin参照
      // speechSynthesisVoices: [],
    });
    const store = useStore();
    const userState = store.state.user;
    const abilityMap = computed<Record<number, Ability>>(() => {
      return userState.abilityMap;
    });

    const displayName = computed<string>(() => {
      return userState.display_name;
    });

    const hasError = computed<boolean>(() => {
      return Object.values(state.errorObj).includes(true);
    });
    const isGettingCurrentLocation = computed<boolean>(() => {
      return !!state.geolocationWatchHandler;
    });
    const johaisetsuTypeNameDisp = computed<string>(() => {
      let ret = '';
      if (state.johaisetsuTypes.length > 1) {
        const johaisetsuTypeObj = state.johaisetsuTypeMap[state.selectedJohaisetsuType || ''];
        ret = johaisetsuTypeObj ? `(${johaisetsuTypeObj.name})` : '';
      }
      return ret;
    });
    const showSanpuNum = computed<boolean>(() => {
      return props.johaisetsuTypeGroup === JOHAISETSU_TYPE_GROUP_TOUKETSU_BOUSHI_SAGYOU;
    });
    const workElapsedTimeInt = computed<number>(() => {
      if (!state.workStartTime || !state.currentTime) { return 0; }

      const workElapsedTimeSec =
        timeDifferenceInSeconds(state.workStartTime, state.currentTime);
      return secondsToTimeInteger(workElapsedTimeSec);
    });
    const clearGeolocationErrors = () => {
      state.geolocationErrors = [];
    };
    const clearGeolocationWatch = () => {
      if (!state.geolocationWatchHandler) {
        return;
      }
      navigator.geolocation.clearWatch(state.geolocationWatchHandler);
      state.geolocationWatchHandler = null;
      clearGeolocationErrors();
      state.currentLocation = null;
    };
    const clearTimers = () => {
      if (state.johaisetsuCarUpdateTimer) {
        clearInterval(state.johaisetsuCarUpdateTimer);
      }
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      if (state.johaisetsuMtxStoreTimer) {
        clearInterval(state.johaisetsuMtxStoreTimer);
      }
      if (state.johaisetsuMtxCreateTimer) {
        clearInterval(state.johaisetsuMtxCreateTimer);
      }
      state.johaisetsuCarUpdateTimer = null;
      state.workElapsedTimeNotifyTimer = null;
      state.johaisetsuMtxStoreTimer = null;
      state.johaisetsuMtxCreateTimer = null;
    };
    const setCurrentLocation = (position: Position) => {
      console.log('lat: ', position.coords.latitude, 'lon: ', position.coords.longitude);
      let { latitude, longitude } = position.coords;
      // 小数点第6位まで
      const base = 1000000;
      latitude = Math.floor(latitude * base) / base;
      longitude = Math.floor(longitude * base) / base;
      state.currentLocation = { lat: latitude, lon: longitude };

      // 取得に成功したらエラーを消す
      clearGeolocationErrors();
    };
    const onGeolocationError = (evt: GeolocationPositionError) => {
      console.error('geolocation error', evt);
      const code = evt.code;
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
      // 1 PERMISSION_DENIED
      // 2 POSITION_UNAVAILABLE
      // 3 TIMEOUT
      if (code === 1) {
        // 位置情報の利用が許可されていない場合
        stopGettingCurrentLocation();
        state.showGeolocationPermissionErrorModal = true;
      } else {
        // 位置情報の取得に失敗した場合

        // エラーの内容が1(PERMISSION_DENIED)以外であれば、
        // 位置情報の利用自体は許可されているはず.
        state.geolocationErrors.push(evt);
        if (state.geolocationErrors.length > 5) {
          // エラーが何回か続いたら、画面下にエラーメッセージ出す
          state.showGeolocationGeneralErrorMsg = true;
        }
      }
    };
    const startGettingCurrentLocation = () => {
      clearGeolocationWatch();
      state.geolocationWatchHandler = navigator.geolocation.watchPosition(
        setCurrentLocation,
        onGeolocationError,
        state.geolocationOpts,
      );
    };
    const stopGettingCurrentLocation = async() => {
      await stopAll();
      clearGeolocationWatch();
    };
    const initJohaisetsuCarDetail = () => {
      const obj: JohaisetsuCarDetailExt = {
        bikou1: null,
        sanpuNum: null,
        startTs: null,
        endTs: null,
      };
      if (showSanpuNum.value) {
        obj.sanpuNum = state.sanpuNums[0];
      }
      state.johaisetsuCar.detail = obj;
    };
    const convJohaisetsuCarDetail = (data: JohaisetsuCarDetail) => {
      const obj: JohaisetsuCarDetailExt = {
        bikou1: data.bikou1,
        sanpuNum: null,
        startTs: new Date(data.start_ts),
        endTs: new Date(data.end_ts),
      };
      if (showSanpuNum.value) {
        obj.sanpuNum = parseInt(data.data_value);
      }

      return obj;
    };
    const refreshJohaisetsuCarDetail = async() => {
      if (!state.johaisetsuCar.deviceId) { return; }

      const { data } = await johaisetsuCarApi.show(state.johaisetsuCar.deviceId);
      if (!data || !data.detail) { return; }

      state.johaisetsuCar.detail = convJohaisetsuCarDetail(data.detail);
    };
    const checkItems = () => {
      const input = state.johaisetsuCar.detail;
      const errorObj: Record<string, boolean> = {};
      let isOk = true;
      const requiredParams: string[] = [];
      requiredParams.forEach(x => {
        const param = x as keyof JohaisetsuCarDetailExt;
        errorObj[param] = input[param] === null || input[param] === '';
        isOk = isOk && !errorObj[param];
      });

      state.errorObj = Object.assign({}, errorObj);
      return isOk;
    };
    const tryShowConfirmStartWorkingModal = () => {
      if (!checkItems()) { return; }
      state.showConfirmStartWorkingModal = true;
    };
    const isStatusRunning = (status: string) => {
      return status === STATUS_RUNNING;
    };
    const updateJohaisetsuCar = async(status: string, shouldRefreshDetail = false) => {
      if (!state.currentLocation) { return; }

      const { lat, lon } = state.currentLocation;
      const reqObj = {
        lat: lat,
        lon: lon,
        status: status,
        status_disp: state.statusDispMap[status],
        johaisetsu_type: state.selectedJohaisetsuType,
        ts_is_new: isStatusRunning(status) ? 1 : 0,
      };
      const { data } = await johaisetsuCarApi.createOrUpdate(reqObj);
      state.johaisetsuCar.deviceId = data.device_id;

      if (shouldRefreshDetail) {
        await updateJohaisetsuCarDetail(status);
      }
    };
    const updateJohaisetsuCarDetail = async(status: string) => {
      if (!state.johaisetsuCar.deviceId) {
        await updateJohaisetsuCar(status, true);
        return;
      }

      if (state.isWorking) {
        // 作業中にPC画面から更新している可能性があるため再取得する
        await refreshJohaisetsuCarDetail();
      }

      const now = new Date();
      const data = state.johaisetsuCar.detail;
      const reqObj: JohaisetsuCarCreateOrUpdateDetailParams = {
        bikou1: data.bikou1,
        start_ts: isStatusRunning(status) ? now : (data.startTs || now),
        end_ts: isStatusRunning(status) ? null : now,
      };
      if (showSanpuNum.value && data.sanpuNum) {
        reqObj.data_value = parseInt(data.sanpuNum.toString());
      }
      await johaisetsuCarApi.createOrUpdateDetail(state.johaisetsuCar.deviceId, reqObj);
    };
    const storeJohaisetsuMtx = () => {
      if (!state.currentLocation) { return; }
      if (!state.johaisetsuCar.deviceId) { return; }

      const now = new Date();
      if (state.lastJohaisetsuMtxTs) {
        const thres = 1000; // milliseconds
        const diffMSec = now.getTime() - state.lastJohaisetsuMtxTs.getTime();
        if (diffMSec < thres) { return; }
      }

      const { lat, lon } = state.currentLocation;
      const obj: JohaisetsuMTX = {
        device_id: state.johaisetsuCar.deviceId,
        ts: now,
        lat: lat,
        lon: lon,
        data_type: state.selectedJohaisetsuType,
      };
      if (showSanpuNum.value && state.johaisetsuCar.detail.sanpuNum) {
        obj.data_value = parseInt(state.johaisetsuCar.detail.sanpuNum?.toString());
      }
      state.johaisetsuMtxs.push(obj);
      state.lastJohaisetsuMtxTs = now;
    };
    const saveJohaisetsuMtxs = async() => {
      // 値として取れているものが10個以上あったら送る
      const thres = 10;
      if (state.johaisetsuMtxs.length < thres) { return; }

      const johaisetsuMtxs = state.johaisetsuMtxs.splice(0);
      const reqObj = { johaisetsu_mtxs: johaisetsuMtxs };
      await johaisetsuMtxApi.createJohaisetsuMTXs(reqObj);
    };
    const notifyWorkElapsedTime = () => {
      // 現在時刻の更新
      state.currentTime = new Date();

      // 15分毎に経過時間を通知する
      const thres = 900; // seconds
      const workElapsedTimeSec = timeIntegerToSeconds(workElapsedTimeInt.value);
      if (workElapsedTimeSec === 0) { return; }
      if (workElapsedTimeSec % thres > 0) { return; }

      let [h, m, s] = unpackTimeInteger(workElapsedTimeInt.value);
      s = 0;
      let text = '作業開始から';
      if (h > 0) {
        text += `${h}時間`;
      }
      if (m > 0) {
        text += `${m}分`;
      }
      if (s > 0) {
        text += `${s}秒`;
      }
      text += 'が経過しました。';
      doSpeech(text);
    };
    const restartJohaisetsuCarUpdateInterval = async(status: string) => {
      if (state.johaisetsuCarUpdateTimer) {
        clearInterval(state.johaisetsuCarUpdateTimer);
      }
      await updateJohaisetsuCar(status);
      state.johaisetsuCarUpdateTimer = setInterval(updateJohaisetsuCar, state.johaisetsuCarUpdateTimerSec * 1000, status);
    };
    const restartWorkElapsedTimeNotifyInterval = () => {
      state.workStartTime = new Date();
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      state.workElapsedTimeNotifyTimer = setInterval(notifyWorkElapsedTime, state.workElapsedTimeNotifyTimerSec * 1000);
    };
    const restartJohaisetsuMtxUpdateInterval = () => {
      if (!props.shouldSaveJohaisetsuMtx) { return; }

      state.johaisetsuMtxs = [];
      if (state.johaisetsuMtxStoreTimer) {
        clearInterval(state.johaisetsuMtxStoreTimer);
      }
      if (state.johaisetsuMtxCreateTimer) {
        clearInterval(state.johaisetsuMtxCreateTimer);
      }
      state.johaisetsuMtxStoreTimer = setInterval(storeJohaisetsuMtx, state.johaisetsuMtxStoreTimerSec * 1000);
      state.johaisetsuMtxCreateTimer = setInterval(saveJohaisetsuMtxs, state.johaisetsuMtxCreateTimerSec * 1000);
    };
    const startMoving = async() => {
      state.isRequesting = true;
      try {
        clearTimers();
        await restartJohaisetsuCarUpdateInterval(STATUS_RUNNING);
        state.isRequesting = false;
        doSpeech('走行を開始します');
        state.isMoving = true;
      } catch (e) {
        state.isRequesting = false;
        state.errorModalMsg = '走行開始に失敗しました。再度操作を行ってください';
        state.showErrorModal = true;
      }
    };
    const startWorking = async() => {
      state.isRequesting = true;
      const opType = state.isSuspending ? '再開' : '開始';
      try {
        await updateJohaisetsuCarDetail(STATUS_RUNNING);
        state.isRequesting = false;
        restartWorkElapsedTimeNotifyInterval();
        restartJohaisetsuMtxUpdateInterval();
        doSpeech(`作業を${opType}します`);
        state.isSuspending = false;
        state.isWorking = true;
        state.showConfirmStartWorkingModal = false;
      } catch (e) {
        state.isRequesting = false;
        state.errorModalMsg = `作業${opType}に失敗しました。再度操作を行ってください。`;
        state.showErrorModal = true;
        state.showConfirmStartWorkingModal = false;
      }
    };
    const stopMoving = async() => {
      state.isRequesting = true;
      try {
        clearTimers();
        await doStopMoving();
        state.isRequesting = false;
        doSpeech('走行を終了します');
      } catch (e) {
        state.isRequesting = false;
        state.errorModalMsg = '走行終了に失敗しました。再度操作を行ってください。';
        state.showErrorModal = true;
      }
    };
    const stopWorking = async() => {
      state.isRequesting = true;
      try {
        await stopAll();
        state.isRequesting = false;
        doSpeech('作業を終了します');
        state.showConfirmStopWorkingModal = false;
      } catch (e) {
        state.isRequesting = false;
        state.errorModalMsg = '作業終了に失敗しました。再度操作を行ってください。';
        state.showErrorModal = true;
        state.showConfirmStopWorkingModal = false;
      }
    };
    const temporarilyStopWorking = async() => {
      state.isRequesting = true;
      try {
        await suspendWork();
        state.isRequesting = false;
        doSpeech('作業を一時停止します');
        state.showConfirmTemporarilyStopWorkingModal = false;
      } catch (e) {
        state.isRequesting = false;
        state.errorModalMsg = '作業一時停止に失敗しました。再度操作を行ってください。';
        state.showErrorModal = true;
        state.showConfirmTemporarilyStopWorkingModal = false;
      }
    };
    const doStopWorking = async() => {
      await Promise.all([
        saveJohaisetsuMtxs(),
        updateJohaisetsuCarDetail(STATUS_STOPPED),
      ]);
      state.isSuspending = false;
      state.isWorking = false;
    };
    const doStopMoving = async() => {
      await updateJohaisetsuCar(STATUS_STOPPED);
      state.isMoving = false;
    };
    const stopAll = async() => {
      clearTimers();
      await doStopWorking();
      await doStopMoving();
    };
    const suspendWork = async() => {
      if (state.workElapsedTimeNotifyTimer) {
        clearInterval(state.workElapsedTimeNotifyTimer);
      }
      if (state.johaisetsuMtxStoreTimer) {
        clearInterval(state.johaisetsuMtxStoreTimer);
      }
      if (state.johaisetsuMtxCreateTimer) {
        clearInterval(state.johaisetsuMtxCreateTimer);
      }
      state.workElapsedTimeNotifyTimer = null;
      state.johaisetsuMtxStoreTimer = null;
      state.johaisetsuMtxCreateTimer = null;

      await doStopWorking();
      state.isSuspending = true;
    };
    const saveLocalStorage = () => {
      const obj = {
        selectedJohaisetsuType: state.selectedJohaisetsuType,
      };
      const localStorageKey = route.value.name;
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, JSON.stringify(obj));
      }
    };
    const logout = async() => {
      await stopAll();
      await store.dispatch(UserActionTypes.LOGOUT);
      // want to explicitly reload
      location.href = '/login';
    };
    const { route } = useRoute();
    onMounted(async() => {
      await waitForUserAndMasters();
      if (!abilityMap.value[props.ability]) {
        location.href = '/';
        return;
      }

      window.addEventListener('beforeunload', stopAll, false);
      const envElement: HTMLMetaElement | null = document.querySelector("meta[name='viewport']");
      if (envElement) {
        envElement.setAttribute(
          'content',
          'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
        );
      }
      enableNoSleep();
      startGettingCurrentLocation();

      await waitForJohaisetsuMasters();
      state.johaisetsuTypeMap = window.johaisetsuMaster.johaisetsuTypeMap;
      state.johaisetsuTypes = window.johaisetsuMaster.johaisetsuTypes.filter(e => {
        return e.key.endsWith(props.johaisetsuTypeGroup);
      });

      state.sanpuNums = Array.from({ length: state.maxSanpuNum }, (_, i) => i + 1);

      const johaisetsuTypeObj = state.johaisetsuTypes[0];
      if (johaisetsuTypeObj) {
        state.selectedJohaisetsuType = johaisetsuTypeObj.key;
      }

      try {
        const localStorageKey = route.value.name;
        if (localStorageKey) {
          const obj = JSON.parse(localStorage.getItem(localStorageKey) || '');
          if (obj.selectedJohaisetsuType) {
            state.selectedJohaisetsuType = obj.selectedJohaisetsuType;
          }
        }
      } catch (e) {}

      initJohaisetsuCarDetail();

      state.isReady = true;
    });
    onBeforeUnmount(() => {
      clearTimers();
      clearGeolocationWatch();
      disableNoSleep();
      window.removeEventListener('beforeunload', stopAll, false);
    });
    return {
      ...toRefs(state),
      speechSynthesisVoices,
      // computed
      abilityMap,
      displayName,
      hasError,
      isGettingCurrentLocation,
      johaisetsuTypeNameDisp,
      showSanpuNum,
      workElapsedTimeInt,
      // methods
      clearGeolocationErrors,
      clearGeolocationWatch,
      clearTimers,
      setCurrentLocation,
      onGeolocationError,
      startGettingCurrentLocation,
      stopGettingCurrentLocation,
      initJohaisetsuCarDetail,
      convJohaisetsuCarDetail,
      refreshJohaisetsuCarDetail,
      checkItems,
      tryShowConfirmStartWorkingModal,
      isStatusRunning,
      updateJohaisetsuCar,
      updateJohaisetsuCarDetail,
      storeJohaisetsuMtx,
      saveJohaisetsuMtxs,
      notifyWorkElapsedTime,
      restartJohaisetsuCarUpdateInterval,
      restartWorkElapsedTimeNotifyInterval,
      restartJohaisetsuMtxUpdateInterval,
      startMoving,
      startWorking,
      stopMoving,
      stopWorking,
      temporarilyStopWorking,
      doStopWorking,
      doStopMoving,
      suspendWork,
      saveLocalStorage,
      logout,
      dtFormat,
      timeInteger,
    };
  },
});
