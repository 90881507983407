
import {
  ref,
  defineComponent,
  computed,
  onMounted,
  reactive,
  toRefs,
  nextTick,
} from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useRoute } from '@/hooks/useRoute';
import { waitForUserAndMasters } from '@/lib/masterHelper';
import { waitForJohaisetsuMasters } from '@/lib/johaisetsuHelper';
import johaisetsuEnsuiSanpuJoukyouApi from '@/apis/johaisetsu_ensui_sanpu_joukyou';
import { notifySuccess } from '@/lib/notificationUtil';
import {
  convHeadersForList,
  getDefaultNameForRegularReport,
  getTaskForceInfos,
  isDataReadOnly,
  onChangeUrlPathParam,
} from '@/lib/johaisetsu/johaisetsuCommonUtil';
import {
  prepareBlocks,
  filterJoukyouDetailsByRole,
  getMapUpdateInfos,
  DATA_TYPE_ENSUI_SANPU,
} from '@/lib/johaisetsu/johaisetsuEnsuiJutenKaradanpuCommonUtil';
import EnsuiSanpuJoukyouMap from '@/components/Johaisetsu/EnsuiSanpuJoukyouMap/EnsuiSanpuJoukyouMap.vue';
import { CommonHeader,
  JoukyouInfoRaw,
  JohaisetsuJoukyouDetail,
  SanpuBlock } from '@/models/apis/johaisetsu/johaisetsuCommon';
import { TaskForce } from '@/models/johaisetsuCommon';
import { redirectIfNoAbility } from '@/lib/abilityHelper';
import { getKyokuIdByJohaisetsuRole, isJohaisetsuRoleKyoku } from '@/lib/johaisetsuRoleHelper';

const api = johaisetsuEnsuiSanpuJoukyouApi;
const dataType = DATA_TYPE_ENSUI_SANPU;

interface EnsuiSanpuJoukyouMapState {
  isReady: boolean;
  isReadOnly: boolean;
  isRequesting: boolean;

  blocks: SanpuBlock[];
  taskForces: TaskForce[];
  joukyouHeaders: CommonHeader[];

  showTaskForceSelect: boolean;
  selectedTaskForce: TaskForce;
  selectedJoukyouInfo: JoukyouInfoRaw<JohaisetsuJoukyouDetail>;

  showSnapshotConfirmModal: boolean;
  snapshotName: string;
}

export default defineComponent({
  name: 'johaisetsu-ensui-sanpu-joukyou-map',
  components: { EnsuiSanpuJoukyouMap },
  setup() {
    const state = reactive<EnsuiSanpuJoukyouMapState>({
      isReady: false,
      isReadOnly: false,
      isRequesting: false,

      blocks: [],
      taskForces: [],
      joukyouHeaders: [],

      showTaskForceSelect: false,
      selectedTaskForce: { id: -1, name: '' },
      selectedJoukyouInfo: {} as JoukyouInfoRaw<JohaisetsuJoukyouDetail>,

      showSnapshotConfirmModal: false,
      snapshotName: '',
    });
    const ensuiSanpuJoukyouMap = ref<InstanceType<typeof EnsuiSanpuJoukyouMap>>();
    const store = useStore();
    const userState = store.state.user;
    const johaisetsuRole = computed<string>(() => {
      return getKyokuIdByJohaisetsuRole(userState.johaisetsu_role);
    });
    const { route, router } = useRoute();
    const taskForceId = computed<string>(() => {
      return route.value.params.taskForceId;
    });
    const headerId = computed<string>(() => {
      return route.value.params.headerId;
    });
    const mapArea = computed<string>(() => {
      if (johaisetsuRole.value === 'honsha') { return 'all'; }
      return johaisetsuRole.value;
    });
    const canSendReport = computed<boolean>(() => {
      return isJohaisetsuRoleKyoku(userState.johaisetsu_role);
    });
    const onSelectedTaskForceChange = (taskForceId: number) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId, 'current');
    };
    const onSelectedHeaderChange = (headerId: string) => {
      onChangeUrlPathParam(router, route.value.name || '', taskForceId.value, headerId);
    };
    const reloadData = async() => {
      state.isReady = false;
      await getJoukyouHeaders();
      await getSelectedJoukyouInfo();
      state.isReady = true;
      nextTick(() => {
        drawMap();
      });
    };
    const getJoukyouHeaders = async() => {
      const { data: joukyouHeaders } = await api.index({
        taskForceId: state.selectedTaskForce.id,
      });
      state.joukyouHeaders = convHeadersForList(state.selectedTaskForce, joukyouHeaders, johaisetsuRole.value);
    };
    const getSelectedJoukyouInfo = async() => {
      const { data: selectedJoukyouInfo } =
        await api.show(
          {
            taskForceId: state.selectedTaskForce.id,
            headerId: headerId.value,
          },
          {
            with_block: 1,
          },
        );

      selectedJoukyouInfo.details = filterJoukyouDetailsByRole(
        selectedJoukyouInfo.details,
        johaisetsuRole.value,
      );
      state.selectedJoukyouInfo = selectedJoukyouInfo;
      state.blocks = prepareBlocks(
        state.selectedJoukyouInfo,
        johaisetsuRole.value,
        dataType,
      );
    };
    const drawMap = () => {
      if (!state.selectedJoukyouInfo.header) { return; }

      const mapUpdateInfos = getMapUpdateInfos(
        state.selectedJoukyouInfo,
        dataType,
      );
      if (ensuiSanpuJoukyouMap.value && mapUpdateInfos) {
        ensuiSanpuJoukyouMap.value.updateMapElements(mapUpdateInfos);
      }
    };
    const openSnapshotConfirmModal = () => {
      state.snapshotName = getDefaultNameForRegularReport();
      state.showSnapshotConfirmModal = true;
    };
    const saveSnapshot = async() => {
      if (state.isRequesting) { return; }
      if (!state.snapshotName) { return; }
      state.isRequesting = true;
      await api.createSnapshot(
        state.selectedTaskForce.id,
        { snapshot_name: state.snapshotName },
      );
      notifySuccess('', '定時報告を送信しました');
      state.showSnapshotConfirmModal = false;
      state.isRequesting = false;
      await reloadData();
    };
    const cancelSnapshot = () => {
      state.showSnapshotConfirmModal = false;
    };
    onMounted(async() => {
      await Promise.all([
        waitForUserAndMasters(),
        waitForJohaisetsuMasters(),
      ]);
      redirectIfNoAbility(userState, route.value);

      const { taskForces, selectedTaskForce } = getTaskForceInfos(taskForceId.value);
      state.taskForces = taskForces;
      state.selectedTaskForce = selectedTaskForce;
      await reloadData();

      state.isReadOnly = isDataReadOnly(
        state.selectedTaskForce,
        state.selectedJoukyouInfo.header,
        johaisetsuRole.value,
      );

      state.isReady = true;
      nextTick(() => {
        drawMap();
      });
    });
    return {
      ...toRefs(state),
      // refes
      ensuiSanpuJoukyouMap,
      // computed
      johaisetsuRole,
      taskForceId,
      headerId,
      mapArea,
      canSendReport,
      // methods
      onSelectedTaskForceChange,
      onSelectedHeaderChange,
      reloadData,
      getJoukyouHeaders,
      getSelectedJoukyouInfo,
      drawMap,
      openSnapshotConfirmModal,
      saveSnapshot,
      cancelSnapshot,
    };
  },
});
