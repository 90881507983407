import Vue from 'vue';
import { computed, nextTick, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api';
import { TabResources } from '@/models/tab';
import { Comment } from '@/models/apis/comment/commentResponse';
import useMaster from '@/composables/useMaster';
import { FetchGeoItemsParams, GIComment, GeoItemMeta } from '@/models/geoItem';
import { selectRelativeCommentAfterDeleted, convCommentsAsRecentComments, hasLocation } from '@/lib/commentHelper';
import { getGeoItemMeta } from '@/lib/geoItemHelper';
import { ensureUserAndMasters } from '@/lib/masterHelper';
import { useStore } from '@/hooks/useStore';
import { CustomGeoItemLayer } from '@/models/apis/user/userResponse';
import { LayerInfo, MapElemInfo, MasterData, Location } from '@/models';
import GeoItemSearchComment from '@/components/Top/geoItemSearchComponents/GeoItemSearchComment.vue';
import { createNewTabs, getEnsuredLayerItem, prepareTabResources, showWaitSpinnerTabs } from '@/lib/tabPaneHelper';
import { LocalStorageActionTypes, LocalStorageGetterTypes } from '@/store/modules/localStorage';
import { ShowImageParams } from '@/composables/useSavedImage';
import { UseCommentResult, State, GeoItemInner, UseCommentParams } from '@/models/useComment';
import {
  getCommentIndexParams,
  getCommentTab,
  getGeoItemsInner,
  getInitUseCommentState,
  getNewObjForMapSelectedElem,
} from './utils';
import { UserState } from '@/store/modules/user';

export function useComment(params: UseCommentParams): UseCommentResult {
  // reactiveで定義すると、配列の型が正しく認識されないため、as Stateで型を指定する
  // https://stackoverflow.com/questions/65576268/vue-composition-api-array-in-reactive-object-typing-error
  const state = reactive<State>(getInitUseCommentState()) as State;

  const startGettingRecentComments = async() => {
    const commentTab = getCommentTab();
    // 検索中にlabelが初期表示されるようにtabsに入れる
    state.tabs = [commentTab];
    await initGetRecentCommentsInterval(commentTab.resources);
    commentTab.resources.ready = true;
  };

  const { state: msts } = useMaster();
  const initGetRecentCommentsInterval = async(resources: TabResources) => {
    window.clearRequestInterval(state.commentUpdateTimer);
    const func = async() => {
      if (!state.geoItemMetaContext.show.comment) {
        return;
      }
      const giCommentManager = state.geoItemMetaContext.map.comment.manager;
      const comments = await giCommentManager.getResourcesByParams(getCommentIndexParams());
      resources.elements = convCommentsAsRecentComments(msts, comments);
    };
    await func();
    state.commentUpdateTimer = window.requestInterval(func, 30 * 1000);
  };
  const initializeDataManagers = (origList: LayerInfo[], { user, masters }: { user: UserState; masters: MasterData }) => {
    const map = getGeoItemMeta(origList, { useGIManager: true, user, masters });
    state.geoItemMetaContext.list = Object.values(map);
    state.geoItemMetaContext.map = map;
  };
  const refreshCommentTab = () => {
    const commentTab = state.tabs.find(e => e.dataName === 'comment');
    if (!commentTab) {
      return;
    }
    initGetRecentCommentsInterval(commentTab.resources).then(() => {}); // 新着付箋を更新
  };

  const createMapSelectedElem = async(obj: MapElemInfo<Comment>) => {
    if (!state.geoItemMetaContext.map[obj.dataName]) {
      console.warn('tryCreateMapSelectedElem. Unknown resource type', obj.dataName);
      return;
    }
    const metaItem = state.geoItemMetaContext.map[obj.dataName];
    try {
      const resource = await metaItem.manager.createResource(obj.data);
      resource.isSelected = true;
      metaItem.layerManager.addLayerItem(resource);
      setMapSelectedElemInfo({
        dataName: obj.dataName,
        data: resource,
      });
    } catch (e) {
      state.mapSelectedElemCreateFailed++;
    }

    if (obj.dataName === 'comment') {
      refreshCommentTab();
    }
  };

  const updateMapSelectedElem = async(obj: MapElemInfo<GIComment>) => {
    if (!state.geoItemMetaContext.map[obj.dataName]) {
      console.warn('tryUpdateMapSelectedElem. Unknown resource type', obj.dataName);
      return;
    }
    const metaItem = state.geoItemMetaContext.map[obj.dataName];
    try {
      const resource = await metaItem.manager.updateResource(obj.data);
      resource.isSelected = true;
      setMapSelectedElemInfo({
        dataName: obj.dataName,
        data: resource,
      });
      metaItem.layerManager.updateLayerItem(resource);
    } catch (e) {
      state.mapSelectedElemUpdateFailed++;
    }

    if (obj.dataName === 'comment') {
      refreshCommentTab();
    }
  };

  const refGISearchcomment = ref<InstanceType<typeof GeoItemSearchComment>[]>();
  const doDeleteMapSelectedElem = async() => {
    const obj = state.mapDeleteConfirmElem;
    if (!obj) {
      return;
    }
    state.mapDeleteConfirmElem = null;
    if (!state.geoItemMetaContext.map[obj.dataName]) {
      console.warn('tryDeleteMapSelectedElem. Unknown resource type', obj.dataName);
      return;
    }
    const metaItem = state.geoItemMetaContext.map[obj.dataName];
    const resource = await metaItem.manager.deleteResource(obj.data);
    metaItem.layerManager.deleteLayerItem(resource);
    if (obj.dataName === 'comment') {
      const relativeComments = metaItem.layerManager.getRelativeComments(obj.data);
      const selectedComment = state.mapSelectedElemInfo?.data as GIComment;
      if (selectedComment && selectedComment.id !== obj.data.id) {
        relativeComments.push(selectedComment);
      }
      if (!refGISearchcomment.value) {
        return;
      }
      const commentOrNull = await selectRelativeCommentAfterDeleted({
        deletedObj: obj,
        relativeComments,
        metaItem,
        giSearchComment: refGISearchcomment.value[0],
      });
      setMapSelectedElemInfo(commentOrNull);
      refreshCommentTab();
    } else {
      setMapSelectedElemInfo(null);
    }
  };

  const setMapSelectedElemInfo = (val: MapElemInfo<GIComment | Location> | null, opts: { moveCenter?: boolean } = {}) => {
    state.mapSelectedElemInfo = val;
    if (val?.dataName === 'comment' && state.mapSelectedElemInfo) {
      const metaItem = state.geoItemMetaContext.map[val.dataName];
      state.mapSelectedElemInfo.relativeComments = metaItem.layerManager.getRelativeComments(val.data as GIComment);
    }
    nextTick(() => {
      params.resizeMap();
      if (opts.moveCenter && params.refExtremeMap.value && val) {
        params.refExtremeMap.value.moveCenterTo(val.data);
      }
    });
  };

  const currentElemInfoComponent = computed(() => {
    if (!state.mapSelectedElemInfo) {
      return 'map-elem-info-none';
    }

    if (state.mapSelectedElemInfo.dataName === 'comment') {
      return 'map-elem-info-comment-container';
    }
    return 'map-elem-info-' + state.mapSelectedElemInfo.dataName;
  });
  const visibleGeoItemLayers = computed(() => {
    return Object.entries(state.geoItemMetaContext.show).filter(ent => !!ent[1]).map(ent => {
      return state.geoItemMetaContext.map[ent[0]];
    });
  });

  const tryRefreshCurrentSelectedElemInfo = ({ dataName, data }: { dataName: string; data: GIComment[] }) => {
    if (!state.mapSelectedElemInfo) { return; }
    const idFieldKey = 'id';
    const selectedData = state.mapSelectedElemInfo.data;
    if (state.mapSelectedElemInfo.dataName === dataName &&
      !hasLocation(selectedData) &&
      !data.find(e => state.mapSelectedElemInfo && e[idFieldKey] === selectedData[idFieldKey])
    ) {
      setMapSelectedElemInfo(null);
    }
  };
  const refreshGeoItemLayers = async({ spinnerMsg }: { spinnerMsg: string }): Promise<Array<GeoItemInner> | undefined> => {
    const reqItems: Array<GeoItemMeta> = [];
    for (const [dataName, show] of Object.entries(state.geoItemMetaContext.show)) {
      const metaItem = state.geoItemMetaContext.map[dataName];
      if (!show && params.refExtremeMap.value) {
        params.refExtremeMap.value.removeDataLayer(metaItem.name);
        continue;
      }
      // あとでまとめてリクエスト
      reqItems.push(metaItem);
    }

    const currentTabs = state.tabs.filter(e => {
      return [...reqItems.map(e => e.name), 'comment'].includes(e.dataName);
    });
    if (reqItems.length === 0) {
      state.tabs = currentTabs;
      return;
    }

    state.waitSpinner.msg = spinnerMsg;
    state.waitSpinner.show = true;
    showWaitSpinnerTabs(currentTabs);
    const newTabs = createNewTabs(state.tabs, reqItems);
    state.tabs = [...currentTabs, ...newTabs];
    const results = await getGeoItemsInner(reqItems, refGISearchcomment.value);
    state.waitSpinner.show = false;

    for (const { item: metaItem, data } of results) {
      const dataName = metaItem.name;
      const zIndexOffset = state.geoItemMetaContext.order[dataName] || 0;
      if (params.refExtremeMap.value) {
        params.refExtremeMap.value.showDataLayer(metaItem, data, zIndexOffset);
      }
      tryRefreshCurrentSelectedElemInfo({ dataName, data });
      state.geoItemMetaContext.countsDisp[dataName] = `(${data.length}件)`;
      prepareTabResources(state.tabs, metaItem, data);
    }

    return results;
  };

  const changeGeoItemLayers = async(dataName: string) => {
    if (state.geoItemMetaContext.show[dataName]) {
      state.geoItemMetaContext.order[dataName] = visibleGeoItemLayers.value.length;
    } else {
      const deletedOrder = state.geoItemMetaContext.order[dataName];
      delete state.geoItemMetaContext.order[dataName];
      // 順番の付け替え
      for (const [k, v] of Object.entries(state.geoItemMetaContext.order)) {
        if (v > deletedOrder) {
          state.geoItemMetaContext.order[k]--;
        }
      }
      delete state.geoItemMetaContext.countsDisp[dataName];
    }
    // 編集中にレイヤーを消されたら追随して消える
    if (state.mapSelectedElemInfo) {
      // carは関係ないので除外
      const ignoreDataNames = ['car'];
      if (
        !ignoreDataNames.includes(state.mapSelectedElemInfo.dataName) &&
        !state.geoItemMetaContext.show[state.mapSelectedElemInfo.dataName]
      ) {
        setMapSelectedElemInfo(null);
      }
    }
    // 再取得、再表示
    await refreshGeoItemLayers({ spinnerMsg: state.waitSpinner.msgDefault });

    params.resizePanes();
  };

  const setCommentDisplayedOnLayer = async() => {
    // 付箋を開いてなければ開く
    const dataName = 'comment';
    if (!state.geoItemMetaContext.show[dataName]) {
      Vue.set(state.geoItemMetaContext.show, dataName, true);
      await changeGeoItemLayers(dataName);
    }
  };

  const showPreviousGeoItem = async(current: MapElemInfo<GIComment>) => {
    switchGeoItem(current, -1);
  };
  const showNextGeoItem = async(current: MapElemInfo<GIComment>) => {
    switchGeoItem(current, 1);
  };
  const switchGeoItem = (current: MapElemInfo<GIComment>, move: number) => {
    const geoItemLayerManager = state.geoItemMetaContext.map[current.dataName].layerManager;
    const targetGeoItem = geoItemLayerManager.getGeoItemByIndexDiff(current.data, move);
    geoItemLayerManager.deselectAll();
    targetGeoItem.isSelected = true;
    const hasGeoLocation = !!targetGeoItem.lat && !!targetGeoItem.lon;
    if (hasGeoLocation) {
      geoItemLayerManager.updateLayerItem(targetGeoItem);
    }
    afterDataLayerItemClick(current.dataName, targetGeoItem, { moveCenter: hasGeoLocation });
  };

  const afterDataLayerItemClick = (dataName: string, obj: GIComment, opts: { moveCenter?: boolean } = {}) => {
    if (obj.isSelected && params.refExtremeMap.value) {
      params.refExtremeMap.value.deselectLayersExcept(dataName);
      setMapSelectedElemInfo({
        dataName: dataName,
        data: obj,
      }, { moveCenter: !!opts.moveCenter });
    } else {
      setMapSelectedElemInfo(null);
    }
  };

  const onMapItemClicked = (obj: MapElemInfo<GIComment>) => {
    if (!state.geoItemMetaContext.map[obj.dataName]) {
      console.warn('onMapItemClicked. Unknown resource type', obj.dataName);
      return;
    }
    afterDataLayerItemClick(obj.dataName, obj.data);
  };

  const onTabElementClick = async(element: MapElemInfo<GIComment>) => {
    if (element.dataName === 'comment') {
      await setCommentDisplayedOnLayer();
    }
    // 選択状態にする
    const layerManager = state.geoItemMetaContext.map[element.dataName].layerManager;
    layerManager.deselectAll();
    const targetResource = getEnsuredLayerItem(layerManager, element.data);
    const hasGeoLocation = !!targetResource.lat && !!targetResource.lon;
    if (hasGeoLocation) {
      layerManager.updateLayerItem(targetResource);
    }
    afterDataLayerItemClick(element.dataName, targetResource, { moveCenter: hasGeoLocation });
  };

  const onClickMap = async(data: Location) => {
    const dataName = 'comment';
    if (params.refExtremeMap.value && state.geoItemMetaContext.show[dataName]) {
      params.refExtremeMap.value.showPin(data);
    }
    setMapSelectedElemInfo(getNewObjForMapSelectedElem(data));
  };

  const store = useStore();
  const userState = store.state.user;
  const isCommentAvailable = computed<boolean>(() => {
    return userState.settings.is_comment_available;
  });
  const fetchAndShowCommentById = async(commentId: string) => {
    const dataName = 'comment';
    const metaItem = state.geoItemMetaContext.map[dataName];
    const comment = await metaItem.manager.getResourceById(parseInt(commentId));
    if (!comment) { return; }
    await onTabElementClick({ dataName, data: comment });
  };
  const getGeoItemById = async(obj: FetchGeoItemsParams) => {
    const metaItem = state.geoItemMetaContext.map[obj.dataName];
    if (!obj.id) { return; }
    try {
      const resource = await metaItem.manager.getResourceById(obj.id);
      if (!resource) {
        return;
      }
      metaItem.layerManager.addLayerItem(resource);
    } catch {
      state.getGeoItemByIdFailed++;
    }
    // 同じGeoItemを再表示する。
    if (state.mapSelectedElemInfo) {
      setMapSelectedElemInfo({ ...state.mapSelectedElemInfo });
    }
  };
  const confirmDeleteMapSelectedElem = async(obj: MapElemInfo<GIComment>) => {
    state.mapDeleteConfirmElem = obj;
  };
  const showImage = (data: ShowImageParams) => {
    state.imageViewModal = {
      show: true,
      imageSrc: data.imageSrc,
      title: data.modalTitle,
      downloadFilenameTpl: data.downloadFilenameTpl,
    };
  };
  const closeImageModal = () => {
    state.imageViewModal = {
      show: false,
      imageSrc: '',
      title: '',
      downloadFilenameTpl: '',
    };
  };
  const cancelDeleteMapSelectedElem = async() => {
    state.mapDeleteConfirmElem = null;
  };
  const onMapSelectedElemAreaSizeChange = async() => {
    nextTick(() => {
      params.resizeMap();
    });
  };
  const layerListDefault: CustomGeoItemLayer[] = [
    { name: 'comment', dispName: '付箋', isCreatable: true },
  ];
  const handleExtParams = () => {
    const extParams = store.getters[LocalStorageGetterTypes.EXT_PARAMS];
    // 一度読んだら捨てる
    store.dispatch(LocalStorageActionTypes.SET, { key: 'extParams', val: {} });
    if (extParams.commentId) {
      fetchAndShowCommentById(extParams.commentId);
    }
  };
  onMounted(async() => {
    await ensureUserAndMasters(store).then(({ user, masters }) => {
      initializeDataManagers(
        layerListDefault,
        { user, masters },
      );
    });
    handleExtParams();
    startGettingRecentComments();
  });
  onUnmounted(() => {
    window.clearRequestInterval(state.commentUpdateTimer);
  });

  return {
    state,
    updateMapSelectedElem,
    createMapSelectedElem,
    doDeleteMapSelectedElem,
    refGISearchcomment,
    currentElemInfoComponent,
    onTabElementClick,
    onMapItemClicked,
    onClickMap,
    showPreviousGeoItem,
    showNextGeoItem,
    changeGeoItemLayers,
    visibleGeoItemLayers,
    refreshGeoItemLayers,
    getGeoItemById,
    confirmDeleteMapSelectedElem,
    showImage,
    closeImageModal,
    cancelDeleteMapSelectedElem,
    onMapSelectedElemAreaSizeChange,
    isCommentAvailable,
  };
}
